import React, { useState } from 'react';
import JobStatus from './JobStatus';
import MarketDataStatus from './MarketDataStatus'
import TradeAudit from './TradeAudit'
import ManageUsers from './ManageUsers'
import Signals from './Signals'
import ActiveTrades from '../trades/ActiveTrades'
import CompletedTrades from '../trades/CompletedTrades'
import BacktestUpload from './BacktestUpload'
import Stats from '../admin/Stats'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ReconcilePlans from "./ReconcilePlans.js"
import TradeCorrectionEmails from "./TradeCorrectionEmails.js"

function ManageSystem(props) {

    const [tab, setTab] = useState('signals');

    function shouldAllow() {
        return (props.role >= 0);
    }

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };


    return (
        <div>
            {shouldAllow() ?
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tab}
                            onChange={handleChangeTab}
                            textColor="secondary"
                            indicatorColor="secondary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab value="signals" label="Signals" />
                            <Tab value="reconcile" label="Reconcile" />
                            <Tab value="jobs" label="Daily Jobs" />
                            <Tab value="quotes" label="Market Data" />
                            <Tab value="audit" label="Trade Audit" />
                            <Tab value="users" label="Users" />
                            <Tab value="active" label="All Active Trades" />
                            <Tab value="completed" label="All Completed Trades" />
                            <Tab value="stats" label="Stats" />
                            <Tab value="upload" label="Upload" />
                            <Tab value="tradeCorrection" label="Trade Correction" />
                        </Tabs>
                    </Box>
                    <br></br>

                    {tab === "audit" ?
                        <>
                            <TradeAudit />
                        </>
                        : null}
                    {tab === "jobs" ?
                        <>
                            <JobStatus />
                        </>
                        : null}
                    {tab === "quotes" ?
                        <>
                            <MarketDataStatus role={props.role} userId={props.userId} />
                        </>
                        : null}
                    {tab === "users" ?
                        <>
                            <ManageUsers role={props.role} userId={props.userId} />
                        </>
                        : null}

                    {tab === "signals" ?
                        <>
                            <Signals role={props.role} />
                        </>
                        : null}
                    {tab === "active" ?
                        <>
                            <ActiveTrades mobileView={props.mobileView} onAlgoSelected={props.onAlgoSelected} />
                        </>
                        : null}
                    {tab === "completed" ?
                        <>
                            <CompletedTrades mobileView={props.mobileView} onAlgoSelected={props.onAlgoSelected} />
                        </>
                        : null}
                    {tab === "upload" ?
                        <>
                            <BacktestUpload planId={props.planId} role={props.role} />
                        </>
                        : null}
                    {tab === "stats" ?
                        <>
                            <Stats />
                        </>
                        : null}
                    {tab === "reconcile" ?
                        <>
                            <ReconcilePlans planId={props.planId} role={props.role} userId={props.userId} />
                        </>
                        : null}
                    {tab === "tradeCorrection" ?
                        <>
                            <TradeCorrectionEmails role={props.role} />
                        </>
                        : null}
                </>
                :
                <div class="empty-page" >Not allowed</div>
            }


        </div>
    );
}

export default ManageSystem;