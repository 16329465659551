import React, { useEffect, useState } from 'react';
import CandleStickChart from './CandlestickChart.js';
import tableHelpers from '../helpers/TableHelpers.js'
import { Link } from 'react-router-dom'
import RichTable from '../components/richTable/RichTable.jsx'
import ExpectancyChart from '../components/ExpectancyChart.js';

function StockQuickLook(props) {

    const [activeTrades, setActiveTrades] = useState([]);

    useEffect(() => {
        (async function () {

            setActiveTrades([])
            let trades = await (await fetch(`/api/activeTrades?planId=` + props.planId)).json()
            console.log("activeTrades", trades);
            setActiveTrades(trades);
        })();
    }, [props.planId]);

    let start = new Date();
    start.setMonth(start.getMonth() - 12);
    let dateString = start.getUTCFullYear() + "-" + (start.getMonth() + 1) + "-1";

    function getTrades() {
        activeTrades.forEach(trade => {
            trade.profit = ((trade.current_price - trade.close) / trade.close * 100);
        })
        return activeTrades.filter(t => t.trade_symbol === props.symbol)
    }

    function profitChartFormatter(cell, row) {
        return <ExpectancyChart width={250} profit={row.profit} avgWin={row.avg_win} avgLoss={row.avg_loss} />
    }

    function tradeDescriptionFormatter(cell, row) {
        return <>
            {tableHelpers.percentFormatterNoDecimals(row.allocation)} <Link className="bg-transparent" to={"/algo/" + row.algoId}> {row.display_name}</Link >
        </>
    }

    return (
        <>
            <CandleStickChart initialVisibleBars={50} startDate={dateString} showAveragePrice={false} symbol={props.symbol} trades={null} width={325} height={250} />
            <br></br>
            <h5>Active Trades</h5>
            <RichTable data={getTrades()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Trade', field: 'allocation', formatter: tradeDescriptionFormatter },
                { title: 'Profit', field: 'profit', formatter: profitChartFormatter },
            ]} />
        </>
    )
}

export default StockQuickLook;