module.exports = {

    getBalanceOfPriorDate: function (backtestResults, date, useBaseline) {
        if (backtestResults && backtestResults.dailyResults && backtestResults.dailyResults.length) {
            for (let i = backtestResults.dailyResults.length - 1; i >= 0; i--) {
                let d = new Date(backtestResults.dailyResults[i].date);
                if (d < date) {
                    return (useBaseline ? backtestResults.dailyResults[i].baseline :
                        backtestResults.dailyResults[i].total);
                }
            }
            return (useBaseline ? backtestResults.dailyResults[0].baseline :
                backtestResults.dailyResults[0].total);
        } else {
            return null;
        }
    },

    getCurrentBalance: function (backtestResults, useBaseline) {
        if (backtestResults && backtestResults.dailyResults && backtestResults.dailyResults.length) {
            let result = useBaseline ? backtestResults.dailyResults[backtestResults.dailyResults.length - 1].baseline :
                backtestResults.dailyResults[backtestResults.dailyResults.length - 1].total;
            return result;
        } else {
            return null;
        }
    },

    getMaxBalance: function (backtestResults, useBaseline) {
        if (backtestResults && backtestResults.dailyResults && backtestResults.dailyResults.length) {
            let maxValue = useBaseline ? backtestResults.dailyResults[backtestResults.dailyResults.length - 1].baseline : backtestResults.dailyResults[backtestResults.dailyResults.length - 1].total;
            for (let i = backtestResults.dailyResults.length - 1; i >= 0; i--) {
                if (useBaseline) {
                    if (backtestResults.dailyResults[i].baseline > maxValue)
                        maxValue = backtestResults.dailyResults[i].baseline;
                } else {
                    if (backtestResults.dailyResults[i].total > maxValue)
                        maxValue = backtestResults.dailyResults[i].total;
                }
            }
            return maxValue;
        } else {
            return 0;
        }
    },

    getReturnSince: function (backtestResults, date, useBaseline, endDate = null) {
        let current = endDate ? module.exports.getBalanceOfPriorDate(backtestResults, endDate, useBaseline) : module.exports.getCurrentBalance(backtestResults, useBaseline);
        let previous = module.exports.getBalanceOfPriorDate(backtestResults, date, useBaseline);
        return (current - previous) / previous * 100;
    },

    getCurrentDrawdown: function (backtestResults, useBaseline, endDate = null) {
        let current = endDate ? module.exports.getBalanceOfPriorDate(backtestResults, endDate, useBaseline) : module.exports.getCurrentBalance(backtestResults, useBaseline);
        let max = module.exports.getMaxBalance(backtestResults, useBaseline);
        return (max - current) / max * 100;
    },

    getMaxDrawdownSince: function (backtestResults, date, useBaseline = false) {
        let maxDrawdown = 0;
        let maxValue = 0;

        if (backtestResults && backtestResults.dailyResults && backtestResults.dailyResults.length) {
            for (let i = 0; i < backtestResults.dailyResults.length; i++) {
                let d = new Date(backtestResults.dailyResults[i].date);
                if (d >= date) {
                    if (useBaseline) {
                        if (backtestResults.dailyResults[i].baseline > maxValue)
                            maxValue = backtestResults.dailyResults[i].baseline;

                        let drawdown = (maxValue - backtestResults.dailyResults[i].baseline) / maxValue * 100;
                        if (drawdown > maxDrawdown)
                            maxDrawdown = drawdown;

                    } else {
                        if (backtestResults.dailyResults[i].total > maxValue)
                            maxValue = backtestResults.dailyResults[i].total;

                        let drawdown = (maxValue - backtestResults.dailyResults[i].total) / maxValue * 100;
                        if (drawdown > maxDrawdown)
                            maxDrawdown = drawdown;
                    }
                }
            }
            return maxDrawdown;
        } else {
            return 0;
        }
    }
}