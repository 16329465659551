import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import HelpFAQ from '../help/HelpFAQ.js'
import GoogleLoginButton from './googleLoginButton'
import './login.css'
import { clearLocalStorage } from '../helpers/localStorageHelper'

function Login({ onLogin }) {
    const navigate = useNavigate()

    // Delete session, then regardless of success/error, clear local storage
    fetch('/api/session', { method: 'DELETE' }).then(clearLocalStorage).catch(clearLocalStorage)

    const [loginFailed, setLoginFailed] = useState(false)
    const [searchParams] = useSearchParams()
    const [message] = useState(searchParams.get('message'))

    async function handleSubmit(e) {
        setLoginFailed(false)
        e.preventDefault()

        const data = new FormData(e.target)

        let email = encodeURIComponent(data.get('email'))
        let password = encodeURIComponent(data.get('password'))

        if (!email || !password) {
            alert('Please enter a valid email address and password')
            return
        }

        const response = await fetch(`/api/login?userId=${email}&password=${password}`)
        const plans = await response.json()

        if (!response.ok || !plans || plans.length === 0) {
            setLoginFailed(true)
            e.target.reset()
            document.getElementById('email').focus()
            return
        }

        console.log(plans)

        onLogin(plans[0].userId, plans[0].role, plans, plans[0].user_agreement, plans[0].cohort, plans[0].disable_on_date)
        navigate('/')
    }

    return (
        <div className="login-page">
            <h1>Welcome to Stockpiler!</h1>
            <p className='instructions'>Please enter your credentials</p>
            {message ? <p className='message'>{message}</p> : null}
            {loginFailed ? <p className='message error'>Wrong email / password combination. If you can't remember your credentials, email <a href="mailto:vmscott@gmail.com">me</a></p>
                : null}
            <div className="login-container">
                <div className="form-container">
                    <form onSubmit={handleSubmit} className="login-form">
                        <p>Log in with your email and password</p>
                        <input type="text" name="email" id="email" placeholder="Email Address" />
                        <input type="password" name="password" id="password" placeholder="Password" />

                        <button type="submit">Log In</button>
                    </form>
                </div>
                <div className="third-party-container">
                    <p>...or log in with Google (must be registered on Stockpiler with same email)</p>
                    <a href="/.auth/login/google?post_login_redirect_uri=/postLogin" className="google-login">
                        <GoogleLoginButton />
                    </a>
                </div>
            </div>
            <HelpFAQ />
        </div>
    );
}

export default Login