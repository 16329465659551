import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import Dropdown from 'react-bootstrap/Dropdown';

function CompletedTradesBreakdownChart(props) {
    const [groupBy, setGroupBy] = useState(props.defaultGroupBy ? props.defaultGroupBy : "trade_symbol")
    const [groupBy2, setGroupBy2] = useState(props.defaultGroupBy2 ? props.defaultGroupBy2 : null);
    const [valueType, setValueType] = useState(props.defaultValueType ? props.defaultValueType : "total profit")
    const [chartData, setChartData] = useState([])

    useEffect(() => {
        (async function () {

            if (props.trades && props.trades.length) {
                let trades = JSON.parse(JSON.stringify(props.trades));
                console.log("CompletedTradesBreakdownChart", trades);
                let sample = trades[0];
                if (typeof (sample[groupBy]) === "number")
                    trades = trades.sort((a, b) => a[groupBy] - b[groupBy])

                let data = [];
                let counts = {};
                trades.forEach(trade => {
                    trade.day_of_week = new Date(trade.purchase_date).getDay().toString();
                })

                trades.forEach(trade => {
                    let key = groupBy2 ? trade[groupBy] + " / " + trade[groupBy2] : trade[groupBy];
                    let existing = data.find(a => a[0] === key);
                    let value = null;
                    switch (valueType) {
                        case "trades":
                            value = 1;
                            break;
                        case "total profit":
                            value = trade.profit * trade.allocation / 100;
                            break;
                        case "winning percentage":
                            value = trade.profit > 0;
                            break;
                        case "avg profit":
                            value = trade.profit / 100;
                            break;
                        default:
                            break;
                    }
                    if (existing) {
                        existing[1] += value
                        counts[key] = counts[key] + 1
                    } else {
                        data.push([key, value])
                        counts[key] = 1;
                    }
                })
                switch (valueType) {
                    case "winning percentage":
                    case "avg profit":
                    case "max drawdown":
                        for (let i = 0; i < data.length; i++) {
                            data[i][1] = data[i][1] / counts[data[i][0]] * 100;
                        }
                        break;
                    default:
                        break;
                }

                data = data.sort((a, b) => a[0].localeCompare(b[0]))

                data.unshift(["Group", valueType])
                setChartData(data);
            }

        })();
    }, [props.trades, groupBy, groupBy2, valueType]);

    const options = {
        bars: "vertical",
        legend: "none",
        chartArea: { 'width': '90%' },
        bar: { width: "25" }
    };

    return (
        <div>
            {chartData.length ?
                <Chart
                    chartType="ColumnChart"
                    data={chartData}
                    width={"100%"}
                    height={"275px"}
                    options={options}
                />
                :
                null
            }
            <center>
                <div className='sameline'>
                    Group By:&nbsp;
                    <Dropdown onSelect={(e) => setGroupBy(e)}>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            {groupBy}
                        </Dropdown.Toggle>
                        <Dropdown.Menu  >
                            <Dropdown.Item as='group' eventKey={'trade_symbol'} value={'trade_symbol'}>Trade Symbol</Dropdown.Item>
                            <Dropdown.Item as='group' eventKey={'algo_type'} value={'algo_type'}>Algo Type</Dropdown.Item>
                            <Dropdown.Item as='group' eventKey={'entry_speed'} value={'entry_speed'}>Entry Speed</Dropdown.Item>
                            <Dropdown.Item as='group' eventKey={'base_symbol'} value={'symbol'}>Base Symbol</Dropdown.Item>
                            <Dropdown.Item as='group' eventKey={'display_name'} value={'display_name'}>Algo</Dropdown.Item>
                            <Dropdown.Item as='group' eventKey={'day_of_week'} value={'day_of_week'}>Day of Week</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {!props.mobileView ?
                        <>
                            Second Dimension:
                            <Dropdown onSelect={(e) => setGroupBy2(e)}>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                    {groupBy2}
                                </Dropdown.Toggle>
                                <Dropdown.Menu  >
                                    <Dropdown.Item as='group' eventKey={null} value={null}>None</Dropdown.Item>
                                    <Dropdown.Item as='group' eventKey={'trade_symbol'} value={'trade_symbol'}>Trade Symbol</Dropdown.Item>
                                    <Dropdown.Item as='group' eventKey={'algo_type'} value={'algo_type'}>Algo Type</Dropdown.Item>
                                    <Dropdown.Item as='group' eventKey={'entry_speed'} value={'entry_speed'}>Entry Speed</Dropdown.Item>
                                    <Dropdown.Item as='group' eventKey={'base_symbol'} value={'symbol'}>Base Symbol</Dropdown.Item>
                                    <Dropdown.Item as='group' eventKey={'display_name'} value={'display_name'}>Algo</Dropdown.Item>
                                    <Dropdown.Item as='group' eventKey={'day_of_week'} value={'day_of_week'}>Day of Week</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                        : null}

                    Display:&nbsp;
                    <Dropdown onSelect={(e) => setValueType(e)}>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            {valueType}
                        </Dropdown.Toggle>
                        <Dropdown.Menu  >
                            <Dropdown.Item as='group' eventKey={'total profit'} value={'total profit'}>Total Profit</Dropdown.Item>
                            <Dropdown.Item as='group' eventKey={'trades'} value={'trades'}>Trade Count</Dropdown.Item>
                            <Dropdown.Item as='group' eventKey={'winning percentage'} value={'winning percentage'}>Win Rate</Dropdown.Item>
                            <Dropdown.Item as='group' eventKey={'avg profit'} value={'avg proft'}>Avg Profit</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </center>
        </div >
    );
}

export default CompletedTradesBreakdownChart;