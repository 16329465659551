import React, { useState, useEffect } from 'react';
import RichTable from '../components/richTable/RichTable.jsx'
import { Link } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import tableHelpers from '../helpers/TableHelpers.js'

function PlanRecommendations(props) {

    const [recommendations, setRecommendations] = useState(null);
    const [correlations, setCorrelations] = useState(null);
    const [excludeAlgoTypeCorrelations, setExcludeAlgoTypeCorrelations] = useState(true);

    useEffect(() => {
        (async function () {
            setRecommendations(null);
            const recs = await (await fetch(`/api/planRecommendations?planId=` + props.planId)).json();
            console.log(recs);
            setRecommendations(recs);

            const data = await (await fetch(`/api/planAlgoCorrelations?planId=` + props.planId)).json();
            setCorrelations(data);
        })();
    }, [props.planId]);


    function getRecommendations() {
        if (recommendations && recommendations.poorPerformance) {
            return recommendations.poorPerformance.map(recommendation => {
                return {
                    displayRemove: true,
                    removeAlgoId: recommendation.remove.algoId,
                    removeDisplayName: recommendation.remove.display_name,
                    removeTradeSymbol: recommendation.remove.trade_symbol,
                    addAlgoId: recommendation.add.algoId,
                    addDisplayName: recommendation.add.display_name,
                    addTradeSymbol: recommendation.add.trade_symbol
                }
            });
        } else
            return [];
    }

    function getHotCold() {
        if (recommendations && recommendations.hotCold) {
            return recommendations.hotCold.map(recommendation => {
                return {
                    displayRemove: true,
                    removeAlgoId: recommendation.remove.algoId,
                    removeDisplayName: recommendation.remove.display_name,
                    removeTradeSymbol: recommendation.remove.trade_symbol,
                    addAlgoId: recommendation.add.algoId,
                    addDisplayName: recommendation.add.display_name,
                    addTradeSymbol: recommendation.add.trade_symbol
                }
            });
        } else
            return [];
    }

    function getHighlyCorrelation() {
        if (correlations && correlations.length)
            return correlations.filter(correlation =>
                correlation.percent > 60 &&
                correlation.primary_symbol === correlation.secondary_symbol &&
                correlation.primary_algoId !== correlation.secondary_algoId &&
                (!excludeAlgoTypeCorrelations || correlation.primary_algo_type === correlation.secondary_algo_type) &&
                correlation.primary_short === correlation.secondary_short);
        else
            return [];
    }

    function getProbations() {
        if (recommendations && recommendations.probation) {
            return recommendations.probation.map(recommendation => {
                return {
                    algoId: recommendation.algoId,
                    displayName: recommendation.display_name,
                    tradeSymbol: recommendation.trade_symbol,
                    percentage: recommendation.percentage,
                    count: recommendation.details.liveVsBacktest.live ? recommendation.details.liveVsBacktest.live.count : 0
                }
            });
        } else
            return [];
    }

    function getColds() {
        if (recommendations && recommendations.cold) {
            return recommendations.cold.map(recommendation => {
                return {
                    algoId: recommendation.algoId,
                    displayName: recommendation.display_name,
                    tradeSymbol: recommendation.trade_symbol,
                    percentage: recommendation.percentage,
                    score: recommendation.recent_vs_longterm
                }
            });
        } else
            return [];
    }
    function algoLinkFormatter(cell, row) {
        let algoId = cell === true ? row.removeAlgoId : row.addAlgoId;
        let displayName = cell === true ? row.removeDisplayName : row.addDisplayName;
        let symbol = cell === true ? row.removeTradeSymbol : row.addTradeSymbol;
        return (
            <>
                {symbol} <Link className="bg-transparent" to={"/algo/" + algoId}>{displayName}</Link>
            </>
        )
    }

    function singleAlgoLinkFormatter(cell, row) {
        let algoid = row.algoId;
        return (
            <Link className="bg-transparent" to={"/algo/" + algoid}>{cell}</Link>
        )
    }

    function primaryAlgoLinkFormatter(cell, row) {
        return (
            <Link className="bg-transparent" to={"/algo/" + cell}>{row.primary_display_name}</Link>
        )
    }

    function secondaryAlgoLinkFormatter(cell, row) {
        return (
            <Link className="bg-transparent" to={"/algo/" + cell}>{row.secondary_display_name}</Link>
        )
    }

    return (
        <div>
            {recommendations ?
                <>
                    <h5>Possibly Overfitted</h5>
                    <RichTable data={getRecommendations()} pageSize={10} className="table-striped table-hover table-condensed fixed" mappers={[
                        { title: 'Remove', field: 'displayRemove', formatter: algoLinkFormatter },
                        { title: 'Add', field: 'add', formatter: algoLinkFormatter },
                    ]} />

                    <h5>In a Slump</h5>
                    <RichTable data={getHotCold()} pageSize={10} className="table-striped table-hover table-condensed fixed" mappers={[
                        { title: 'Remove', field: 'displayRemove', formatter: algoLinkFormatter },
                        { title: 'Add', field: 'add', formatter: algoLinkFormatter },
                    ]} />

                    <h5>Highly Correlated</h5>
                    <input type="checkbox" checked={excludeAlgoTypeCorrelations} onChange={() => setExcludeAlgoTypeCorrelations(!excludeAlgoTypeCorrelations)} />Exclude different algo types<br></br>
                    <RichTable data={getHighlyCorrelation()} pageSize={10} className="table-striped table-hover table-condensed fixed" mappers={[
                        { title: 'Symbol', field: 'primary_symbol', sorters: true },
                        { title: 'Primary', field: 'primary_algoId', sorters: true, formatter: primaryAlgoLinkFormatter },
                        { title: 'Secondary', field: 'secondary_algoId', sorters: true, formatter: secondaryAlgoLinkFormatter },
                        { title: 'Percent', field: 'percent', sorters: true, isDefaultSort: true },
                    ]} />

                    <br></br>
                    <h5>Review allocation, cold streak</h5>
                    <RichTable data={getColds()} pageSize={10} className="table-striped table-hover table-condensed fixed" mappers={[
                        { title: 'Symbol', field: 'tradeSymbol' },
                        { title: 'Algo', field: 'displayName', formatter: singleAlgoLinkFormatter },
                        { title: 'Allocation', field: 'percentage', formatter: tableHelpers.percentFormatterNoDecimals },
                        { title: 'Score', field: 'score', formatter: tableHelpers.intFormatter, sorters: true, isDefaultSort: true },
                    ]} />

                    <br></br>
                    <h5>Reduce allocation until more trades</h5>
                    <RichTable data={getProbations()} pageSize={10} className="table-striped table-hover table-condensed fixed" mappers={[
                        { title: 'Symbol', field: 'tradeSymbol' },
                        { title: 'Algo', field: 'displayName', formatter: singleAlgoLinkFormatter },
                        { title: 'Allocation', field: 'percentage', formatter: tableHelpers.percentFormatterNoDecimals },
                        { title: 'Trade Count', field: 'count', formatter: tableHelpers.intFormatter, sorters: true, isDefaultSort: true },
                    ]} />
                </>
                :
                <CircularProgress />
            }
        </div>
    );
}

export default PlanRecommendations;