import React, { useState, useEffect } from 'react';
import ActiveTrades from './ActiveTrades';
import CompletedTrades from './CompletedTrades'
import AllocationChanges from './AllocationChanges'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import BonusTrades from './BonusTrades';
import SneakPeek from './SneakPeek';
import HedgeFinder from './HedgeFinder';

function Trades(props) {

    let { activeTab } = useParams();

    const [tab, setTab] = useState(activeTab ? activeTab : 'active');
    const [brokerageConnections, setBrokerageConnections] = useState([]);

    useEffect(() => {
        (async function () {

            async function loadBrokerageConnections() {
                console.log("Checking for active brokerage connections");
                let response = await fetch(`/api/brokeragesActive?userId=` + props.userId);
                if (response.ok) {
                    let connections = await (response.json());
                    setBrokerageConnections(connections);
                }
            }
            loadBrokerageConnections();
        })();
    }, [props.userId]);


    function shouldAllow() {
        return (props.role >= 0);
    }

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };


    return (
        <div>
            {shouldAllow() ?
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tab}
                            onChange={handleChangeTab}
                            textColor="secondary"
                            indicatorColor="secondary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab value="active" label="Active" href="/trades/active" />
                            <Tab value="completed" label="Completed" href="/trades/completed" />
                            <Tab value="history" label="Trade History" href="/trades/history" />
                            <Tab value="sneakpeek" label="Sneak Peek" href="/trades/sneakpeek" />
                            <Tab value="bonus" label="Bonus Trades" href="/trades/bonus" />
                            <Tab value="hedge" label="Hedge Finder" href="/trades/hedge" />
                        </Tabs>
                    </Box>
                    <br></br>

                    {tab === "active" ?
                        <>
                            <ActiveTrades onlyShowUserTrades={props.isOptions} userId={props.userId} accountId={props.accountId} planId={props.planId} mobileView={props.mobileView} onAlgoSelected={props.onAlgoSelected} accountValue={props.accountValue} />
                        </>
                        : null}
                    {tab === "completed" ?
                        <>
                            <CompletedTrades onlyShowUserTrades={props.isOptions} userId={props.userId} accountId={props.accountId} mobileView={props.mobileView} onAlgoSelected={props.onAlgoSelected} planId={props.planId} />
                        </>
                        : null}
                    {tab === "history" ?
                        <>
                            <AllocationChanges mobileView={props.mobileView} onAlgoSelected={props.onAlgoSelected} planId={props.planId} accountValue={props.accountValue} />
                        </>
                        : null}
                    {tab === "sneakpeek" ?
                        <>
                            <SneakPeek mobileView={props.mobileView} planId={props.planId} />
                        </>
                        : null}
                    {tab === "hedge" && brokerageConnections && brokerageConnections.length ?
                        <>
                            <HedgeFinder mobileView={props.mobileView} planId={props.planId} userId={props.userId} brokerage="schwab" accountId={props.accountId} />
                        </>
                        : null}
                    {tab === "bonus" ?
                        <>
                            <h4>Trade Feed</h4>
                            <BonusTrades showAll={true} mobileView={props.mobileView} userId={props.userId} accountValue={props.accountValue} brokerage={brokerageConnections.length ? "schwab" : null} accountId={props.accountId} />
                            <br></br>
                            <h4>Active Trades</h4>
                            <ActiveTrades hideCharts={true} onlyShowUserTrades={true} planId={108} userId={props.userId} accountId={0} mobileView={props.mobileView} onAlgoSelected={props.onAlgoSelected} accountValue={props.accountValue} />
                            <br></br>
                            <h4>Completed Trades</h4>
                            <CompletedTrades hideCharts={true} hideDateSelector={true} onlyShowUserTrades={true} planId={108} userId={props.userId} accountId={0} mobileView={props.mobileView} onAlgoSelected={props.onAlgoSelected} accountValue={props.accountValue} />
                            <br></br>
                        </>
                        : null}

                </>
                :
                <div class="empty-page" >Not allowed</div>
            }


        </div>
    );
}

export default Trades;