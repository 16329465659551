import React, { useState, useEffect } from 'react';
import TwoStepConfirmationButton from "../components/TwoStepConfirmButton.js"
import ActionResult from '../components/ActionResult.js';
import { Link } from 'react-router-dom'

function ReconcilePlans(props) {
    const [result, setResult] = useState(null);
    const [updates, setUpdates] = useState(0);

    useEffect(() => {
        (async function () {
            const url = `/api/syncPlanWithBacktestTrades?userId=` + props.userId + `&planId=` + props.planId
            let result = await (await fetch(url, { method: 'POST' })).json();
            console.log(result);
            setResult(result);
        })();
    }, [props.planId, props.userId, updates]);

    function shouldAllowUpdates() {
        return props.role >= 3;
    }

    async function handleReconciliation() {
        const url = `/api/syncPlanWithBacktestTrades?execute=true&userId=` + props.userId + `&planId=` + props.planId
        let result = await (await fetch(url, { method: 'POST' })).json();
        console.log(result);
        setResult(result);
        setUpdates(updates + 1);
    }

    return (
        <>
            {result ?
                <>

                    <h4>{result.success}</h4>
                    <br></br>
                    {result.summary.length ?
                        <ul>
                            {result.summary.map(item => {
                                return (<li>{item.text} <Link to={"/algo/" + item.algoId}>(Details)</Link></li>);
                            })
                            }
                        </ul>
                        :
                        <>No missing trades</>
                    }
                    <br></br>
                    <br></br>

                    <table style={{ borderWidth: 1, borderStyle: 'solid' }}>
                        <tr style={{ backgroundColor: 'transparent' }}>
                            <td>Plan</td>
                            <td>Backtest</td>
                        </tr>
                        <tr style={{ backgroundColor: 'transparent' }}>
                            <td>
                                {result.planTrades.length ?
                                    <ul>
                                        {result.planTrades.map(item => {
                                            return (<li>{item.allocation}% of {item.trade_symbol} {item.display_name}</li>);
                                        })
                                        }
                                    </ul>
                                    :
                                    <>No plan trades</>
                                }
                            </td>
                            <td>
                                {result.backtestTrades.length ?
                                    <ul>
                                        {result.backtestTrades.map(item => {
                                            return (<li>{item.allocation}% of {item.trade_symbol} {item.display_name}</li>);
                                        })
                                        }
                                    </ul>
                                    :
                                    <>No plan trades</>
                                }
                            </td>
                        </tr>
                    </table>

                    <ActionResult result={result} />

                    <br></br>
                    <br></br>
                    {shouldAllowUpdates() ?
                        <TwoStepConfirmationButton id={1} text="Sync Plans" onConfirmed={handleReconciliation} /> : null}
                </>
                :
                null}
        </>
    );
}

export default ReconcilePlans;