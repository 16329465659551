import React, { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';

function ActionResult(props) {

    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        (async function () {
            if (props.result)
                setOpen(true);
        })();
    }, [props.result]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                X
            </IconButton>
        </React.Fragment>
    );

    if (props.result) {
        return (
            <div style={{ padding: '5px' }}>
                <Snackbar
                    open={open}
                    autoHideDuration={5000}
                    onClose={handleClose}
                    action={action}
                    message={props.result.error ? props.result.error : props.result.success}
                />
            </div>
        );
    } else {
        return <></>
    }
}

export default ActionResult;