import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import Dropdown from 'react-bootstrap/Dropdown';

function AlgosChart(props) {
    const [chartData, setChartData] = useState([]);
    const [groupBy, setGroupBy] = useState("trade_symbol")
    const [years, setYears] = useState(10)
    const [valueType, setValueType] = useState("total profit")

    useEffect(() => {
        (async function () {
            let text = await (await fetch(`/api/algos?years=` + years + `&planId=` + props.planId)).json();

            if (Array.isArray(text) && text.length) {

                let sample = text[0];
                if (typeof (sample[groupBy]) === "number")
                    text = text.sort((a, b) => a[groupBy] - b[groupBy])

                let dataByAlgo = [["Group", "Percent"]];
                let counts = {};
                text.forEach(algo => {

                    let key = typeof (algo[groupBy]) === "number" ? Math.round(algo[groupBy]).toFixed(0) : algo[groupBy];
                    let existing = dataByAlgo.find(a => a[0] === key);
                    let value = null;
                    switch (valueType) {
                        case "trades":
                            value = algo.count;
                            break;
                        case "allocation":
                            value = algo.percentage;
                            break;
                        case "total profit":
                            value = algo.count * algo.avg_profit * algo.percentage / 100;
                            break;
                        case "winning percentage":
                            value = algo.winning_percentage;
                            break;
                        case "avg profit":
                            value = algo.avg_profit;
                            break;
                        case "max drawdown":
                            value = algo.max_drawdown;
                            break;
                        default:
                            break;
                    }
                    if (existing) {
                        existing[1] += value
                        counts[key] = counts[key] + 1
                    } else {
                        dataByAlgo.push([key, value])
                        counts[key] = 1;
                    }
                })
                console.log("counts", counts);
                switch (valueType) {
                    case "winning percentage":
                    case "avg profit":
                    case "max drawdown":
                        for (let i = 1; i < dataByAlgo.length; i++) {
                            dataByAlgo[i][1] = dataByAlgo[i][1] / counts[dataByAlgo[i][0]];
                        }
                        break;
                    default:
                        break;
                }
                setChartData(dataByAlgo)
            }
        })();
    }, [props.planId, props.itemsChanged, groupBy, years, valueType]);

    const options = {
        bars: "vertical",
        legend: "none",
        vAxis: {
            viewWindow: {
                min: 0
            },
            minValue: 0
        },

    };

    return (
        <div>
            <div className='sameline'>
                Group By:&nbsp;
                <Dropdown onSelect={(e) => setGroupBy(e)}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {groupBy}
                    </Dropdown.Toggle>
                    <Dropdown.Menu  >
                        <Dropdown.Item as='group' eventKey={'trade_symbol'} value={'trade_symbol'}>Trade Symbol</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={'algo_type'} value={'algo_type'}>Algo Type</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={'entry_speed'} value={'entry_speed'}>Entry Speed</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={'symbol'} value={'symbol'}>Base Symbol</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={'name'} value={'name'}>Name</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={'robustness_score'} value={'robustness_score'}>Robustness</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={'avg_profit'} value={'avg_profit'}>Avg Profit</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={'avg_duration'} value={'avg_duration'}>Duration</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                Display:&nbsp;
                <Dropdown onSelect={(e) => setValueType(e)}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {valueType}
                    </Dropdown.Toggle>
                    <Dropdown.Menu  >
                        <Dropdown.Item as='group' eventKey={'total profit'} value={'total profit'}>Total Profit</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={'allocation'} value={'allocation'}>Allocation</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={'trades'} value={'trades'}>Trade Count</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={'winning percentage'} value={'winning percentage'}>Win Rate</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={'avg profit'} value={'avg proft'}>Avg Profit</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={'max drawdown'} value={'max drawdown'}>Max Drawdown</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                Over:&nbsp;
                <Dropdown onSelect={(e) => setYears(e)}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {years ? years + " years" : "live trades"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu  >
                        <Dropdown.Item as='group' eventKey={0} value={0}>Live Trades</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={1} value={1}>1 Year Backtest</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={2} value={2}>2 Year Backtest</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={3} value={3}>3 Year Backtest</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={5} value={5}>5 Year Backtest</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={10} value={10}>10 Year Backtest</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            {chartData.length ?
                <Chart
                    chartType="ColumnChart"
                    data={chartData}
                    width={"75%"}
                    height={"200px"}
                    options={options}
                />
                :
                null
            }
        </div >
    );
}

export default AlgosChart;