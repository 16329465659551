import React, { useState, useEffect } from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TwoStepConfirmationButton from '../components/TwoStepConfirmButton.js'
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import tableHelpers from '../helpers/TableHelpers.js'

function AutomateTrade(props) {
    const [modalOpen, setModalOpen] = useState(false);
    const [brokerageAccount, setBrokerageAccount] = useState(null);
    const [tradeResults, setTradeResults] = useState(null);
    const [marketOrders, setMarketOrders] = useState(false);
    const [shouldAllocateCash, setShouldAllocateCash] = useState(false);
    const [updates, setUpdates] = useState(1);
    const [isPlacingOrders, setIsPlacingOrders] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [trade, setTrade] = useState(null);

    useEffect(() => {
        (async function () {

            if (props.trade) {
                setTrade({
                    symbol: props.trade.symbol,
                    shares: Math.round(props.trade.shares),
                    isOption: props.trade.symbol.length > 5
                });
            }

            setBrokerageAccount(null);
            setIsLoading(true);
            const url = "/api/brokerageAccounts?userId=" + props.userId + "&brokerage=schwab"
            const brokerageAccounts = await (await fetch(url)).json();
            if (brokerageAccounts.success && brokerageAccounts.accounts) {
                let brokerageAccount = brokerageAccounts.accounts.find(act => act.accountId === Number(props.accountId));
                if (brokerageAccount) {
                    setBrokerageAccount(brokerageAccount);
                } else {
                    console.log("Could not find brokerage account for " + props.accountId)
                    setBrokerageAccount(null);
                }
            }
            setIsLoading(false);

        })();
    }, [props.userId, props.accountId, updates, props.trade]);

    useEffect(() => {
        (async function () {
            if (props.isOpen)
                setModalOpen(true);
        })();
    }, [props.isOpen]);

    const handleCloseModal = () => {
        setModalOpen(false);
        setTradeResults(null);
        if (props.onClose)
            props.onClose();
    }

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: props.mobileView ? 425 : 475,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 2,
    };

    function getPositionQuantity() {
        if (trade && trade.symbol && brokerageAccount && brokerageAccount.positions) {
            let pos = brokerageAccount.positions.find(pos => pos.symbol === trade.symbol)
            if (pos) {
                return pos.shares;
            } else {
                return "None";
            }
        } else {
            return "N/A";
        }
    }

    async function onPlaceTrade() {
        setTradeResults(null);
        setIsPlacingOrders(true);

        const url = "/api/brokerageTrades?brokerage=schwab&userId=" + props.userId + "&accountId=" + props.accountId + "&marketOrders=" + marketOrders + "&shouldAllocateCash=" + shouldAllocateCash
        let tradesPayload = [trade];
        let result = await (await fetch(url, { method: 'post', body: JSON.stringify(tradesPayload) })).json();

        setIsPlacingOrders(false);
        setTradeResults(result);
        setUpdates(updates + 1);
        console.log(result);
    }

    function updateSymbol(s) {
        let isOption = false;
        if (s.length > 5)
            isOption = true;

        setTrade({
            shares: trade.shares,
            symbol: s,
            isOption: isOption
        })
    }

    function updateQuantity(q) {
        setTrade({
            shares: q,
            symbol: trade.symbol,
            isOption: trade.isOption
        })
    }
    return (
        <>
            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modalOpen}
                onClose={handleCloseModal}
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Automate Trade
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {isLoading ?
                            <CircularProgress />
                            :
                            <>
                                {brokerageAccount ?
                                    <>
                                        Quantity: <input size={5} type="text" value={trade ? Math.round(trade.shares) : null} onChange={(e) => updateQuantity(e.target.value)} />
                                        Symbol: <input size={25} type="text" value={trade ? trade.symbol : null} onChange={(e) => updateSymbol(e.target.value)} />{trade && trade.isOption ? <b> OPTION</b> : null}
                                        <br></br>
                                        <br></br>
                                        Positions: {getPositionQuantity()}
                                        <br></br>
                                        Account Value: {brokerageAccount ? tableHelpers.moneyFormatterNoCents(brokerageAccount.value, null) : null}
                                        <br></br>
                                        Cash: {brokerageAccount ? tableHelpers.moneyFormatterNoCents(brokerageAccount.cash, null) : null} ({brokerageAccount ? (brokerageAccount.cash / brokerageAccount.value * 100).toFixed(1) : "N/A"}%)
                                        <br></br>
                                        ✳️ Open Orders: {brokerageAccount && brokerageAccount.openOrders ? brokerageAccount.openOrders.length : null}
                                        <br></br>
                                        <div style={{ textAlign: 'right' }}>
                                            {trade ?
                                                <TwoStepConfirmationButton id={0} text="Place Trade" onConfirmed={onPlaceTrade} >
                                                    {(trade.shares >= 0 ? "BUY " : "SELL ") + Math.abs(trade.shares) + " of " + trade.symbol}
                                                    <br></br><br></br>
                                                    <input type="checkbox" checked={shouldAllocateCash} onClick={() => setShouldAllocateCash(!shouldAllocateCash)} /> Allocate CASH to SGOV
                                                    <br></br>
                                                    <input type="checkbox" checked={marketOrders} onClick={() => setMarketOrders(!marketOrders)} /> Market day orders
                                                    <br></br>
                                                </TwoStepConfirmationButton>
                                                :
                                                null}
                                        </div>
                                        <br></br>
                                        <div style={{ textAlign: 'right' }}>
                                            <button class="btn btn-secondary" onClick={handleCloseModal} type="button">Cancel</button>
                                        </div>
                                    </>
                                    :
                                    <>Could not find brokerage account at {props.brokerage}</>
                                }
                            </>
                        }
                    </Typography>

                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <b>
                            {isPlacingOrders ? <><br></br><LinearProgress /></> : null}
                            {tradeResults ? (tradeResults.error ? tradeResults.error : tradeResults.orders.length + " orders placed") : null}
                        </b>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}

export default AutomateTrade;