import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { CircularProgress } from '@mui/material';

function SneakPeek(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [previewTrades, setPreviewTrades] = useState(null);
    const [updates, setUpdates] = useState(0);

    useEffect(() => {
        (async function () {

            setIsLoading(true);
            console.log("loadTradePreview")
            setPreviewTrades(null);
            const url = `/api/strategyAlertTradePreview?planId=` + props.planId;
            const trades = await (await fetch(url)).json();
            console.log(trades);
            setPreviewTrades(trades.sort((a, b) => a.symbol < b.symbol ? -1 : 1));
            setIsLoading(false);

        })();
    }, [props.planId, updates]);

    function reloadData() {
        setUpdates(updates + 1)
    }


    function getTradePreview() {
        if (previewTrades === null) {
            return <div>Checking...</div>
        } else if (previewTrades.length === 0) {
            return <div>No trades as of right now</div>
        } else {
            return (
                <div>
                    Things might change so don't take action yet...<br></br>
                    <ul>
                        {
                            previewTrades.map(t =>
                                <li>{t.action.toUpperCase()} {t.percentage}% of <Link to={"/stock/" + t.symbol}>{t.symbol}</Link> by <Link to={"/algo/" + t.algoId}>{t.display_name}</Link>{t.profitPercentage ? (t.profitPercentage > 0 ? " for profit" : " for loss") : null}</li>
                            )
                        }
                    </ul>
                </div>
            )
        }
    }

    return (
        <>
            {isLoading ?
                <CircularProgress />
                :
                <>
                    {getTradePreview()}
                    <Link onClick={reloadData}>🔄 Reload</Link>
                </>
            }

        </>
    );
}

export default SneakPeek;