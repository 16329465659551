import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import RichTable from '../components/richTable/RichTable.jsx'
import TableHelpers from '../helpers/TableHelpers.js';
import { CircularProgress } from '@mui/material';
import AutomateTrade from './AutomateTrade.js'
import Dropdown from 'react-bootstrap/Dropdown';
const OptionFinder = require('./OptionFinder.js');

function OptionChain(props) {

    const [optionCandidates, setOptionCandidates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [maxPrice, setMaxPrice] = useState(20);
    const [symbol, setSymbol] = useState("SPY");
    const [pullbackPercent, setPullbackPercent] = useState(3);
    const [isPlacingOrder, setIsPlacingOrder] = useState(false);
    const [days, setDays] = useState(10);
    const [trade, setTrade] = useState(null);
    const [callPut, setCallPut] = useState("put")

    useEffect(() => {
        (async function () {
        })();
    }, []);

    async function reloadData() {
        setIsLoading(true);
        setOptionCandidates([])
        let optionFinder = new OptionFinder.OptionFinder();
        console.log("OptionChain Loading option candidates", props.brokerage, maxPrice, days)
        let optionCandidates = await optionFinder.loadOptionCandidates(props.userId, props.brokerage, symbol, days, days * 2, maxPrice, callPut === "put", pullbackPercent);
        if (optionCandidates && optionCandidates.options && optionCandidates.options.length) {
            console.log(optionCandidates)
            setOptionCandidates(optionCandidates.options);
        }
        setIsLoading(false);
    }

    function openPlaceTradeModal(symbol, quantity) {
        setTrade({
            symbol: symbol,
            shares: Number(quantity)
        })
        setIsPlacingOrder(true);
    }

    function symbolFormatter(cell, row) {
        return (
            <>
                {cell} <Link onClick={() => openPlaceTradeModal(row.description, 1)}> ⤴️</Link>
            </>
        )
    }

    return (
        <>

            <>
                <Dropdown onSelect={(e) => setCallPut(e)}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {callPut}
                    </Dropdown.Toggle>
                    <Dropdown.Menu  >
                        <Dropdown.Item as='filterId' eventKey={'call'} value={'call'}>Call</Dropdown.Item>
                        <Dropdown.Item as='filterId' eventKey={'put'} value={'put'}>Put</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <b>Symbol:</b> <input size={5} type="text" onChange={(e) => setSymbol(e.target.value)} value={symbol}></input>
                <br></br>
                <b>Max Price:</b> <input size={5} type="text" onChange={(e) => setMaxPrice(e.target.value)} value={maxPrice}></input>
                <br></br>
                <b>Percent Move:</b> <input size={5} type="text" onChange={(e) => setPullbackPercent(e.target.value)} value={pullbackPercent} />
                <br></br>
                <b>Days:</b> <input size={5} type="text" onChange={(e) => setDays(Number(e.target.value))} value={days} />
                <br></br>
                <Link onClick={reloadData}> 🔄</Link>
                <br></br>
                <br></br>
            </>

            {isLoading ?
                <CircularProgress />
                :
                <>
                    {props.brokerage && props.userId ?
                        <RichTable pageSize={10} headless={false} data={optionCandidates} className="table-condensed" mappers={[
                            { title: 'Symbol', field: 'description', formatter: symbolFormatter },
                            { title: 'Target', field: 'hypotheticalProfitPct', formatter: TableHelpers.percentChangeFormatterOneDecimal, sorters: true },
                            { title: 'Bid', field: 'bid', sorters: true },
                            { title: 'Ask', field: 'ask', sorters: true },
                            { title: 'Delta', field: 'delta', sorters: true },
                            { title: 'Theta %', field: 'thetaPercent', formatter: TableHelpers.percentFormatterNoDecimals, sorters: true },
                            { title: 'Distance', field: 'distanceFromLast', formatter: TableHelpers.percentChangeFormatter, sorters: true },
                        ]} />
                        :
                        "Not logged into brokerage account"
                    }
                </>
            }

            <AutomateTrade brokerage={props.brokerage} trade={trade} userId={props.userId} accountId={props.accountId} isOpen={isPlacingOrder} onClose={() => setIsPlacingOrder(false)} mobileView={props.mobileView} />

        </>
    );
}

export default OptionChain;