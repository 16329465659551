import Algos from './Algos.js';
import React, { useState, useEffect } from 'react';
import AlgosChart from '../algos/AlgosChart.js';
import Drawer from '@mui/material/Drawer';
import { Link } from 'react-router-dom'
import AlgoStats from '../algos/AlgoStats.js';
import ActionResult from '../components/ActionResult.js';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function ManageAlgos(props) {

    const [itemsChanged, setItemsChanged] = useState(0);
    const [planMetadata, setPlanMetadata] = useState({});
    const [enableAlgoUpdates, setEnableAlgoUpdates] = useState(false);
    const [hideAlgos, setHideAlgos] = useState(true)
    const [result, setResult] = useState(null);

    useEffect(() => {
        (async function () {
            let metadata = await (await fetch(`/api/planMetaData?planId=` + props.planId)).json();
            if (metadata && metadata.length)
                setPlanMetadata(metadata[0])
        })();
    }, [props.planId]);


    const onAddAlgoToPlan = async (algoId) => {
        console.log("onAddAlgoToPlan", algoId);

        const text = await (await fetch(`/api/addAlgoToPlan?planId=` + props.planId + `&algoId=` + algoId)).json();
        setResult(text);
        setItemsChanged(itemsChanged + 1);
        console.log(text);
    }

    const onRemoveAlgoFromPlan = async (algoId) => {
        console.log("onRemoveAlgoFromPlan", algoId);

        const text = await (await fetch(`/api/removeAlgoFromPlan?planId=` + props.planId + `&algoId=` + algoId)).json();
        setItemsChanged(itemsChanged + 1);
        console.log(text);
    }

    const onAllocationUpdated = async (algoId) => {
        //setItemsChanged(itemsChanged + 1);
    }

    function shouldAllowEdits() {
        if (props.role === 3) return true;
        if (props.userId === planMetadata.userIdOwner) return true;
        return false;
    }
    function shouldAllowAlgoUpdates() {
        return (props.role === 3) && enableAlgoUpdates;
    }

    async function onAlgoAdded(algoId) {
        console.log("onAlgoAdded", algoId);
        if (shouldAllowEdits()) {
            await onAddAlgoToPlan(algoId);
        } else {
        }
    }

    return (
        <>
            <div>
                <center>
                    <AlgosChart planId={props.planId} itemsChanged={itemsChanged} />
                </center>

                <center>
                    <input type='checkbox' checked={enableAlgoUpdates} onClick={() => setEnableAlgoUpdates(!enableAlgoUpdates)} />Enable Edits
                </center>

                <Algos mobileView={props.mobileView} showStats={true} onAlgoSelected={props.onAlgoSelected} allowEdit={shouldAllowEdits} allowAlgoUpdates={shouldAllowAlgoUpdates} showAssigned={true} planId={props.planId} onAllocationUpdated={onAllocationUpdated} onAddAlgoToPlan={onAddAlgoToPlan} onRemoveAlgoFromPlan={onRemoveAlgoFromPlan} itemsChanged={itemsChanged} />

                <div className='addalgo-button'>
                    <Link onClick={() => setHideAlgos(!hideAlgos)}>
                        <AddCircleIcon sx={{ fontSize: 40 }} />
                    </Link>
                </div>
            </div>
            <br></br>
            <Drawer open={!hideAlgos} onClose={() => setHideAlgos(true)}
                PaperProps={{
                    sx: { width: "90%" },
                }}
            >
                <div style={{ padding: "20px" }}>
                    <center>
                        <b><Link onClick={() => setHideAlgos(true)}>Close</Link></b>
                    </center>

                    <div>
                        <h2>All Algos</h2>
                        <AlgoStats showAll={true} planId={props.planId} mobileView={props.mobileView} onAlgoSelected={props.onAlgoSelected} onAlgoAction={shouldAllowEdits() ? onAlgoAdded : null} />
                    </div>

                    <div className='addalgo-button'>
                        <Link onClick={() => setHideAlgos(!hideAlgos)}>
                            <CheckCircleIcon sx={{ fontSize: 40 }} />
                        </Link>
                    </div>


                    <center>
                        <b><Link onClick={() => setHideAlgos(true)}>Close</Link></b>
                    </center>

                </div>

            </Drawer>

            <ActionResult result={result} />
        </>
    );
}

export default ManageAlgos;