import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Collapsible from 'react-collapsible';
import tableHelpers from '../helpers/TableHelpers.js'
import DailyBalanceChart from './DailyBalanceChart.js';
import RecentReturns from './RecentReturns.js'
import PlanScorecard from './PlanScorecard.js';
import RichTable from '../components/richTable/RichTable.jsx'
import MonthlyBacktestResults from './MonthlyBacktestResults.js';
import CircularProgress from '@mui/material/CircularProgress';
import DailyProfitChart from "./DailyProfitChart.js"

function BacktestResults(props) {

    const [algos, setAlgos] = useState([])

    useEffect(() => {
        (async function () {
            const text = await (await fetch(`/api/algos?planId=`)).json();
            setAlgos(text);
        })();
    }, [props.planId]);

    function getPeriodResult(timeframe, key) {
        return (backtestResults && backtestResults.periodSummaries && backtestResults.periodSummaries[timeframe] && backtestResults.periodSummaries[timeframe][key]) ?
            backtestResults.periodSummaries[timeframe][key] : 0;
    }

    function getDecimalPeriodResult(timeframe, key) {
        return (backtestResults && backtestResults.periodSummaries && backtestResults.periodSummaries[timeframe] && backtestResults.periodSummaries[timeframe][key]) ?
            backtestResults.periodSummaries[timeframe][key].toFixed(2) : 0;
    }

    function getPrintablePeriodResult(timeframe, key) {
        return getPeriodResult(timeframe, key).toFixed(2);
    }

    let backtestResults = props.backtestResults;

    function algoLinkFormatter(cell, row) {
        let algoid = row.algoId;
        return (
            <Link className="bg-transparent" to={"/algo/" + algoid}>{cell}</Link>
        )
    }

    function getAlgoCompositeStats() {

        let stats = { count: 0, winners: 0, winningPct: 0.0, losers: 0, avgDuration: 0.0, avgProfit: 0.0, avgWin: 0.0, avgLoss: 0.0, maxWin: 0.0, maxLoss: 0.0, netProfit: 0.0 }

        if (backtestResults && backtestResults.algoResults && backtestResults.algoResults.length) {
            backtestResults.algoResults.forEach(algo => {
                let winners = algo.count * algo.winning_percentage / 100;
                let losers = algo.count - winners;
                stats.winningPct = (stats.winners + winners) / (stats.count + algo.count) * 100;
                stats.avgProfit = ((stats.count * stats.avgProfit) + algo.average_profit * algo.count) / (stats.count + algo.count);
                stats.avgDuration = ((stats.count * stats.avgDuration) + (algo.average_duration * algo.count)) / (stats.count + algo.count);
                stats.avgWin = ((stats.winners * stats.avgWin) + algo.avg_win * winners) / (stats.winners + winners);
                if (losers)
                    stats.avgLoss = ((stats.losers * stats.avgLoss) + algo.avg_loss * losers) / (stats.losers + losers);
                stats.count += algo.count;
                stats.winners += winners;
                stats.losers += losers;
            })
        }

        return [stats];
    }

    function getResultsByAlgo() {

        if (backtestResults && backtestResults.algoResults) {
            return backtestResults.algoResults.map(algo => {
                const algoInfo = algos.find(a => a.idalgos === algo.algoId);
                const probabilityOfWinning = (algo.winning_percentage / 100);
                const winLossRatio = Math.abs(algo.avg_win / algo.avg_loss);
                return {
                    algoId: algo.algoId,
                    display_name: algoInfo ? algoInfo.display_name : "",
                    trade_symbol: algoInfo ? algoInfo.trade_symbol : "",
                    count: algo.count,
                    winning_percentage: algo.winning_percentage,
                    average_profit: algo.average_profit,
                    total_profit: algo.average_profit * algo.count,
                    average_duration: algo.average_duration,
                    avg_win: algo.avg_win,
                    avg_loss: algo.avg_loss,
                    max_win: algo.max_win,
                    max_loss: algo.max_loss,
                    profit_per_day: algo.average_profit / algo.average_duration,
                    allocation: algo.allocation,
                    percentage_of_profit: algo.percentage_of_profit,
                    kelly: (probabilityOfWinning - ((1 - probabilityOfWinning) / winLossRatio)) * 100
                }
            })
        } else {
            return [];
        }
    }

    function getResultsByStock() {
        if (backtestResults && backtestResults.stockResults)
            return backtestResults.stockResults;
        else
            return [];
    }

    function getMetaData() {
        let data = {};
        data.testId = backtestResults ? backtestResults.testId : 0;
        data.date = backtestResults ? backtestResults.date : null;
        data.status = backtestResults.isCompleted ? "Completed" : "Running";
        data.annualProfit = getPeriodResult("yearly", "avgResult");
        data.endAmount = backtestResults ? backtestResults.totalValue : null;
        data.startAmount = backtestResults ? backtestResults.startAmount : null;
        data.spyEndAmount = backtestResults ? backtestResults.baselineTotalValue : null;
        data.errors = backtestResults ? backtestResults.errorCount : null;
        data.overExtendedWarnings = backtestResults ? backtestResults.overExtendedCount : null;

        return [data];
    }

    return (
        < div >
            {props.isLoading ?

                <center>
                    <br></br>
                    <CircularProgress />
                </center>
                :
                <>
                    <RichTable data={getMetaData()} className="table-striped table-hover table-condensed analytics_hidden" mappers={[
                        { title: 'Date', field: 'date', formatter: tableHelpers.dateFormmatter, hidden: props.mobileView },
                        { title: 'Annual Profit', field: 'annualProfit', formatter: tableHelpers.percentFormatter },
                        { title: 'Start Amount', field: 'startAmount', formatter: tableHelpers.moneyFormatterNoCents, hidden: props.mobileView },
                        { title: 'End Amount', field: 'endAmount', formatter: tableHelpers.moneyFormatterNoCents },
                        { title: 'SPY End Amount', field: 'spyEndAmount', formatter: tableHelpers.moneyFormatterNoCents },
                        { title: 'Out of Money', field: 'overExtendedWarnings', formatter: tableHelpers.intFormatter, hidden: props.mobileView },
                    ]} />

                    <DailyBalanceChart defaultToSyncValues={props.accountStartDate} startDate={props.accountStartDate} backtestResults={props.backtestResults} chartData={props.chartData}></DailyBalanceChart>

                    <br></br>

                    Scorecard
                    <center>
                        <PlanScorecard backtestResults={backtestResults} mobileView={props.mobileView}></PlanScorecard>
                    </center>
                    <br></br>

                    <Collapsible className="bold" trigger="Performance" open={true}>
                        <Table width="75%">
                            <tr>
                                <td></td>
                                <td className="bold" width="15%">Daily</td>
                                <td hidden={props.mobileView} className="bold" width="15%">Weekly</td>
                                <td className="bold" width="15%">Monthly</td>
                                <td hidden={props.mobileView} className="bold" width="15%">Quarterly</td>
                                <td className="bold" width="15%">Yearly</td>
                            </tr>
                            <tr>
                                <td className="bold">Average Profit</td>
                                <td className="bold">{getPrintablePeriodResult("daily", "avgResult")}%</td>
                                <td hidden={props.mobileView} className="bold">{getPrintablePeriodResult("weekly", "avgResult")}%</td>
                                <td className="bold">{getPrintablePeriodResult("monthly", "avgResult")}%</td>
                                <td hidden={props.mobileView} className="bold">{getPrintablePeriodResult("quarterly", "avgResult")}%</td>
                                <td className="bold">{getPrintablePeriodResult("yearly", "avgResult")}%</td>
                            </tr>
                            <tr>
                                <td>Average SPY Profit</td>
                                <td >{getPrintablePeriodResult("daily", "avgResultSpy")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("weekly", "avgResultSpy")}%</td>
                                <td>{getPrintablePeriodResult("monthly", "avgResultSpy")}%</td>
                                <td hidden={props.mobileView}>  {getPrintablePeriodResult("quarterly", "avgResultSpy")}%</td>
                                <td>{getPrintablePeriodResult("yearly", "avgResultSpy")}%</td>
                            </tr>
                            <tr>
                                <td >Sharpe Ratio</td>
                                <td >{getDecimalPeriodResult("daily", "sharpeRatio")}</td>
                                <td hidden={props.mobileView} >{getDecimalPeriodResult("weekly", "sharpeRatio")}</td>
                                <td >{getDecimalPeriodResult("monthly", "sharpeRatio")}</td>
                                <td hidden={props.mobileView} >{getDecimalPeriodResult("quarterly", "sharpeRatio")}</td>
                                <td>{getDecimalPeriodResult("yearly", "sharpeRatio")}</td>
                            </tr>
                            <tr>
                                <td >MAR Ratio</td>
                                <td >{getDecimalPeriodResult("daily", "marRatio")}</td>
                                <td hidden={props.mobileView} >{getDecimalPeriodResult("weekly", "marRatio")}</td>
                                <td >{getDecimalPeriodResult("monthly", "marRatio")}</td>
                                <td hidden={props.mobileView} >{getDecimalPeriodResult("quarterly", "marRatio")}</td>
                                <td>{getDecimalPeriodResult("yearly", "marRatio")}</td>
                            </tr>
                            <tr>
                                <td className="bold">Profitable %</td>
                                <td className="bold">{getPrintablePeriodResult("daily", "winningPercentage")}%</td>
                                <td hidden={props.mobileView} className="bold">{getPrintablePeriodResult("weekly", "winningPercentage")}%</td>
                                <td className="bold">{getPrintablePeriodResult("monthly", "winningPercentage")}%</td>
                                <td hidden={props.mobileView} className="bold">{getPrintablePeriodResult("quarterly", "winningPercentage")}%</td>
                                <td className="bold">{getPrintablePeriodResult("yearly", "winningPercentage")}%</td>
                            </tr>
                            <tr>
                                <td>SPY Profitable %</td>
                                <td >{getPrintablePeriodResult("daily", "spyWinningPercentage")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("weekly", "spyWinningPercentage")}%</td>
                                <td >{getPrintablePeriodResult("monthly", "spyWinningPercentage")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("quarterly", "spyWinningPercentage")}%</td>
                                <td >{getPrintablePeriodResult("yearly", "spyWinningPercentage")}%</td>
                            </tr>
                            <tr>
                                <td >Profit StdDev</td>
                                <td >{getPrintablePeriodResult("daily", "resultStdDev")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("weekly", "resultStdDev")}%</td>
                                <td >{getPrintablePeriodResult("monthly", "resultStdDev")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("quarterly", "resultStdDev")}%</td>
                                <td>{getPrintablePeriodResult("yearly", "resultStdDev")}%</td>
                            </tr>
                            <tr>
                                <td>Average Win</td>
                                <td >{getPrintablePeriodResult("daily", "avgWin")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("weekly", "avgWin")}%</td>
                                <td>{getPrintablePeriodResult("monthly", "avgWin")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("quarterly", "avgWin")}%</td>
                                <td>{getPrintablePeriodResult("yearly", "avgWin")}%</td>
                            </tr>
                            <tr>
                                <td>Average Loss</td>
                                <td >{getPrintablePeriodResult("daily", "avgLoss")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("weekly", "avgLoss")}%</td>
                                <td>{getPrintablePeriodResult("monthly", "avgLoss")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("quarterly", "avgLoss")}%</td>
                                <td>{getPrintablePeriodResult("yearly", "avgLoss")}%</td>
                            </tr>
                            <tr>
                                <td>Best</td>
                                <td >{getPrintablePeriodResult("daily", "best")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("weekly", "best")}%</td>
                                <td>{getPrintablePeriodResult("monthly", "best")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("quarterly", "best")}%</td>
                                <td>{getPrintablePeriodResult("yearly", "best")}%</td>
                            </tr>
                            <tr>
                                <td>Worst</td>
                                <td  >{getPrintablePeriodResult("daily", "worst")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("weekly", "worst")}%</td>
                                <td>{getPrintablePeriodResult("monthly", "worst")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("quarterly", "worst")}%</td>
                                <td>{getPrintablePeriodResult("yearly", "worst")}%</td>
                            </tr>
                            <tr>
                                <td className="bold">Beat SPY</td>
                                <td className="bold">{getPrintablePeriodResult("daily", "winningPercentageSpy")}%</td>
                                <td hidden={props.mobileView} className="bold">{getPrintablePeriodResult("weekly", "winningPercentageSpy")}%</td>
                                <td className="bold">{getPrintablePeriodResult("monthly", "winningPercentageSpy")}%</td>
                                <td hidden={props.mobileView} className="bold">{getPrintablePeriodResult("quarterly", "winningPercentageSpy")}%</td>
                                <td className="bold">{getPrintablePeriodResult("yearly", "winningPercentageSpy")}%</td>
                            </tr>
                        </Table>
                    </Collapsible>

                    <br></br>

                    <Collapsible className="bold" trigger="Risk" open={true}>
                        <Table>
                            <tr>
                                <td></td>
                                <td className="bold" width="15%">Daily</td>
                                <td hidden={props.mobileView} className="bold" width="15%">Weekly</td>
                                <td className="bold" width="15%">Monthly</td>
                                <td hidden={props.mobileView} className="bold" width="15%">Quarterly</td>
                                <td className="bold" width="15%">Yearly</td>
                            </tr>
                            <tr>
                                <td>Max Drawdown</td>
                                <td >{getPrintablePeriodResult("daily", "maxDrawdown")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("weekly", "maxDrawdown")}%</td>
                                <td>{getPrintablePeriodResult("monthly", "maxDrawdown")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("quarterly", "maxDrawdown")}%</td>
                                <td>{getPrintablePeriodResult("yearly", "maxDrawdown")}%</td>
                            </tr>
                            <tr>
                                <td>Max SPY Drawdown</td>
                                <td  >{getPrintablePeriodResult("daily", "maxSpyDrawdown")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("weekly", "maxSpyDrawdown")}%</td>
                                <td>{getPrintablePeriodResult("monthly", "maxSpyDrawdown")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("quarterly", "maxSpyDrawdown")}%</td>
                                <td>{getPrintablePeriodResult("yearly", "maxSpyDrawdown")}%</td>
                            </tr>
                            <tr>
                                <td>Average Drawdown</td>
                                <td  >{getPrintablePeriodResult("daily", "avgDrawdown")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("weekly", "avgDrawdown")}%</td>
                                <td>{getPrintablePeriodResult("monthly", "avgDrawdown")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("quarterly", "avgDrawdown")}%</td>
                                <td>{getPrintablePeriodResult("yearly", "avgDrawdown")}%</td>
                            </tr>
                            <tr>
                                <td>Average SPY Drawdown</td>
                                <td  >{getPrintablePeriodResult("daily", "avgDrawdownSpy")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("weekly", "avgDrawdownSpy")}%</td>
                                <td>{getPrintablePeriodResult("monthly", "avgDrawdownSpy")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("quarterly", "avgDrawdownSpy")}%</td>
                                <td>{getPrintablePeriodResult("yearly", "avgDrawdownSpy")}%</td>
                            </tr>
                            <tr>
                                <td>Drawdown Time Spent</td>
                                <td  >{getPrintablePeriodResult("daily", "drawdownPercentPeriods")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("weekly", "drawdownPercentPeriods")}%</td>
                                <td>{getPrintablePeriodResult("monthly", "drawdownPercentPeriods")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("quarterly", "drawdownPercentPeriods")}%</td>
                                <td>{getPrintablePeriodResult("yearly", "avgDradrawdownPercentPeriodswdown")}%</td>
                            </tr>
                            <tr>
                                <td>SPY Drawdown Time Spent</td>
                                <td  >{getPrintablePeriodResult("daily", "drawdownPercentPeriodsSpy")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("weekly", "drawdownPercentPeriodsSpy")}%</td>
                                <td>{getPrintablePeriodResult("monthly", "drawdownPercentPeriodsSpy")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("quarterly", "drawdownPercentPeriodsSpy")}%</td>
                                <td>{getPrintablePeriodResult("yearly", "drawdownPercentPeriodsSpy")}%</td>
                            </tr>
                            <tr>
                                <td>Average Volatility</td>
                                <td  >{getPrintablePeriodResult("daily", "avgVolatility")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("weekly", "avgVolatility")}%</td>
                                <td>{getPrintablePeriodResult("monthly", "avgVolatility")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("quarterly", "avgVolatility")}%</td>
                                <td>{getPrintablePeriodResult("yearly", "avgVolatility")}%</td>
                            </tr>
                            <tr>
                                <td>Average SPY Volatility</td>
                                <td  >{getPrintablePeriodResult("daily", "avgSpyVolatility")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("weekly", "avgSpyVolatility")}%</td>
                                <td>{getPrintablePeriodResult("monthly", "avgSpyVolatility")}%</td>
                                <td hidden={props.mobileView} >{getPrintablePeriodResult("quarterly", "avgSpyVolatility")}%</td>
                                <td>{getPrintablePeriodResult("yearly", "avgSpyVolatility")}%</td>
                            </tr>
                        </Table>
                    </Collapsible>

                    <br></br>

                    <Collapsible open={true} className="bold" trigger="Month by Month">

                        <MonthlyBacktestResults backtestResults={props.backtestResults} />

                    </Collapsible>

                    {
                        props.liveTradesOnly ?
                            <>
                                <br></br>
                                <RecentReturns backtestResults={backtestResults} accountStartDate={props.accountStartDate} accountValue={props.accountValue} mobileView={props.mobileView}></RecentReturns>
                            </>
                            :
                            null
                    }

                    <br></br>
                    <DailyProfitChart planId={props.planId} backtestResults={backtestResults} />

                    <br></br>
                    <br></br>
                    <RichTable data={getAlgoCompositeStats()} className="table-striped table-hover table-condensed fixed" mappers={[
                        { title: 'Count', field: 'count', formatter: tableHelpers.intFormatter, hidden: props.mobileView },
                        { title: 'Win %', field: 'winningPct', formatter: tableHelpers.percentFormatter },
                        { title: 'Avg Profit', field: 'avgProfit', formatter: tableHelpers.percentFormatter },
                        { title: 'Avg Duration', field: 'avgDuration', formatter: tableHelpers.intFormatter },
                        { title: 'Avg Win', field: 'avgWin', formatter: tableHelpers.percentFormatter, hidden: props.mobileView },
                        { title: 'Avg Loss', field: 'avgLoss', formatter: tableHelpers.percentFormatter, hidden: props.mobileView },
                    ]} />

                    <br></br>
                    <Collapsible open={true} className="bold" trigger="Results by Stock">
                        <RichTable data={getResultsByStock()} className="table-striped table-hover table-condensed fixed" mappers={[
                            { title: 'Symbol', field: 'trade_symbol', sorters: true },
                            { title: 'Trades', field: 'count', formatter: tableHelpers.intFormatter, sorters: true },
                            { title: 'Win %', field: 'winning_percentage', formatter: tableHelpers.percentFormatter, sorters: true },
                            { title: 'Avg P/L', field: 'average_profit', formatter: tableHelpers.percentFormatter, sorters: true },
                            { title: 'Avg Duration', field: 'average_duration', formatter: tableHelpers.intFormatter, hidden: props.mobileView, sorters: true },
                            { title: 'Allocation', field: 'allocation', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                            { title: '% of Profit', field: 'percentage_of_profit', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                        ]} />
                    </Collapsible>

                    <br></br>
                    <Collapsible open={false} className="bold" trigger="Results by Algo">
                        <RichTable data={getResultsByAlgo()} className="table-striped table-hover table-condensed fixed" mappers={[
                            { title: 'Algo', field: 'display_name', sorters: true, width: '135px', formatter: algoLinkFormatter },
                            { title: 'Symbol', field: 'trade_symbol', sorters: true },
                            { title: 'Trades', field: 'count', formatter: tableHelpers.intFormatter, sorters: true, hidden: props.mobileView },
                            { title: 'Win %', field: 'winning_percentage', formatter: tableHelpers.percentFormatter, sorters: true },
                            { title: 'Avg P/L', field: 'average_profit', formatter: tableHelpers.percentFormatter, sorters: true },
                            { title: 'Total Profit', field: 'total_profit', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                            { title: 'Avg Duration', field: 'average_duration', formatter: tableHelpers.intFormatter, hidden: props.mobileView, sorters: true },
                            { title: 'Avg Win', field: 'avg_win', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                            { title: 'Avg Loss', field: 'avg_loss', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                            { title: 'Max Win', field: 'max_win', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                            { title: 'Max Loss', field: 'max_loss', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                            { title: 'Kelly', field: 'kelly', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                            { title: 'Allocation', field: 'allocation', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                            { title: '% of Profit', field: 'percentage_of_profit', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                        ]} />

                    </Collapsible>
                </>
            }
        </div >
    );
}

export default BacktestResults;