import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import DatePicker from "react-datepicker";
import RichTable from '../components/richTable/RichTable.jsx'
import tableHelpers from '../helpers/TableHelpers.js'
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import Drawer from '@mui/material/Drawer';

function AlgoCorrelation(props) {
    const [backtestTrades, setBacktestTrades] = useState([]);
    const [quoteHistory, setQuoteHistory] = useState([])
    const [startDate, setStartDate] = useState(new Date('2023-01-02'))
    const [endDate, setEndDate] = useState(new Date())
    const [algoList, setAlgoList] = useState([]);
    const [selectedAlgos, setSelectedAlgos] = useState([])
    const [updates, setUpdates] = useState(0);
    const [symbols, setSymbols] = useState([])
    const [hideAlgos, setHideAlgos] = useState(false)
    const [monthlyStat, setMonthlyStat] = useState('winPercentage')
    const [showDrawdown, setShowDrawdown] = useState(false);
    const [columns, setColumns] = useState(3);
    const [warningPercent, setWarningPercent] = useState(.50);

    const shouldRenderTrade = (trade, start, end) => {
        const buyDate = new Date(trade.purchase_date);
        if (buyDate >= start && buyDate <= end)
            return true;
        const sellDate = new Date(trade.sell_date);
        if (sellDate >= start && sellDate <= end)
            return true;
        return false;
    }

    const shouldRenderAlgo = (algo) => {
        return symbols.length === 0 || symbols.find(s => algo.symbol);
    }


    useEffect(() => {
        (async function () {

            if (algoList.length === 0) {
                let algoList = await (await fetch(`/api/algos?planId=` + props.planId)).json();
                setAlgoList(algoList);
            }

            if (selectedAlgos.length) {
                console.log("Getting trades", selectedAlgos)
                let algos = "";
                selectedAlgos.forEach(a => {
                    algos += a + ",";
                })
                const query = `/api/backtestTrades?algos=` + algos +
                    "&startDate=" + startDate.toLocaleDateString() +
                    "&endDate=" + endDate.toLocaleDateString();
                console.log(query);
                let trades = await (await fetch(query)).json();
                console.log("Retrieved " + trades.length + " trades", trades);
                setBacktestTrades(trades);

                let symbolString = "";
                symbols.forEach(s => symbolString += s + ",");
                const url = `/api/quoteHistory?symbols=` + symbolString +
                    "&startDate=" + startDate.toLocaleDateString() +
                    "&endDate=" + endDate.toLocaleDateString();
                let history = await (await fetch(url)).json();
                Object.keys(history).forEach(sym => {
                    console.log(sym, history[sym])
                    history[sym].reverse();
                })
                setQuoteHistory(history);
            }
        })();
    }, [props.planId, algoList.length, selectedAlgos, updates, startDate, endDate, symbols, symbols.length]);

    function getChartData(algo) {

        let retVal = {
            maxPrice: 0,
            minPrice: 10000000,
            data: []
        };

        const symbol = algo.trade_symbol;

        const history = quoteHistory[symbol];
        if (history) {
            history.forEach(quote => {
                const date = new Date(quote.date);
                const dateString = (date.getUTCMonth() + 1) + "/" + date.getUTCDate() + "/" + (date.getUTCFullYear() - 2000);

                if (showDrawdown) {
                    retVal.minPrice = 0;

                    let drawdown = 0;
                    backtestTrades.forEach(trade => {
                        if (trade.algoId === algo.idalgos) {
                            const buyDate = new Date(trade.purchase_date);
                            const sellDate = new Date(trade.sell_date);
                            if ((date >= buyDate && date <= sellDate) ||
                                (date >= buyDate && trade.sell_date === null)) {
                                drawdown = (trade.purchase_price - quote.close) / trade.purchase_price * 100;
                                if (drawdown < 0) drawdown = 0;
                                if (drawdown > retVal.maxPrice) retVal.maxPrice = drawdown;
                            }
                        }
                    })
                    retVal.data.push([dateString, 0, drawdown]);
                } else {
                    if (quote.close > retVal.maxPrice) retVal.maxPrice = quote.close;
                    if (quote.close < retVal.minPrice) retVal.minPrice = quote.close;
                    let long = 0;

                    backtestTrades.forEach(trade => {
                        if (trade.algoId === algo.idalgos) {
                            const buyDate = new Date(trade.purchase_date);
                            const sellDate = new Date(trade.sell_date);
                            if ((date >= buyDate && date <= sellDate) ||
                                (date >= buyDate && trade.sell_date === null)) long = quote.close;
                        }
                    })
                    retVal.data.push([dateString, quote.close, long]);
                }
            })
        } else {
            console.error("Could not find quote data for algo", algo, Object.keys(quoteHistory));
        }

        if (retVal.data.length)
            retVal.data.unshift(['Date', 'Price', 'Long']);

        return retVal;
    }

    function renderChartRow(row) {
        return row.map(algoId => {
            const algo = algoList.find(a => a.idalgos === algoId);
            if (algo) {
                return (<td>{renderChart(algo)}</td>)
            } else
                return <></>
        })
    }

    function renderCharts() {

        let rows = [];
        let row = [];
        selectedAlgos.forEach(algoId => {
            row.push(algoId);
            if (row.length === columns) {
                rows.push([...row]);
                row = [];
            }
        })
        if (row.length) rows.push([...row])
        return (
            <>
                <table>
                    {
                        rows.map(row => {
                            return <tr>{renderChartRow(row)}</tr>
                        })
                    }
                </table>
            </>
        )
    }

    function renderChart(algo) {

        let chartData = getChartData(algo);

        const options = {
            vAxis: {
                viewWindowMode: 'explicit',
                viewWindow: {
                    max: chartData.maxPrice * 1.05,
                    min: chartData.minPrice * .9
                },
                minValue: chartData.minPrice * .9,
                maxValue: chartData.maxPrice * 1.05
            },
            chartArea: { 'width': '75%', 'height': '75%' },
            legend: { position: "none" },
            title: algo.display_name + " (" + algo.trade_symbol + ")"
        };

        return (
            <>
                {
                    chartData.data.length ?
                        <Chart
                            chartType="AreaChart"
                            data={chartData.data}
                            options={options}
                            height={"300px"}
                            width={"400px"}
                            chartPackages={["corechart", "controls"]}
                        />
                        :
                        null
                }
            </>
        );
    }

    function getBacktestTrades() {
        return backtestTrades.filter(trade => shouldRenderTrade(trade, startDate, endDate)).map(trade => {
            const durationInDays = trade.sell_date ? (new Date(trade.sell_date).getTime() - new Date(trade.purchase_date).getTime()) / (1000 * 60 * 60 * 24) : 0;
            const algo = algoList.find(a => a.idalgos === trade.algoId);
            return {
                purchase_date: trade.purchase_date,
                purchase_price: trade.purchase_price,
                sell_date: trade.sell_date,
                sell_price: trade.sell_price,
                duration: durationInDays > 0 ? durationInDays : null,
                profit: trade.profit,
                algoId: trade.algoId,
                display_name: algo.display_name,
                symbol: algo.trade_symbol,
                allocation: algo.percentage,
                netProfit: trade.profit * algo.percentage / 100
            }
        })
    }

    function getAlgoList() {
        return algoList.filter(a => shouldRenderAlgo(a)).map(algo => {
            return {
                display_name: algo.display_name,
                trade_symbol: algo.trade_symbol,
                symbol: algo.symbol,
                algoId: algo.idalgos,
                percentage: algo.percentage
            }
        })
    }

    function renderRealizedGainChart() {

        let chartData = [];
        chartData.push(['Date', 'Gain', { role: "style" }]);

        const trades = getBacktestTrades();
        const end = new Date(endDate);
        let date = new Date(startDate);
        let balance = 0;
        let maxValue = balance;
        let minValue = balance;
        while (date <= end) {
            for (const trade of trades) {
                const closeDate = new Date(trade.sell_date);
                if (date.getFullYear() === closeDate.getFullYear() &&
                    date.getMonth() === closeDate.getMonth() &&
                    date.getDate() === closeDate.getDate()) {

                    const algo = algoList.find(a => a.idalgos === trade.algoId);
                    const profit = trade.profit / 100 * (algo.percentage / 100);
                    balance += profit;
                }
                if (balance > maxValue) maxValue = balance;
                if (balance < minValue) minValue = balance;
            }

            let color = balance > 0 ? "#A0DDFD" : "#B85D5D";
            let dateString = (date.getUTCMonth() + 1) + "/" + date.getUTCDate() + "/" + (date.getUTCFullYear() - 2000);
            chartData.push([dateString, balance, color])
            date.setDate(date.getDate() + 1)
        }

        const options = {
            title: "Realized P/L (not compounded)",
            vAxis: {
                format: '#%',
                viewWindowMode: 'explicit',
                viewWindow: {
                    max: maxValue + (Math.abs(maxValue) * .1),
                    min: minValue - (Math.abs(minValue) * .1)
                },
                minValue: maxValue + (Math.abs(maxValue) * .1),
                maxValue: minValue - (Math.abs(minValue) * .1)
            },
            chartArea: { 'width': '75%', 'height': '50%' },
            legend: { position: 'none' }
        };

        if (balance === 0) {
            return (<></>);
        } else {
            return (
                <div>
                    <center>
                        <Chart
                            chartType="AreaChart"
                            data={chartData}
                            width={"100%"}
                            height={"200px"}
                            options={options}
                            chartPackages={["corechart", "controls"]}
                        />
                    </center>
                </div>
            )
        }
    }

    function getCorrelationData() {
        let chartData = [];
        chartData.push(['Date', 'Long', 'Short']);

        const history = Object.keys(quoteHistory).length ? quoteHistory[Object.keys(quoteHistory)[0]] : null;
        if (history) {
            history.forEach(quote => {
                const date = new Date(quote.date);
                let long = 0;
                let short = 0;
                backtestTrades.forEach(trade => {
                    const buyDate = new Date(trade.purchase_date);
                    const sellDate = new Date(trade.sell_date);
                    if ((date >= buyDate && date <= sellDate) ||
                        (date >= buyDate && trade.sell_date === null)) {

                        const algo = algoList.find(a => a.idalgos === trade.algoId);
                        if (algo.short)
                            short += algo.percentage;
                        else
                            long += algo.percentage;
                    }
                })
                let dateString = (date.getUTCMonth() + 1) + "/" + date.getUTCDate() + "/" + (date.getUTCFullYear() - 2000);
                chartData.push([dateString, long, short]);
            })
            return chartData;
        }
        return chartData;
    }

    function renderCorrelationChart() {

        const chartData = getCorrelationData();
        const options = {
            title: "Total Allocation (Long & Short)",
            chartArea: { 'width': '75%', 'height': '50%' },
            legend: { position: 'none' },
            vAxis: {
                viewWindowMode: 'explicit',
                viewWindow: {
                    max: 100,
                    min: 0
                },
            }
        };

        if (chartData.length <= 1) {
            return (<></>);
        } else {
            return (
                <div>
                    <center>
                        <Chart
                            chartType="AreaChart"
                            data={chartData}
                            width={"100%"}
                            height={"200px"}
                            options={options}
                            chartPackages={["corechart", "controls"]}
                        />
                    </center>
                </div>
            )
        }
    }

    function getMatrixBgColor(cell, row) {
        if (cell > warningPercent * 100) return 'yellow'
        else return undefined
    }

    function getCorrelationMatrix() {

        let result = {
            matrix: {},
            columns: [],
            tableData: []
        }

        selectedAlgos.forEach(a => {
            result.matrix[a] = {};
            result.matrix[a].totalDays = 0;
            selectedAlgos.forEach(b => {
                result.matrix[a][b] = 0;
            })
        })


        const history = Object.keys(quoteHistory).length ? quoteHistory[Object.keys(quoteHistory)[0]] : null;
        if (history) {
            history.forEach(quote => {
                const date = new Date(quote.date);

                let trades = backtestTrades.filter(s => {
                    return (new Date(s.purchase_date) <= date && new Date(s.sell_date) > date)
                });

                trades.forEach(t => {
                    if (result.matrix[t.algoId])
                        ++result.matrix[t.algoId].totalDays;
                })

                selectedAlgos.forEach(a => {
                    selectedAlgos.forEach(b => {
                        if (trades.find(t => t.algoId === a) && trades.find(t => t.algoId === b)) {
                            ++result.matrix[a][b];
                        }
                    })
                })
            });
        }

        const keys = Object.keys(result.matrix);
        keys.sort();
        result.columns.push(
            { title: "Algo", field: 'algo', formatter: algoLinkFormatter }
        );
        result.columns.push(
            { title: "Days", field: 'days' }
        );
        keys.forEach(key => {
            console.log(algoList);
            let algo = algoList.find(a => a.idalgos === Number(key));

            let row = {};
            row.algo = algo.display_name;
            row.algoId = algo.idalgos;
            row.days = result.matrix[key].totalDays;
            keys.forEach(subKey => {
                if (key === subKey)
                    row[subKey] = null;
                else
                    row[subKey] = result.matrix[key][subKey] / result.matrix[key].totalDays * 100;
            })

            result.columns.push(
                { title: algo.display_name, field: key, formatter: tableHelpers.percentFormatterNoDecimals, bgcolor: getMatrixBgColor }
            );

            result.tableData.push(row);
        })

        return result;
    }

    function getCorrelationStats() {

        const history = Object.keys(quoteHistory).length ? quoteHistory[Object.keys(quoteHistory)[0]] : null;
        let stats = {
            totalDays: 0,
            daysInMarket: 0,
            daysAllInMarket: 0,
            buyDays: 0,
            sellDays: 0,
            daysAllBought: 0,
            daysAllSold: 0,
            percentDaysAllIn: 0,
            percentDaysAllBought: 0,
            percentDaysAllSold: 0,
            percentDaysInMarket: 0,
        };

        if (history) {
            history.forEach(quote => {
                const date = new Date(quote.date);

                stats.totalDays++;

                let trades = backtestTrades.filter(s => {
                    return (new Date(s.purchase_date) <= date && new Date(s.sell_date) > date)
                });
                let buyTrades = backtestTrades.filter(s => {
                    const purchDate = new Date(s.purchase_date)
                    return (purchDate.getFullYear() === date.getFullYear() &&
                        purchDate.getMonth() === date.getMonth() &&
                        purchDate.getDate() === date.getDate())
                });
                let sellTrades = backtestTrades.filter(s => {
                    const sellDate = new Date(s.sell_date)
                    return (sellDate.getFullYear() === date.getFullYear() &&
                        sellDate.getMonth() === date.getMonth() &&
                        sellDate.getDate() === date.getDate())
                });

                if (trades.length) {
                    ++stats.daysInMarket;
                }
                if (buyTrades.length) {
                    ++stats.buyDays;
                }
                if (sellTrades.length) {
                    ++stats.sellDays;
                }
                if (trades.length === selectedAlgos.length) {
                    ++stats.daysAllInMarket;
                }
                if (buyTrades.length === selectedAlgos.length) {
                    stats.daysAllBought += selectedAlgos.length;
                }
                if (sellTrades.length === selectedAlgos.length) {
                    stats.daysAllSold += selectedAlgos.length;
                }
            })
        }

        console.log("correlation stats", stats);
        stats.percentDaysInMarket = stats.daysInMarket / stats.totalDays * 100;
        stats.percentDaysAllIn = stats.daysAllInMarket / stats.daysInMarket * 100;
        stats.percentBuyOverlapTrades = stats.daysAllBought / backtestTrades.length * 100;
        stats.percentSellOverlapTrades = stats.daysAllSold / backtestTrades.length * 100;

        return [stats]
    }

    function renderCorrelationStats() {
        return (
            <div style={{ width: '100%' }}>
                <RichTable data={getCorrelationStats()} className="table-striped table-hover table-condensed fixed" mappers={[
                    { title: 'Total Days', field: 'totalDays', formatter: tableHelpers.intFormatter },
                    { title: 'Days In Market', field: 'percentDaysInMarket', formatter: tableHelpers.percentFormatter },
                    { title: '100% Correlation Days', field: 'percentDaysAllIn', formatter: tableHelpers.percentFormatter },
                    { title: 'Buy Trades', field: 'percentBuyOverlapTrades', formatter: tableHelpers.percentFormatter },
                    { title: 'Sell Trades', field: 'percentSellOverlapTrades', formatter: tableHelpers.percentFormatter },
                ]} />
            </div>
        );
    }

    function addSymbol(symbol) {
        let s = symbols;
        if (!s.find(s => s === symbol)) s.push(symbol);
        setSymbols(s);
    }

    function onAlgoAdd(algoId, sym) {
        console.log("Adding algo to list", algoId);
        let list = selectedAlgos;
        addSymbol(sym);
        list.push(algoId);
        setSelectedAlgos(list);
        setUpdates(updates + 1);
    }

    function onAlgoRemove(algoId, sym) {
        console.log("Adding algo to list", algoId);
        let list = selectedAlgos.filter(t => t !== algoId);
        setSelectedAlgos(list);
        setUpdates(updates + 1);
    }

    function onDecreaseAlgo(algoId, percentage) {
        let algo = algoList.find(a => a.idalgos === algoId);
        algo.percentage -= 1;
        setUpdates(updates + 1);
    }

    function onIncreaseAlgo(algoId, percentage) {
        let algo = algoList.find(a => a.idalgos === algoId);
        algo.percentage += 1;
        setUpdates(updates + 1);
    }

    function addLinkFormatter(cell, row) {
        let algoId = row.algoId;
        let percentage = row.percentage;
        return (
            <>
                <Link className="plus-minus-button" onClick={() => onDecreaseAlgo(algoId, percentage)}>-</Link>
                &nbsp;{percentage}&nbsp;
                <Link className="plus-minus-button" onClick={() => onIncreaseAlgo(algoId, percentage)}>+</Link>
            </>
        )
    }

    function allocationLinksFormatter(cell, row) {
        let algoid = row.algoId;
        let sym = row.trade_symbol;

        if (selectedAlgos.length && selectedAlgos.find(t => t === algoid) !== undefined) {
            return (
                <Link className="bg-transparent" onClick={() => onAlgoRemove(algoid, sym)}>Remove</Link>
            )
        } else {
            return (
                <Link className="bg-transparent" onClick={() => onAlgoAdd(algoid, sym)}>Add</Link>
            )
        }
    }

    function onClearSelectedAlgos() {
        setSelectedAlgos([]);
        setSymbols([]);
        setBacktestTrades([])
        setQuoteHistory({})
        setUpdates(updates + 1);
    }

    function onAddAllAlgos() {
        let list = [];
        algoList.forEach(a => {
            list.push(a.idalgos);
            addSymbol(a.trade_symbol);
        })
        setSelectedAlgos(list);

        setUpdates(updates + 1);
    }

    async function onAddActiveAlgos() {
        let list = [];

        const url = `/api/activeTrades/?planId=` + props.planId;
        let activeTrades = await (await fetch(url)).json();
        if (activeTrades && Array.isArray(activeTrades)) {
            activeTrades.forEach(trade => {
                list.push(trade.algoId);
                addSymbol(trade.trade_symbol);
            })
        }
        setSelectedAlgos(list);
        setUpdates(updates + 1);
    }

    function handleYearClicked(year) {
        let start = new Date(year, 0, 1);
        let end = new Date(year, 11, 31);
        setStartDate(start);
        setEndDate(end);
    }

    function getMonthlyStatistic(year, month) {
        let start = new Date(year, month, 1);
        let end = new Date(year, month + 1, 1);

        let trades = backtestTrades.filter(s => {
            return (new Date(s.sell_date) >= start && new Date(s.sell_date) < end)
        });
        let stats = { count: 0, wins: 0, totalProfit: 0, netProfit: 0 }
        trades.forEach(trade => {
            const algo = algoList.find(a => a.idalgos === trade.algoId);

            ++stats.count;
            if (trade.profit >= 0)
                ++stats.wins;
            stats.totalProfit += Number(trade.profit);
            stats.netProfit += trade.profit * algo.percentage / 100;
        })
        switch (monthlyStat) {
            case 'winPercentage': return stats.wins / stats.count * 100;
            case 'count': return stats.count;
            case 'avgProfit': return stats.totalProfit / stats.count;
            case 'totalProfit': return stats.totalProfit;
            case 'netProfit': return stats.netProfit;
            case 'avgNetProfit': return stats.netProfit / stats.count;

            default: return stats.wins / stats.count * 100;
        }
    }

    function getMonthlyFormat() {
        switch (monthlyStat) {
            case 'count': return tableHelpers.intFormatter;
            default: return tableHelpers.percentFormatter;

        }
    }

    function algoLinkFormatter(cell, row) {
        let algoid = row.algoId;
        return (
            <Link className="bg-transparent" to={"/algo/" + algoid}> {cell}</Link >
        )
    }

    function getMonthlyData() {
        let d = new Date(startDate);
        let result = [];
        while (d.getFullYear() <= endDate.getFullYear()) {
            result.push(
                {
                    year: d.getFullYear(),
                    jan: getMonthlyStatistic(d.getFullYear(), 0),
                    feb: getMonthlyStatistic(d.getFullYear(), 1),
                    mar: getMonthlyStatistic(d.getFullYear(), 2),
                    apr: getMonthlyStatistic(d.getFullYear(), 3),
                    may: getMonthlyStatistic(d.getFullYear(), 4),
                    jun: getMonthlyStatistic(d.getFullYear(), 5),
                    jul: getMonthlyStatistic(d.getFullYear(), 6),
                    aug: getMonthlyStatistic(d.getFullYear(), 7),
                    sep: getMonthlyStatistic(d.getFullYear(), 8),
                    oct: getMonthlyStatistic(d.getFullYear(), 9),
                    nov: getMonthlyStatistic(d.getFullYear(), 10),
                    dec: getMonthlyStatistic(d.getFullYear(), 11),
                }
            );
            d.setFullYear(d.getFullYear() + 1);
        }

        return result;
    }

    let matrix = getCorrelationMatrix();

    return (
        <>
            <br></br>
            <center>
                Start Date: <DatePicker showMonthYearPicker className="bg-transparent" selected={startDate} onChange={(date) => setStartDate(date)} />&nbsp;&nbsp;
                End Date: <DatePicker showMonthYearPicker className="bg-transparent" selected={endDate} onChange={(date) => setEndDate(date)} />

                <br></br>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2025) }}>2025</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2024) }}>2024</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2023) }}>2023</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2022) }}>2022</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2021) }}>2021</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2020) }}>2020</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2019) }}>2019</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2018) }}>2018</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2017) }}>2017</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2016) }}>2016</Link>
                <br></br>

            </center>
            <Drawer open={!hideAlgos} onClose={() => setHideAlgos(true)}>
                <div style={{ padding: "20px" }}>
                    <div class="sameline">
                        <br></br>
                        <br></br>
                        <Link className="bg-transparent" onClick={() => onClearSelectedAlgos()}>Clear</Link>
                        &nbsp;&nbsp;&nbsp;<Link className="bg-transparent" onClick={() => onAddAllAlgos()}>Add All</Link>
                        &nbsp;&nbsp;&nbsp;<Link className="bg-transparent" onClick={() => onAddActiveAlgos()}>Add Active</Link>
                    </div>
                    <br></br>

                    <RichTable data={getAlgoList()} className="table-striped table-hover table-condensed" mappers={[
                        { title: 'Name', field: 'display_name', width: '150px', sorters: true, formatter: algoLinkFormatter, filter: 'text' },
                        { title: 'Symbol', field: 'trade_symbol', width: '25px', sorters: true, filter: 'text' },
                        { title: '%', field: 'algoId', width: '30px', formatter: addLinkFormatter, sorters: true, hidden: true },
                        { title: 'Add', field: 'percentage', width: '35px', formatter: allocationLinksFormatter }
                    ]} />
                </div>
            </Drawer>


            <br></br>
            <h4 class="sameline">Correlation Matrix
                <div class="smaller-font sameline">
                    &nbsp;&nbsp;<div class="bold-big"><Link className="bg-transparent" onClick={() => setHideAlgos(!hideAlgos)}>{hideAlgos ? "Add Algos" : "Hide"}</Link></div>
                    &nbsp;&nbsp;&nbsp;Warning Percent:
                    <Dropdown width="150" onSelect={(e) => setWarningPercent(Number(e / 100))}>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            {warningPercent * 100}%
                        </Dropdown.Toggle>

                        <Dropdown.Menu  >
                            <Dropdown.Item eventKey={40} value={40}>40%</Dropdown.Item>
                            <Dropdown.Item eventKey={50} value={50}>50%</Dropdown.Item>
                            <Dropdown.Item eventKey={60} value={60}>60%</Dropdown.Item>
                            <Dropdown.Item eventKey={70} value={70}>70%</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </h4>

            <div class="smallest-text">
                <RichTable data={matrix.tableData} className="table-striped table-hover table-condensed fixed" mappers={matrix.columns} />
            </div>
            <br></br>

            <h4>Aggregation</h4>
            {renderCorrelationStats()}
            {renderCorrelationChart()}
            {renderRealizedGainChart()}

            <br></br>
            <h4>Monthly Results</h4>
            <center>
                <Link className="bg-transparent" onClick={() => setMonthlyStat('winPercentage')}>Win Rate</Link>
                &nbsp;&nbsp;<Link className="bg-transparent" onClick={() => setMonthlyStat('count')}>Count</Link>
                &nbsp;&nbsp;<Link className="bg-transparent" onClick={() => setMonthlyStat('netProfit')}>Net Profit</Link>
                &nbsp;&nbsp;<Link className="bg-transparent" onClick={() => setMonthlyStat('avgProfit')}>Avg P/L per Trade</Link>
                &nbsp;&nbsp;<Link className="bg-transparent" onClick={() => setMonthlyStat('avgNetProfit')}>Avg Net P/L per Trade</Link>
            </center>
            <RichTable data={getMonthlyData()} className="table-striped table-hover table-condensed fixed" mappers={[
                { title: 'Year', field: 'year' },
                { title: 'Jan', field: 'jan', formatter: getMonthlyFormat() },
                { title: 'Feb', field: 'feb', formatter: getMonthlyFormat() },
                { title: 'Mar', field: 'mar', formatter: getMonthlyFormat() },
                { title: 'Apr', field: 'apr', formatter: getMonthlyFormat() },
                { title: 'May', field: 'may', formatter: getMonthlyFormat() },
                { title: 'Jun', field: 'jun', formatter: getMonthlyFormat() },
                { title: 'Jul', field: 'jul', formatter: getMonthlyFormat() },
                { title: 'Aug', field: 'aug', formatter: getMonthlyFormat() },
                { title: 'Sep', field: 'sep', formatter: getMonthlyFormat() },
                { title: 'Oct', field: 'oct', formatter: getMonthlyFormat() },
                { title: 'Nov', field: 'nov', formatter: getMonthlyFormat() },
                { title: 'Dec', field: 'dec', formatter: getMonthlyFormat() }
            ]} />
            <br></br>

            <h4>Algo Charts</h4>
            <Dropdown width="50" onSelect={(e) => setColumns(Number(e))}>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    Columns: {columns}
                </Dropdown.Toggle>

                <Dropdown.Menu  >
                    <Dropdown.Item as='columns' eventKey={1} value={1}>1</Dropdown.Item>
                    <Dropdown.Item as='columns' eventKey={2} value={2}>2</Dropdown.Item>
                    <Dropdown.Item as='columns' eventKey={3} value={3}>3</Dropdown.Item>
                    <Dropdown.Item as='columns' eventKey={4} value={3}>4</Dropdown.Item>
                    <Dropdown.Item as='columns' eventKey={5} value={3}>5</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            Show Drawdown<input type="checkbox" onChange={(e) => setShowDrawdown(!showDrawdown)}></input>
            {renderCharts()}

            <br></br>
            <h4>Backtest Trades</h4>
            <div style={{ width: '100%' }}>
                <RichTable data={getBacktestTrades()} className="table-striped table-hover table-condensed fixed" pageSize={10} mappers={[
                    { title: 'Purchase Date', field: 'purchase_date', sorters: true, formatter: tableHelpers.dateFormmatter },
                    { title: 'Sell Date', field: 'sell_date', sorters: true, formatter: tableHelpers.dateFormmatter },
                    { title: 'Symbol', field: 'symbol', sorters: true },
                    { title: 'Algo', field: 'display_name', sorters: true },
                    { title: 'P/L', field: 'profit', sorters: true, formatter: tableHelpers.percentFormatter },
                    { title: 'Net', field: 'netProfit', sorters: true, formatter: tableHelpers.percentFormatter }
                ]} />
            </div>
        </>
    );
}

export default AlgoCorrelation;