import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom'
import CandleStickChart from './CandlestickChart.js';
import tableHelpers from '../helpers/TableHelpers.js'
import RichTable from '../components/richTable/RichTable.jsx'
import { createDateSorter } from '../components/richTable/sorters.js'
import Indicators from '../positions/Indicators.js';

function Stock(props) {

    let { symbol } = useParams();
    if (symbol === undefined)
        symbol = props.symbol;

    const [trades, setTrades] = useState([]);
    const [activeTrades, setActiveTrades] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async function () {
            let date = new Date();
            let path = `/api/completedTrades/?planId=` + props.planId + "&startDate=2023-11-1&endDate=" + date.toLocaleDateString();
            let text = await (await fetch(path)).json();
            if (Array.isArray(text)) {
                text = text.filter(t => t.trade_symbol === symbol);
                setTrades(text);
            }

            let activePath = `/api/activeTrades/?planId=` + props.planId;
            let res = await (await fetch(activePath)).json();
            if (Array.isArray(res)) {
                res = res.filter(t => t.trade_symbol === symbol);
                setActiveTrades(res);
            }

            setLoading(false);
        })();
    }, [props.planId, symbol]);

    function getTrades() {
        let result = trades.map(t => {
            let profit = (t.sell_price - t.purchase_price) / t.purchase_price * 100;
            return {
                ...t,
                profit: profit,
                realizedProfit: profit * t.allocation / 100
            }
        });

        activeTrades.forEach(t => {
            result.push({
                ...t,
                purchase_price: t.close,
                profit: null,
                realizedProfit: null
            })
        })
        return result;
    }

    function getActiveTrades() {
        return activeTrades.map(t => {
            return {
                ...t,
                purchase_price: t.close,
                profit: null,
                realizedProfit: null
            }
        });
    }

    function getCompletedTrades() {
        return trades.map(t => {
            let profit = (t.sell_price - t.purchase_price) / t.purchase_price * 100;
            return {
                ...t,
                profit: profit,
                realizedProfit: profit * t.allocation / 100
            }
        });
    }

    function algoLinkFormatter(cell, row) {

        let algoid = row.idalgos;
        return (
            <Link className="bg-transparent" to={"/algo/" + algoid} >{cell}</Link>
        )
    }

    return (
        <>
            <h2>{symbol}</h2>
            <br></br>
            <h4>Indicators</h4>
            <Indicators hideSymbol={true} symbols={[symbol]} mobileView={props.mobileView} />

            <br></br>
            <h4>Active Trades</h4>
            <RichTable data={getActiveTrades()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Purch Date', field: 'purchase_date', formatter: tableHelpers.dateFormmatter, sorters: createDateSorter('purchase_date'), isDefaultSort: true },
                { title: 'Purch Price', field: 'purchase_price', formatter: tableHelpers.moneyFormatter, sorters: createDateSorter('purchase_date'), isDefaultSort: true },
                { title: 'Algo', field: 'display_name', formatter: algoLinkFormatter, sorters: true },
                { title: 'Allocation', field: 'allocation', formatter: tableHelpers.percentFormatter, sorters: true },
            ]} />

            <br></br>
            <h4>Average Price</h4>
            <table width="100%">
                <tr>
                    <td>
                        {
                            loading === true ?
                                <>Loading chart...</>
                                :
                                <CandleStickChart showAveragePrice={true} symbol={symbol} trades={getTrades()} width={window.innerWidth * .8} height={window.innerHeight * .8} />

                        }
                    </td>
                    <td>
                    </td>
                </tr>
            </table>

            <br></br>
            <h4>Completed Trades</h4>
            <RichTable data={getCompletedTrades()} className="table-striped table-hover table-condensed" pageSize={10} mappers={[
                { title: 'Purch Date', field: 'purchase_date', formatter: tableHelpers.dateFormmatter, hidden: props.mobileView, sorters: createDateSorter('purchase_date'), isDefaultSort: true },
                { title: 'Sell Date', field: 'sell_date', formatter: tableHelpers.dateFormmatter, sorters: createDateSorter('sell_date'), isDefaultSort: true },
                { title: 'Algo', field: 'display_name', formatter: algoLinkFormatter, sorters: true },
                { title: 'P/L', field: 'profit', formatter: tableHelpers.percentFormatter, sorters: true },
                { title: 'Allocation', field: 'allocation', formatter: tableHelpers.percentFormatter, sorters: true, hidden: props.mobileView || props.planId === undefined },
                { title: 'Net P/L', field: 'realizedProfit', formatter: tableHelpers.percentFormatter, sorters: true, hidden: props.mobileView || props.planId === undefined }
            ]} />
        </>
    );
}

export default Stock;