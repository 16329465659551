import React, { useState, useEffect } from 'react';
import RichTable from '../components/richTable/RichTable.jsx'
import Tooltip from '../components/Tooltip.js';
import TwoStepConfirmationButton from '../components/TwoStepConfirmButton.js';
import ActionResult from '../components/ActionResult.js';
import Dropdown from 'react-bootstrap/Dropdown';

function MarketDataStatus(props) {

    const [historicalData, setHistoricalData] = useState([]);
    const [quoteData, setQuoteData] = useState({});
    const [quoteHistory, setQuoteHistory] = useState([]);
    const [quoteResult, setQuoteResult] = useState(null);
    const [updatedQuote, setUpdatedQuote] = useState({})
    const [vendorSelected, setVendorSelected] = useState(null);
    const [updates, setUpdates] = useState(0);
    const [result, setResult] = useState(null);

    useEffect(() => {
        (async function () {
            let data = await (await fetch(`/api/quoteHistoryAudit`)).json();
            setHistoricalData(data);

            let vendor = await (await fetch(`/api/activeQuoteVendor`)).json();
            if (vendor && vendor.vendor) {
                console.log("active quote vendor", vendor.vendor);
                setVendorSelected(vendor.vendor);
            }
        })();
    }, [updates]);

    function getHistoricalData() {
        return historicalData.map(data => {
            return {
                symbol: data.symbol,
                count: data.count
            }
        });
    }

    async function getQuoteData(symbol) {
        setQuoteData({})
        const url = "/api/quote?symbols=" + symbol;
        const d = await (await fetch(url)).json();
        setQuoteData(d);
    }

    async function getQuoteHistory(symbol) {
        setQuoteHistory([]);
        let date = new Date();
        const url = "/api/quoteHistory?symbol=" + symbol + "&startDate=" + date.toLocaleDateString() + "&endDate=" + date.toLocaleDateString();
        const h = await (await fetch(url)).json();
        setQuoteHistory(h);
    }

    function symbolFormatter(cell, row) {
        return (
            <>
                <Tooltip textClass="tooltip-link" onOpen={() => getQuoteData(row.symbol)} text={cell} json={quoteData} position="right center" width="500px" />
            </>
        )
    }

    async function handleUpdateQuote(symbol) {

        console.log("updatedQuote", updatedQuote);

        const url = "/api/quoteUpdate?symbol=" + symbol + "&close=" + updatedQuote.close;
        const d = await (await fetch(url, { method: 'POST' })).json();
        if (d && d.success) {
            setQuoteResult("Updated Quote");
        } else {
            setQuoteResult("Error updating quote");
        }

        getQuoteHistory(symbol);
    }

    async function handleAddQuote(symbol) {
        const url = "/api/quoteInsertToday?symbol=" + symbol + "&close=" + updatedQuote.close;
        const d = await (await fetch(url, { method: 'POST' })).json();
        if (d && d.success) {
            setQuoteResult("Added Quote");
        } else {
            setQuoteResult("Error updating quote");
        }
        getQuoteHistory(symbol);
        setUpdatedQuote({});
    }

    function onQuoteHistoryOpen(symbol) {
        setQuoteResult(null);
        setUpdatedQuote({});
        getQuoteHistory(symbol)
    }

    function shouldAllowEdits() {
        return props.role >= 3;
    }

    function historicalCountFormatter(cell, row) {

        return (
            <Tooltip textClass="tooltip-link" onOpen={() => onQuoteHistoryOpen(row.symbol)} text={cell} position="right center" width="500px" >
                {quoteHistory && quoteHistory.length ?
                    <>
                        Date: {quoteHistory[0].date}<br></br>
                        Close: <input type="text" defaultValue={quoteHistory[0].close} onChange={(e) => setUpdatedQuote({ ...updatedQuote, close: e.target.value })} /><br></br>
                        Open: {quoteHistory[0].open}<br></br>
                        High: {quoteHistory[0].high}<br></br>
                        Low: {quoteHistory[0].low}<br></br>
                        <button class="btn btn-primary" onClick={(e) => handleUpdateQuote(row.symbol)}>Save</button>
                    </>
                    :
                    <>
                        Close: <input type="text" defaultValue={0} onChange={(e) => setUpdatedQuote({ ...updatedQuote, close: e.target.value })} /><br></br>
                        <button class="btn btn-primary" onClick={(e) => handleAddQuote(row.symbol)}>Add Record</button>
                    </>
                }
                <br></br>
                <>{quoteResult}
                </>
            </Tooltip>
        );
    }

    async function handleVendorChange(vendor) {
        let url = `/api/quoteVendor?userId=${props.userId}&vendor=${vendor}`;
        let result = await (await fetch(url, { method: 'post' })).json();
        setResult(result);
        setUpdates(updates + 1);
    }

    async function handleUpdateQuoteCache() {
        let url = `/api/updateQuoteCache`;
        await (await fetch(url, { method: 'post' })).json();
    }

    return (
        <div>

            {shouldAllowEdits() ?
                <div className="sameline" style={{ padding: 10, borderWidth: 1, borderStyle: 'solid', textAlign: 'center' }}>

                    Current Vendor:
                    <Dropdown width="150" onSelect={(e) => setVendorSelected(e)}>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            {vendorSelected}
                        </Dropdown.Toggle>

                        <Dropdown.Menu  >
                            <Dropdown.Item as='daily' eventKey={"12Data"} value={"12Data"}>12Data</Dropdown.Item>
                            <Dropdown.Item as='weekly' eventKey={"schwab"} value={"schwab"}>Schwab</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <TwoStepConfirmationButton id={vendorSelected} text="Change" onConfirmed={handleVendorChange} />

                    <br></br>
                    <br></br>

                    <TwoStepConfirmationButton id={0} text="Update Quote Cache" onConfirmed={handleUpdateQuoteCache} />
                </div>
                : null}

            <br></br>
            <RichTable data={getHistoricalData()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Symbol', field: 'symbol', formatter: symbolFormatter },
                { title: 'Historical Bars', field: 'count', formatter: historicalCountFormatter },
            ]} />

            <ActionResult result={result} />

        </div>
    );
}

export default MarketDataStatus;