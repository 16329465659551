import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import RichTable from '../components/richTable/RichTable.jsx'
import tableHelpers from '../helpers/TableHelpers.js'
import Slider from '@mui/material/Slider';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Tooltip from '../components/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';

function AlgoStats(props) {
    const [data, setData] = useState([]);
    const [years, setYears] = useState(0);
    const [updates, setUpdates] = useState(1);
    const [filtered, setFiltered] = useState(!props.showAll)
    const [recommendations, setRecommendations] = useState(null)
    const [searchSymbol, setSearchSymbol] = useState('');
    const [searchAlgoType, setSearchAlgoType] = useState('Mean Reversion');
    const [searchMaxCorrelation, setSearchMaxCorrelation] = useState('60');

    const [winRateFilter, setWinRateFilter] = React.useState([0, 100]);
    const [durationFilter, setDurationFilter] = React.useState([0, 100]);
    const [avgProfitFilter, setAvgProfitFilter] = React.useState([-100, 100]);
    const [tradeCountFilter, setTradeCountFilter] = React.useState([0, 100]);
    const [robustnessFilter, setRobustnessFilter] = React.useState([0, 10]);
    const [symbolFilter, setSymbolFilter] = React.useState(null);
    const [algoFilter, setAlgoFilter] = React.useState(null);
    const [algoTypeFilter, setAlgoTypeFilter] = React.useState(null);

    useEffect(() => {
        (function () {
            fetch(`/api/algoStats?planId=` + props.planId + "&all=" + (filtered ? "0" : "1"))
                .then(res => res.json())
                .then(text => {
                    console.log(text);
                    setData(text);
                });
        })();
    }, [filtered, props.planId, updates]);

    function getSummaryArray() {
        let stats = { count: 0, winners: 0, losers: 0, winningPct: 0.0, netProfit: 0.0, avgProfit: 0.0, avgWin: 0.0, avgLoss: 0.0, maxWin: 0.0, maxLoss: 0.0, totalProfit: 0.0, kelly: 0.0, avgRobustness: 0, totalRobustness: 0, algoCount: 0, avgMaxDrawdown: 0, totalMaxDrawdown: 0 }

        let filteredAlgos = getStats();
        filteredAlgos.forEach(algo => {
            const netProfit = (algo.avg_profit * algo.count);
            const winners = Math.round((algo.winning_percentage / 100 * algo.count));
            const losers = algo.count - winners;
            const winnersProfit = algo.avg_win * winners;
            const losersProfit = algo.avg_loss * losers;
            const totalWinners = stats.winners + winners;
            const totalLosers = stats.losers + losers;
            if (totalWinners) stats.avgWin = ((stats.avgWin * stats.winners) + (winnersProfit)) / totalWinners;
            if (totalLosers) stats.avgLoss = ((stats.avgLoss * stats.losers) + (losersProfit)) / totalLosers;

            stats.winners += winners;
            stats.losers += losers;
            stats.netProfit += netProfit;
            stats.count += algo.count;
            console.log(algo.algo_robustness_overall);
            stats.totalRobustness += algo.robustness;
            stats.totalMaxDrawdown += algo.max_drawdown;
            ++stats.algoCount;
        });

        stats.winningPct = (stats.winners / (stats.count) * 100);
        stats.avgProfit = stats.netProfit / stats.count;
        const probabilityOfWinning = (stats.winningPct / 100);
        const winLossRatio = Math.abs(stats.avgWin / stats.avgLoss);
        stats.kelly = (probabilityOfWinning - ((1 - probabilityOfWinning) / winLossRatio)) * 100
        stats.avgRobustness = stats.totalRobustness / stats.algoCount;
        stats.avgMaxDrawdown = stats.totalMaxDrawdown / stats.algoCount;
        return [stats];
    }

    function getDataRanges() {
        let ranges = {
            duration: [undefined, undefined],
            avgProfit: [undefined, undefined],
            tradeCount: [undefined, undefined],
            robustness: [0, 10]
        }

        data.filter(s => s.years === years)
            .forEach(a => {
                if (ranges.duration[0] === undefined || a.avg_duration < ranges.duration[0]) {
                    ranges.duration[0] = a.avg_duration;
                }
                if (ranges.duration[1] === undefined || a.avg_duration > ranges.duration[1]) {
                    ranges.duration[1] = a.avg_duration;
                }
                if (ranges.avgProfit[0] === undefined || a.avg_profit < ranges.avgProfit[0]) {
                    ranges.avgProfit[0] = a.avg_profit;
                }
                if (ranges.avgProfit[1] === undefined || a.avg_profit > ranges.avgProfit[1]) {
                    ranges.avgProfit[1] = a.avg_profit;
                }
                if (ranges.tradeCount[0] === undefined || a.count < ranges.tradeCount[0]) {
                    ranges.tradeCount[0] = a.count;
                }
                if (ranges.tradeCount[1] === undefined || a.count > ranges.tradeCount[1]) {
                    ranges.tradeCount[1] = a.count;
                }
            })

        return ranges;
    }

    function getStats() {
        return data
            .filter(a => a.winning_percentage >= winRateFilter[0] && a.winning_percentage <= winRateFilter[1])
            .filter(a => a.avg_duration >= durationFilter[0] && a.avg_duration <= durationFilter[1])
            .filter(a => a.avg_profit >= avgProfitFilter[0] && a.avg_profit <= avgProfitFilter[1])
            .filter(a => a.count >= tradeCountFilter[0] && a.count <= tradeCountFilter[1])
            .filter(a => a.algo_robustness_overall >= robustnessFilter[0] && a.algo_robustness_overall <= robustnessFilter[1])
            .filter(a => symbolFilter === null || a.trade_symbol === symbolFilter)
            .filter(a => algoFilter === null || a.display_name === algoFilter)
            .filter(a => algoTypeFilter === null || a.algo_type === algoTypeFilter)
            .filter(s => s.years === years).map(stat => {

                const probabilityOfWinning = (stat.winning_percentage / 100);
                const winLossRatio = stat.avg_loss === 0 ? stat.avg_win : Math.abs(stat.avg_win / stat.avg_loss);
                const kelly = probabilityOfWinning === 0 ? 0 : (probabilityOfWinning - ((1 - probabilityOfWinning) / winLossRatio)) * 100

                return {
                    display_name: stat.display_name,
                    entry_speed: stat.entry_speed,
                    winning_percentage: stat.winning_percentage,
                    avg_profit: stat.avg_profit,
                    avg_win: stat.avg_win,
                    avg_loss: stat.avg_loss,
                    win_loss_ratio: Math.abs(stat.avg_win / stat.avg_loss),
                    max_win_loss_ratio: Math.abs(stat.max_win / stat.max_loss),
                    avg_duration: stat.avg_duration,
                    max_win: stat.max_win,
                    max_loss: stat.max_loss,
                    trade_symbol: stat.trade_symbol,
                    count: stat.count,
                    algo_type: stat.algo_type,
                    algoId: stat.algoId,
                    percentage: stat.percentage,
                    kelly: kelly,
                    net_profit: stat.avg_profit * stat.count,
                    annual_profit: stat.years > 0 ? (stat.avg_profit * stat.count) / stat.years : 0,
                    robustness: stat.algo_robustness_overall,
                    max_correlation: stat.max_correlation,
                    max_drawdown: stat.max_drawdown
                }
            })
    }

    function algoLinkFormatter(cell, row) {
        let algoid = row.algoId;
        return (
            <Link className="bg-transparent" to={"/algo/" + algoid}>{cell}</Link>
        )
    }

    async function onActionClicked(algoId) {
        if (props.onAlgoAction) {
            await props.onAlgoAction(algoId);
            setUpdates(updates + 1);
        }
    }

    function selectAlgoFormatter(cell, row) {
        return <Link onClick={() => onActionClicked(row.algoId)}><LibraryAddIcon /></Link>
    }

    function getRecommendations() {
        if (recommendations)
            return recommendations;
        else
            return [];
    }

    async function onLoadRecommendations() {
        setRecommendations(null);
        const url = `/api/planAlgoWizard/?planId=` + props.planId + "&symbol=" + searchSymbol + "&algoType=" + searchAlgoType + "&maxCorrelation=" + searchMaxCorrelation;
        let recs = await (await fetch(url)).json();
        setRecommendations(recs);
    }

    function getUniqueSymbols() {
        return [...new Set(data.map(trade => trade.trade_symbol))].sort();
    }
    function getUniqueAlgos() {
        return [...new Set(data.map(trade => trade.display_name))].sort();
    }
    function getUniqueAlgoTypes() {
        return [...new Set(data.map(trade => trade.algo_type))].sort();
    }

    let dataRanges = getDataRanges();

    return (
        <>
            <hr></hr>
            <center>
                <table width={500}>
                    <tr className='bg-transparent'>
                        <td width="100">Win Rate</td>
                        <td>
                            <Slider onChange={(e, newValue) => setWinRateFilter(newValue)} min={0} max={100} value={winRateFilter} valueLabelDisplay="auto" shiftStep={30} step={5} marks />
                        </td>
                    </tr>
                    <tr className='bg-transparent'>
                        <td>Avg Duration</td>
                        <td>
                            <Slider onChange={(e, newValue) => setDurationFilter(newValue)} min={dataRanges.duration[0]} max={dataRanges.duration[1]} value={durationFilter} valueLabelDisplay="auto" shiftStep={5} step={1} marks />
                        </td>
                    </tr>

                    <tr className='bg-transparent'>
                        <td>Avg Profit</td>
                        <td>
                            <Slider onChange={(e, newValue) => setAvgProfitFilter(newValue)} min={dataRanges.avgProfit[0]} max={dataRanges.avgProfit[1]} value={avgProfitFilter} valueLabelDisplay="auto" shiftStep={5} step={.1} marks />
                        </td>
                    </tr>
                    <tr className='bg-transparent'>
                        <td>Robustness</td>
                        <td>
                            <Slider onChange={(e, newValue) => setRobustnessFilter(newValue)} min={dataRanges.robustness[0]} max={dataRanges.robustness[1]} value={robustnessFilter} valueLabelDisplay="auto" shiftStep={5} step={1} marks />
                        </td>
                    </tr>
                    <tr className='bg-transparent'>
                        <td>Trade Count</td>
                        <td>
                            <Slider onChange={(e, newValue) => setTradeCountFilter(newValue)} min={dataRanges.tradeCount[0]} max={dataRanges.tradeCount[1]} value={tradeCountFilter} valueLabelDisplay="auto" shiftStep={5} step={1} marks />
                        </td>
                    </tr>

                    <tr className='bg-transparent'>
                        <td>Symbol</td>
                        <td>
                            <Dropdown onSelect={(e) => setSymbolFilter(e)}>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                    {symbolFilter}
                                </Dropdown.Toggle>
                                <Dropdown.Menu  >
                                    <Dropdown.Item as='group' eventKey={null} value={null}>None</Dropdown.Item>
                                    {getUniqueSymbols().map(sym => {
                                        return (
                                            <Dropdown.Item as='sym' eventKey={sym} value={sym}>{sym}</Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>

                    <tr className='bg-transparent'>
                        <td>Algo</td>
                        <td>
                            <Dropdown onSelect={(e) => setAlgoFilter(e)}>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                    {algoFilter}
                                </Dropdown.Toggle>
                                <Dropdown.Menu  >
                                    <Dropdown.Item as='group' eventKey={null} value={null}>None</Dropdown.Item>
                                    {getUniqueAlgos().map(a => {
                                        return (
                                            <Dropdown.Item as='algo' eventKey={a} value={a}>{a}</Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>

                    <tr className='bg-transparent'>
                        <td>Algo Type</td>
                        <td>
                            <Dropdown onSelect={(e) => setAlgoTypeFilter(e)}>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                    {algoTypeFilter}
                                </Dropdown.Toggle>
                                <Dropdown.Menu  >
                                    <Dropdown.Item as='group' eventKey={null} value={null}>None</Dropdown.Item>
                                    {getUniqueAlgoTypes().map(a => {
                                        return (
                                            <Dropdown.Item as='algoType' eventKey={a} value={a}>{a}</Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>
                </table>
            </center >
            <hr></hr>
            <br></br>

            <center>
                <Link id="one" className="menu-item" onClick={e => { setYears(0) }}>Live Trades</Link>&nbsp;&nbsp;
                <Link id="one" className="menu-item" onClick={e => { setYears(1) }}>1 Years</Link>&nbsp;&nbsp;
                <Link id="one" className="menu-item" onClick={e => { setYears(2) }}>2 Years</Link>&nbsp;&nbsp;
                <Link id="one" className="menu-item" onClick={e => { setYears(3) }}>3 Years</Link>&nbsp;&nbsp;
                <Link id="one" className="menu-item" onClick={e => { setYears(5) }}>5 Years</Link>&nbsp;&nbsp;
                <Link id="one" className="menu-item" onClick={e => { setYears(10) }}>10 Years</Link>&nbsp;&nbsp;
                <br></br>
                <input type="checkbox" checked={filtered} onChange={() => setFiltered(!filtered)} /> Show Plan Only
            </center>

            <center>
                <h3>{years === 0 ? "Live Trades" : (years + " Years")}</h3>
            </center >
            <RichTable data={getSummaryArray()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Algos', field: 'algoCount', formatter: tableHelpers.intFormatter, hidden: props.mobileView },
                { title: 'Trades', field: 'count', formatter: tableHelpers.intFormatter, hidden: props.mobileView },
                { title: 'Win %', field: 'winningPct', formatter: tableHelpers.percentFormatter },
                { title: 'Avg Profit', field: 'avgProfit', formatter: tableHelpers.percentFormatter },
                { title: 'Avg Win', field: 'avgWin', formatter: tableHelpers.percentFormatter },
                { title: 'Avg Loss', field: 'avgLoss', formatter: tableHelpers.percentFormatter },
                { title: 'Robustness', field: 'avgRobustness', formatter: tableHelpers.decimalFormatter, hidden: props.mobileView },
                { title: 'Avg Max DD', field: 'avgMaxDrawdown', formatter: tableHelpers.percentFormatter, hidden: props.mobileView }
            ]} />

            {props.onAlgoAction ?
                <Tooltip width={'500px'} text="Get a recommendation 🎲">
                    <>
                        Symbol<input type="text" value={searchSymbol} onChange={(e) => setSearchSymbol(e.target.value)} />
                        <br></br>
                        <div class="sameline">
                            Algo Type:
                            <Dropdown width="150" onSelect={(e) => setSearchAlgoType(e)}>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                    {searchAlgoType}
                                </Dropdown.Toggle>

                                <Dropdown.Menu  >
                                    <Dropdown.Item as='a' eventKey={"Mean Reversion"} value={"Mean Reversion"}>Mean Reversion</Dropdown.Item>
                                    <Dropdown.Item as='g' eventKey={"Trend"} value={"Trend"}>Trend</Dropdown.Item>
                                    <Dropdown.Item as='b' eventKey={"Candlestick"} value={"Candlestick"}>Candlestick</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            Max Correlation:
                            <Dropdown width="150" onSelect={(e) => setSearchMaxCorrelation(e)}>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                    {searchMaxCorrelation}%
                                </Dropdown.Toggle>

                                <Dropdown.Menu  >
                                    <Dropdown.Item as='a' eventKey={20} value={20}>20%</Dropdown.Item>
                                    <Dropdown.Item as='a' eventKey={30} value={30}>30%</Dropdown.Item>
                                    <Dropdown.Item as='a' eventKey={40} value={40}>40%</Dropdown.Item>
                                    <Dropdown.Item as='a' eventKey={50} value={50}>50%</Dropdown.Item>
                                    <Dropdown.Item as='a' eventKey={60} value={60}>60%</Dropdown.Item>
                                    <Dropdown.Item as='a' eventKey={70} value={70}>70%</Dropdown.Item>
                                    <Dropdown.Item as='a' eventKey={100} value={100}>100%</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <br></br>
                        <Link className="bold-big" onClick={() => onLoadRecommendations()}>🎲</Link>
                        <RichTable data={getRecommendations()} className="table-striped table-hover table-condensed" mappers={[
                            { title: 'Algo', field: 'primary_display_name' },
                            { title: 'Action', field: 'algoId', formatter: selectAlgoFormatter },
                            { title: 'Win Rate', field: 'winning_percentage', formatter: tableHelpers.percentFormatterNoDecimals },
                            { title: 'Score', field: 'theScore', formatter: tableHelpers.intFormatter },
                            { title: 'Max Correlation', field: 'maxCorrelation', formatter: tableHelpers.percentFormatterNoDecimals },
                        ]} />

                    </>
                </Tooltip>
                :
                null}


            <br></br>
            <br></br>
            <RichTable data={getStats()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Algo', field: 'display_name', formatter: algoLinkFormatter, sorters: true, filter: 'text' },
                { title: 'Symbol', field: 'trade_symbol', sorters: true, filter: 'text' },
                { title: 'Select', field: 'idalgos', hidden: !props.onAlgoAction, formatter: selectAlgoFormatter },
                { title: 'Type', field: 'algo_type', hidden: props.mobileView, sorters: true, filter: 'choice' },
                { title: 'Speed', field: 'entry_speed', hidden: props.mobileView, sorters: true, filter: 'choice' },
                { title: 'Allocation', field: 'percentage', formatter: tableHelpers.percentFormatter, hidden: props.mobileView, sorters: true },
                { title: 'Robustness', field: 'robustness', formatter: tableHelpers.decimalFormatter, hidden: props.mobileView, sorters: true },
                { title: 'Win %', field: 'winning_percentage', formatter: tableHelpers.percentFormatterNoDecimals, sorters: true },
                { title: 'Trades', field: 'count', formatter: tableHelpers.intFormatter, hidden: props.mobileView, sorters: true },
                { title: 'Avg P/L', field: 'avg_profit', formatter: tableHelpers.percentChangeFormatterOneDecimal, sorters: true },
                { title: 'Avg R:R', field: 'win_loss_ratio', formatter: tableHelpers.decimalFormatter, hidden: props.mobileView, sorters: true },
                { title: 'Max R:R', field: 'max_win_loss_ratio', formatter: tableHelpers.decimalFormatter, hidden: props.mobileView, sorters: true },
                { title: 'Duration', field: 'avg_duration', formatter: tableHelpers.intFormatter, hidden: props.mobileView, sorters: true },
                { title: 'Total Profit', field: 'net_profit', formatter: tableHelpers.percentFormatterNoDecimals, hidden: props.mobileView, sorters: true },
                { title: 'Max Correlation', field: 'max_correlation', formatter: tableHelpers.percentFormatterNoDecimals, hidden: props.mobileView, sorters: true },
                { title: 'Max Drawdown', field: 'max_drawdown', formatter: tableHelpers.percentFormatterNoDecimals, hidden: props.mobileView, sorters: true },
            ]} />

            <br></br>
            <br></br>
        </>
    );
}

export default AlgoStats;