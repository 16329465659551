import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes, Route
} from 'react-router-dom'

import { isMobile } from 'react-device-detect'
import { getValueOrDefault } from './helpers/localStorageHelper'
import { clarity } from 'react-microsoft-clarity'

import AuthLayout from './site/AuthLayout.jsx'
import MainLayout from './site/MainLayout.js'

// Imports for Login Layout
import Login from './auth/login'
import Logout from './auth/logout'
import PostLogin from './auth/postLogin'

// Imports for Main Layout
import Home from './Home'
import Plan from './positions/Plan.js'
import Positions from './positions/Positions.js'
import Signals from './manage/Signals.js'
import Alerts from './alerts/Alerts.js'
import ConfigureAlert from './alerts/ConfigureAlert.js'
import AlertSandbox from './alerts/AlertSandbox'
import Subscriptions from './Subscriptions'
import Plans from './Plans'
import AllocationChanges from './trades/AllocationChanges.js'
import PlanStatusHistory from './plan/PlanStatusHistory.js'
import ManageAlgos from './plan/ManageAlgos.js'
import ManagePlans from './manage/ManagePlans.js'
import BacktestUpload from './manage/BacktestUpload.js'
import RunBacktest from './plan/RunBacktest.js'
import LastBacktest from './plan/LastBacktest.js'
import ManageUsers from './manage/ManageUsers.js'
import CreateAlgo from './manage/CreateAlgo.js'
import ActiveTrades from './trades/ActiveTrades'
import Trades from './trades/Trades.js'
import CompletedTrades from './trades/CompletedTrades'
import DetailedPerformance from './plan/DetailedPerformance.js'
import Algo from './algos/Algo.js'
import AlgoStats from './algos/AlgoStats.js'
import AlgoCorrelation from './algos/AlgoCorrelation.js'
import StressTest from './plan/StressTest.js'
import ManageSystem from './manage/ManageSystem.js'
import TradeWizard from './trades/TradeWizard.js'
import Forecast from './plan/Forecast.js'
import NewTrades from './trades/NewTrades.js'
import PlanRecap from './plan/PlanRecap.js'
import Replay from './plan/Replay.js'
import TradeCorrectionEmails from './manage/TradeCorrectionEmails.js'
import AddAccount from './accounts/AddAccount.js'
import AccountsSummary from './accounts/AccountsSummary.js'
import Help from './help/Help.js'
import Stats from './admin/Stats'
import TermsAndConditions from './legal/termsAndConditions'
import PrivacyPolicy from './legal/privacyPolicy'
import UserAgreement from './legal/user'
import PersonalInfo from './legal/personalInfo'
import Stock from './stock/Stock.js'
import AlgoPerformanceComparison from './algos/AlgoPerformanceComparison.js'
import AlgoPerformance from './algos/AlgoPerformance.js'
import PlanAnalysis from './plan/PlanAnalysis.js'
import UpdatePlan from './plan/UpdatePlan.js'
import StickySettings from './helpers/StickySettings.js';
import Authorized from './trades/Authorized.js'

function App() {
  const [accountValue, setAccountValue] = useState(getValueOrDefault('accountValue'))
  const [activePlan, setActivePlan] = useState(getValueOrDefault('activePlan'));
  const [activePlanName, setActivePlanName] = useState(getValueOrDefault('activePlanName'))
  const [userId, setUserId] = useState(Number(getValueOrDefault('userId')))
  //const [cohort, setCohort] = useState(null)
  const [role, setRole] = useState(Number(getValueOrDefault('role')))
  const [algoSelected, setAlgoSelected] = useState(0);
  const [alertSelected, setAlertSelected] = useState(0);
  const [mobileView, setMobileView] = useState(isMobile)
  const [subscriptions, setSubscriptions] = useState([])
  const [accounts, setAccounts] = useState([])
  const [activeAccountName, setActiveAccountName] = useState(getValueOrDefault('activeAccountName'))
  const [activeAccountId, setActiveAccountId] = useState(getValueOrDefault('activeAccountId'))
  const [activeAccountIsOptions, setActiveAccountIsOptions] = useState(StickySettings.getBool("activeAccountIsOptions", false))
  const [activeAccountStartDate, setActiveAccountStartDate] = useState(getValueOrDefault('activeAccountStartDate'))
  const [updates, setUpdates] = useState(0);
  const [agreedToTerms, setAgreedToTerms] = useState(getValueOrDefault('agreedToTerms'))
  const [disableOnDate, setDisableOnDate] = useState(StickySettings.getString("disableOnDate", null));
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => { // TODO: There's a race condition here that sometimes preserves the old userId if you navigate to /login manually
    console.log("Loading page data")

    async function loadSubs() {
      const subResponse = await fetch(`/api/subscriptions?userId=` + userId)
      if (subResponse.ok) {
        const subscriptions = await subResponse.json()
        setSubscriptions(subscriptions)
        setSessionExpired(false);
      } else {
        console.error(`Failed to load subscriptions for user ${userId} - HTTP ${subResponse.status}`)
        setSessionExpired(true);
      }
    }

    async function loadAccts() {
      const response = await fetch(`/api/accounts?userId=` + userId)
      if (response.ok) {
        const accounts = await response.json()
        setAccounts(accounts)
        setSessionExpired(false);
      } else {
        // Do NOT redirect to /login in the App root component
        console.error(`Failed to load accounts for user ${userId} - HTTP ${response.status}`)
      }
    }

    loadSubs()
    loadAccts()

    console.log("userId", userId);

    if (userId <= 0)
      setSessionExpired(true);

    localStorage.setItem('userId', userId)
    localStorage.setItem('agreedToTerms', agreedToTerms)
    localStorage.setItem('role', role)
    localStorage.setItem('activePlan', activePlan)
    localStorage.setItem('activePlanName', activePlanName)
    localStorage.setItem('accountValue', accountValue)
    localStorage.setItem('activeAccountId', activeAccountId)
    localStorage.setItem('activeAccountName', activeAccountName)
    localStorage.setItem('activeAccountStartDate', activeAccountStartDate)
    if (disableOnDate)
      localStorage.setItem('disableOnDate', disableOnDate)
    else
      localStorage.removeItem('disableOnDate');

  }, [userId, role, activePlan, agreedToTerms, activePlanName, accountValue, updates, activeAccountId, activeAccountName, activeAccountStartDate, disableOnDate]);

  function onAccountValueUpdated(value) {
    console.log("Updating account value to " + value);
    localStorage.setItem('accountValue', value)
    setAccountValue(value)
    if (activeAccountId >= 0) {
      handleSaveAccountValue(activeAccountId, value);
    }
  }

  async function onUserDataChanged() {
    console.log("onUserDataChanged");
    const subResponse = await fetch(`/api/subscriptions?userId=` + userId)
    if (subResponse.ok) {
      const subscriptions = await subResponse.json()
      setSubscriptions(subscriptions)
    }

    const response = await fetch(`/api/accounts?userId=` + userId)
    if (response.ok) {
      const accounts = await response.json()
      setAccounts(accounts)

      if (activeAccountId >= 0) {
        const account = accounts.find(a => a.idaccounts === Number(activeAccountId));
        if (account) {
          console.log("Found active account, setting balance");
          setAccountValue(account.current_balance);
        } else {
          console.log("Could not find account to update balance", activeAccountId, accounts);
        }
      }
    }
  }

  async function handleSaveAccountValue(accountId, value) {
    console.log("handleSaveAccountValue", activeAccountId, accountValue);

    if (activeAccountId >= 0 && (accountValue >= 0 || parseFloat(accountValue) >= 0)) {
      const url = "/api/accountValue?userId=" + userId + "&accountId=" + accountId + "&balance=" + value;
      console.log(url);
      await fetch(url, { method: "post" })
      setUpdates(updates + 1);
    } else {
      console.log("activeAccountId", activeAccountId);
      console.log("accountValue", accountValue);
    }
  }

  function handlePlanSelected(planId, name) {
    console.log("App handlePlanSelected called with", planId)
    setActivePlan(planId);
    setActivePlanName(name);
  }

  function handleAlgoSelected(algoId) {
    console.log("App handleAlgoSelected called with", algoId)
    setAlgoSelected(algoId);
  }

  function handleTradingAlertSelected(alertId) {
    console.log("App handleTradingAlertSelected called with", alertId)
    setAlertSelected(alertId);
  }

  function handleAccountAdded() {
    setUpdates(updates + 1);
  }

  function handleLogin(userId, newRole, plans, userAgreementDate, cohort, disableOnDate) {
    console.log("handleLogin", userId, newRole, plans, userAgreementDate, cohort, disableOnDate);
    setUserId(Number(userId));
    setRole(Number(newRole));
    setAgreedToTerms(userAgreementDate !== null && userAgreementDate !== undefined ? 'true' : 'false')
    setDisableOnDate(disableOnDate);
    //setCohort(cohort);
    plans.forEach(p => {
      if (p.is_primary === 1) handlePlanSelected(p.planId, p.name);
    });
  }

  function handleShowHideClicked() {
    setMobileView(!mobileView);
  }

  function handleSubscriptionDropdownSelected(value, shouldUpdateAccount) {
    console.log(value);
    const subscription = subscriptions.find(s => s.planId === Number(value))
    handlePlanSelected(value, subscription.name);
    if (shouldUpdateAccount)
      handleAccountSelected(-1, false);
  }

  function onSubscriptionDropdownSelected(value) {
    setActiveAccountIsOptions(false);
    handleSubscriptionDropdownSelected(value, true);
  }

  function onAccountSelected(value) {
    handleAccountSelected(value, true);
  }

  function onAgreementSigned() {
    setAgreedToTerms('true');
    window.location.href = '/overview';
  }


  function handleAccountSelected(value, updateSubscription) {
    console.log("handleAccountSelected", value);
    if (parseInt(value) < 0) {
      setActiveAccountName("Select Account...");
      setActiveAccountId(-1);
      setActiveAccountStartDate(null);
      setActiveAccountIsOptions(false);
    } else {
      let accountId = parseInt(value);
      const account = accounts.find(a => a.idaccounts === accountId);
      if (account) {
        console.log("Account selected", account);
        setActiveAccountId(account.idaccounts);
        setActiveAccountName(account.name);
        setAccountValue(account.current_balance);
        setActiveAccountStartDate(new Date(account.create_date));
        setActiveAccountIsOptions(account.is_options === 1);
        StickySettings.setBool("activeAccountIsOptions", account.is_options);
        if (updateSubscription)
          handleSubscriptionDropdownSelected(account.planId);
      }
    }
  }

  if (process.env.NODE_ENV === 'production' && userId !== 1) {
    clarity.init('n071dllvg1');
    window.clarity("set", "Stockpiler user id", userId.toString())
  }

  return (
    <div className="App">
      <div id="outer-container">
        <Router>
          <Routes>
            {/* Login template routes to separate localstorage and cookies from main appliction */}
            <Route element={<AuthLayout />}>
              <Route path="/login" element={<Login onLogin={handleLogin} />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/postLogin" element={<PostLogin onLogin={handleLogin} />} />
            </Route>

            {/* Main template routes for application */}
            <Route element={<MainLayout userId={userId} activePlan={activePlan} role={role} activePlanName={activePlanName} onSubscriptionDropdownSelected={onSubscriptionDropdownSelected}
              subscriptions={subscriptions} agreedToTerms={agreedToTerms} onAgreementSigned={onAgreementSigned} mobileView={mobileView}
              handleShowHideClicked={handleShowHideClicked} onAccountSelected={onAccountSelected} activeAccountName={activeAccountName}
              accounts={accounts} accountValue={accountValue} onAccountValueUpdated={onAccountValueUpdated} isOptions={activeAccountIsOptions} isSessionExpired={sessionExpired} disableDate={disableOnDate} />}>
              <Route path="/" element={<Home accountId={activeAccountId} isOptions={activeAccountIsOptions} userId={userId} accountStartDate={activeAccountStartDate} planId={activePlan} accountValue={accountValue} mobileView={mobileView} onAccountsUpdated={onUserDataChanged} onAccountSelected={onAccountSelected} />} />
              <Route path="/authorized/:params?" element={<Authorized userId={userId} mobileView={mobileView} onAccountsUpdated={onUserDataChanged} />} />
              <Route path="/overview" element={<Home accountId={activeAccountId} isOptions={activeAccountIsOptions} userId={userId} accountStartDate={activeAccountStartDate} planId={activePlan} accountValue={accountValue} mobileView={mobileView} onAccountsUpdated={onUserDataChanged} onAccountSelected={onAccountSelected} />} />
              <Route path="/plan" element={<Plan mobileView={mobileView} planId={activePlan} accountValue={accountValue} />} />
              <Route path="/positions" element={<Positions isOptions={activeAccountIsOptions} mobileView={mobileView} planId={activePlan} accountValue={accountValue} />} />
              <Route path="/signals" element={<Signals role={role} />} />
              <Route path="/alerts" element={<Alerts planId={activePlan} mobileView={mobileView} role={role} onAlgoSelected={handleAlgoSelected} alertId={alertSelected} onAlertSelected={handleTradingAlertSelected} />} />
              <Route path="/alerts/:activeTab" element={<Alerts planId={activePlan} mobileView={mobileView} role={role} onAlgoSelected={handleAlgoSelected} alertId={alertSelected} onAlertSelected={handleTradingAlertSelected} />} />
              <Route path="/alerts/:activeTab/:alertIdParam" element={<Alerts planId={activePlan} mobileView={mobileView} role={role} onAlgoSelected={handleAlgoSelected} alertId={alertSelected} onAlertSelected={handleTradingAlertSelected} />} />
              <Route path="/configureAlert" element={<ConfigureAlert mobileView={mobileView} onAlgoSelected={handleAlgoSelected} role={role} alertId={alertSelected} />} />
              <Route path="/configureAlert/:alertIdParam" element={<ConfigureAlert mobileView={mobileView} onAlgoSelected={handleAlgoSelected} role={role} alertId={alertSelected} />} />
              <Route path="/alertSandbox" element={<AlertSandbox mobileView={mobileView} />} />
              <Route path="/subscriptions" element={<Subscriptions mobileView={mobileView} userId={userId} onPlanSelected={handlePlanSelected} />} />
              <Route path="/plans" element={<Plans mobileView={mobileView} userId={userId} onPlanSelected={handlePlanSelected} onPlansUpdated={onUserDataChanged} />} />
              <Route path="/plan/:planId" element={<Plan mobileView={mobileView} planId={activePlan} accountValue={accountValue} />} />
              <Route path="/recentUpdates" element={<AllocationChanges mobileView={mobileView} onAlgoSelected={handleAlgoSelected} planId={activePlan} accountValue={accountValue} />} />
              <Route path="/statusHistory" element={<PlanStatusHistory mobileView={mobileView} planId={activePlan} userId={userId} />} />
              <Route path="/managealgos" element={<ManageAlgos onAlgoSelected={handleAlgoSelected} onAlertSelected={handleTradingAlertSelected} planId={activePlan} userId={userId} role={role} mobileView={mobileView} />} />
              <Route path="/updatePlan" element={<UpdatePlan onAlgoSelected={handleAlgoSelected} onAlertSelected={handleTradingAlertSelected} planId={activePlan} userId={userId} role={role} mobileView={mobileView} onPlanSelected={handlePlanSelected} onPlansUpdated={onUserDataChanged} />} />
              <Route path="/updatePlan/:activeTab" element={<UpdatePlan onAlgoSelected={handleAlgoSelected} onAlertSelected={handleTradingAlertSelected} planId={activePlan} userId={userId} role={role} mobileView={mobileView} onPlanSelected={handlePlanSelected} onPlansUpdated={onUserDataChanged} />} />
              <Route path="/manageplans" element={<ManagePlans onPlansUpdated={onUserDataChanged} userId={userId} onPlanSelected={handlePlanSelected} />} />
              <Route path="/backtestupload" element={<BacktestUpload planId={activePlan} role={role} />} />
              <Route path="/runbacktest" element={<RunBacktest mobileView={mobileView} onAlgoSelected={handleAlgoSelected} planId={activePlan} userId={userId} role={role} />} />
              <Route path="/lastBacktest" element={<LastBacktest mobileView={mobileView} onAlgoSelected={handleAlgoSelected} planId={activePlan} />} />
              <Route path="/manageusers" element={<ManageUsers role={role} userId={userId} />} />
              <Route path="/createAlgo" element={<CreateAlgo role={role} />} />
              <Route path="/activeTrades" element={<ActiveTrades mobileView={mobileView} onAlgoSelected={handleAlgoSelected} planId={activePlan} accountValue={accountValue} />} />
              <Route path="/trades" element={<Trades isOptions={activeAccountIsOptions} userId={userId} accountId={activeAccountId} mobileView={mobileView} onAlgoSelected={handleAlgoSelected} planId={activePlan} accountValue={accountValue} role={role} />} />
              <Route path="/trades/:activeTab" element={<Trades isOptions={activeAccountIsOptions} userId={userId} accountId={activeAccountId} mobileView={mobileView} onAlgoSelected={handleAlgoSelected} planId={activePlan} accountValue={accountValue} role={role} />} />
              <Route path="/allActiveTrades" element={<ActiveTrades mobileView={mobileView} onAlgoSelected={handleAlgoSelected} />} />
              <Route path="/completedTrades" element={<CompletedTrades mobileView={mobileView} onAlgoSelected={handleAlgoSelected} planId={activePlan} />} />
              <Route path="/allCompletedTrades" element={<CompletedTrades mobileView={mobileView} onAlgoSelected={handleAlgoSelected} />} />
              <Route path="/detailedPerformance" element={<DetailedPerformance accountStartDate={activeAccountStartDate} mobileView={mobileView} onAlgoSelected={handleAlgoSelected} planId={activePlan} accountValue={accountValue} />} />
              <Route path="/algo" element={<Algo userId={userId} planId={activePlan} accountId={activeAccountId} mobileView={mobileView} algoId={algoSelected} role={role} onAlertSelected={handleTradingAlertSelected} />} />
              <Route path="/algo/:algoIdParam" element={<Algo userId={userId} accountId={activeAccountId} planId={activePlan} mobileView={mobileView} algoId={algoSelected} role={role} onAlertSelected={handleTradingAlertSelected} />} />
              <Route path="/algoStats" element={<AlgoStats mobileView={mobileView} planId={activePlan} onAlgoSelected={handleAlgoSelected} />} />
              <Route path="/algoPerformance" element={<AlgoPerformance mobileView={mobileView} planId={activePlan} onAlgoSelected={handleAlgoSelected} />} />
              <Route path="/performanceComparison" element={<AlgoPerformanceComparison mobileView={mobileView} planId={activePlan} onAlgoSelected={handleAlgoSelected} />} />
              <Route path="/correlations" element={<AlgoCorrelation planId={activePlan} onAlgoSelected={handleAlgoSelected} />} />
              <Route path="/stresstest" element={<StressTest planId={activePlan} onAlgoSelected={handleAlgoSelected} />} />
              <Route path="/manageSystem" element={<ManageSystem role={role} onAlgoSelected={handleAlgoSelected} userId={userId} planId={activePlan} />} />
              <Route path="/tradeWizard" element={<TradeWizard planId={activePlan} userId={userId} />} />
              <Route path="/forecast" element={<Forecast planId={activePlan} accountValue={accountValue} />} />
              <Route path="/newTrades" element={<NewTrades planId={activePlan} accountValue={accountValue} userId={userId} />} />
              <Route path="/planRecap" element={<PlanRecap accountStartDate={activeAccountStartDate} planId={activePlan} accountValue={accountValue} mobileView={mobileView} />} />
              <Route path="/tradeCorrectionEmails" element={<TradeCorrectionEmails role={role} />} />
              <Route path="/addAccount" element={<AddAccount userId={userId} onAccountsUpdated={handleAccountAdded} />} />
              <Route path="/accountsSummary" element={<AccountsSummary onAccountSelected={onAccountSelected} mobileView={mobileView} accountId={activeAccountId} userId={userId} onAccountsUpdated={onUserDataChanged} />} />
              <Route path="/replay" element={<Replay planId={activePlan} />} />
              <Route path="/planAnalysis" element={<PlanAnalysis role={role} planId={activePlan} mobileView={mobileView} />} />
              <Route path="/planAnalysis/:activeTab" element={<PlanAnalysis role={role} planId={activePlan} mobileView={mobileView} />} />
              <Route path="/stock/:symbol" element={<Stock planId={activePlan} mobileView={mobileView} onAlgoSelected={handleAlgoSelected} />} />
              <Route path="/help" element={<Help userId={userId} />} />
              <Route path='/admin/stats' element={<Stats />} />

              <Route path="/tc" element={<TermsAndConditions />} />
              <Route path="/tc/privacy" element={<PrivacyPolicy />} />
              <Route path="/tc/user" element={<UserAgreement />} />
              <Route path="/tc/personalInfo" element={<PersonalInfo />} />
            </Route>
          </Routes>
        </Router >
      </div >
    </div >
  );
}

export default App;