import React, { useState, useEffect } from 'react';
import DailyProfitChart from './DailyProfitChart.js';

function LiveVsBacktest(props) {

    const [backtestResults, setBacktestResults] = useState(null);
    const [liveResults, setLiveResults] = useState(null);

    useEffect(() => {
        (async function () {
            const url = `/api/lastBacktestResults?planId=` + props.planId;
            let text = await (await fetch(url)).json();
            setBacktestResults(text);

            const u = `/api/lastBacktestResults?planId=` + props.planId + "&liveTradesOnly=1";
            let t = await (await fetch(u)).json();
            setLiveResults(t);

        })();
    }, [props.planId]);

    return (
        <div>
            <DailyProfitChart planId={props.planId} backtestResults={liveResults} secondaryResults={backtestResults} />
        </div>
    );
}

export default LiveVsBacktest; 