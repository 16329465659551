import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Chart } from "react-google-charts";
import RichTable from '../components/richTable/RichTable.jsx'
import tableHelpers from '../helpers/TableHelpers.js'


function DailyBalanceChart(props) {

    const [chartStartDate, setChartStartDate] = useState(props.defaultToYtd ? new Date(2024, 11, 31) : new Date(2001, 1, 1))
    const [chartEndDate, setChartEndDate] = useState(props.endDate ? props.endDate : new Date())
    const [showSPY, setShowSpy] = useState(true)
    const [syncStartValues, setSyncStartValues] = useState(true);
    const [showDrawdown, setShowDrawdown] = useState(props.defaultToDrawdown ? true : false);
    const [movingAverageLength, setMovingAverageLength] = useState(0);

    useEffect(() => {
        (async function () {
            if (props.startDate) {
                let d = new Date(props.startDate);
                if (!isNaN(d)) {
                    setChartStartDate(d)
                    setChartEndDate(new Date());
                }
            }
            if (props.endDate) {
                let d = new Date(props.endDate);
                if (!isNaN(d)) {
                    setChartEndDate(new Date(props.endDate));
                }
            }
        })();
    }, [props.startDate, props.endDate]);

    function getPriorDate(start) {
        let prior = null;
        props.chartData.forEach(e => {
            const d = new Date(e[0]);
            if (prior == null || (d < start && d > prior)) {
                prior = d;
            }
        });
        return prior;
    }

    function getYears() {
        let years = {};
        props.chartData.forEach(e => {
            years[e[0].getFullYear()] = 1;
        });

        return Object.keys(years);
    }

    function handleSyncChecked() {
        if (!showDrawdown)
            setSyncStartValues(!syncStartValues);
    }

    function handleYearSelected(startYear, endYear) {
        console.log("startYear", startYear);
        console.log("endYear", endYear);
        let start = new Date();
        start.setMonth(0);
        start.setDate(1);
        start.setFullYear(startYear);
        let prior = getPriorDate(start);
        console.log("prior", prior);
        setChartStartDate(prior);

        let end = new Date();
        end.setMonth(11);
        end.setDate(31);
        end.setFullYear(endYear);
        setChartEndDate(end);
    }

    function handleDateSelected(startDate) {
        console.log("startDate", startDate);
        let start = new Date(startDate);
        setChartStartDate(start);
        setChartEndDate(new Date());
    }

    function getChartData() {

        let startDate = chartStartDate;
        let ret = props.chartData.filter(e => {
            const d = new Date(e[0]);
            return d >= startDate && d <= chartEndDate;
        });
        if (showSPY) {
            if (syncStartValues && ret.length >= 2) {
                let startValue = ret[0][1];
                let spyStartValue = ret[0][2];
                let max = null;
                let spyMax = null;
                if (spyStartValue && startValue) {
                    ret = ret.map(e => {
                        if (max === null || e[1] > max) max = e[1];
                        if (spyMax === null || e[2] > spyMax) spyMax = e[2];
                        let newSpyValue = (1 + ((e[2] - spyStartValue) / spyStartValue)) * startValue
                        const date = (e[0].getUTCMonth() + 1) + "/" + e[0].getUTCDate() + "/" + (e[0].getUTCFullYear() - 2000)
                        if (showDrawdown) {
                            let drawdown = (max - e[1]) / max
                            let spyDrawdown = (spyMax - e[2]) / spyMax
                            return [date, drawdown, spyDrawdown];
                        } else {
                            return [date, (e[1] - startValue) / startValue, (parseInt(newSpyValue) - startValue) / startValue];
                        }
                    })
                }
            }
            if (showDrawdown) {
                ret.unshift(["Date", "Plan Drawdown", "S&P 500"]);
            } else {
                ret.unshift(["Date", "Plan Performance", "S&P 500"]);
            }
        } else {
            let max = null;
            let movingAverage = [];
            ret = ret.map(e => {
                if (max === null || e[1] > max) max = Number(e[1]);
                const date = (e[0].getUTCMonth() + 1) + "/" + e[0].getUTCDate() + "/" + (e[0].getUTCFullYear() - 2000)
                if (showDrawdown) {
                    return [date, ((max - e[1]) / max)];
                } else {
                    if (movingAverageLength > 0) {
                        let ret = e[1]
                        movingAverage.push(ret);
                        if (movingAverage.length > movingAverageLength)
                            movingAverage.shift();
                        let sum = 0;
                        movingAverage.forEach(r => sum += r);
                        return [date, ret, sum / movingAverage.length];
                    } else {
                        return [date, e[1]];
                    }
                }
            })
            if (showDrawdown)
                ret.unshift(["Date", "Plan Drawdown"]);
            else if (movingAverageLength > 0)
                ret.unshift(["Date", "Plan Performance", { label: "Moving Average" }]);
            else
                ret.unshift(["Date", "Plan Performance"]);
        }

        return ret;
    }

    function getAllocationChartData() {
        if (props.backtestResults && props.backtestResults.dailyResults && props.backtestResults.dailyResults.length) {
            let tempChartData = [];
            let startDate = chartStartDate;
            props.backtestResults.dailyResults.filter(e => {
                const d = new Date(e.date);
                return d >= startDate && d <= chartEndDate;
            }).forEach(day => {
                let date = new Date(day.date);
                const str = (date.getUTCMonth() + 1) + "/" + date.getUTCDate() + "/" + (date.getUTCFullYear() - 2000)
                tempChartData.push([str, day.long_positions / day.total, day.short_positions / day.total])
            });

            tempChartData.unshift(["Date", "Long", "Short"]);
            return tempChartData;
        } else {
            return [];
        }
    }

    function renderYearLink(year) {
        return (<><Link id="b" className="menu-item" onClick={e => { handleYearSelected(year, year) }}>{year}</Link>&nbsp;&nbsp;</>);
    }

    function getChartStats(chartData) {

        let stats = { profit: 0.0, spyProfit: 0.0, maxDrawdown: 0.0, maxSpyDrawdown: 0.0, avgDrawdown: 0.0, drawdownDays: 0, drawdownPct: 0.0, totalDays: 0, spyDrawdownDays: 0, spyAvgDrawdown: 0.0, spyDrawdownPct: 0.0 };

        if (chartData.length >= 3) {

            let startValue = chartData[1][1];
            let endValue = chartData[chartData.length - 1][1];

            stats.profit = (endValue - startValue) / startValue * 100;
            if (syncStartValues)
                stats.profit = endValue;


            let max = 0;
            chartData.forEach(day => {
                ++stats.totalDays;
                if (day[1] > max) {
                    max = day[1];
                }
                let drawdown = (max - day[1]) / max * 100;
                if (drawdown > stats.maxDrawdown) stats.maxDrawdown = drawdown;

                if (drawdown > 0) {
                    stats.drawdownDays += 1;
                    stats.avgDrawdown = ((((stats.drawdownDays - 1) * stats.avgDrawdown) + drawdown) / stats.drawdownDays);
                }
            })

            stats.drawdownPct = stats.drawdownDays / stats.totalDays * 100;

            if (chartData[1].length >= 3) {
                startValue = chartData[1][2];
                endValue = chartData[chartData.length - 1][2];
                stats.spyProfit = (endValue - startValue) / startValue * 100;
                if (syncStartValues)
                    stats.spyProfit = endValue;

                max = 0;
                chartData.forEach(day => {
                    if (day[2] > max) max = day[2];
                    else {
                        let drawdown = (max - day[2]) / max * 100;
                        if (drawdown > stats.maxSpyDrawdown) stats.maxSpyDrawdown = drawdown;

                        if (drawdown > 0) {
                            stats.spyDrawdownDays += 1;
                            stats.spyAvgDrawdown = ((((stats.spyDrawdownDays - 1) * stats.spyAvgDrawdown) + drawdown) / stats.spyDrawdownDays);
                        }
                    }
                })
                stats.spyDrawdownPct = stats.spyDrawdownDays / stats.totalDays * 100;
            }
        }

        return [stats];
    }

    var mainChartOptions = {
        chartArea: { 'width': '80%', 'height': '75%' },
        legend: { position: "top" },
    }
    if ((syncStartValues && showSPY) || showDrawdown) {
        mainChartOptions.vAxis = {
            format: 'percent'
        }
    }

    let chartData = getChartData();
    let chartStats = getChartStats(chartData);

    return (
        <div>
            {
                props.chartData.length ?
                    <div>
                        {props.hideOptions === true ? null :
                            <center>
                                {props.startDate ?
                                    <><Link id="a" className="menu-item" onClick={e => { handleDateSelected(props.startDate) }}>Since Start Date</Link>&nbsp;&nbsp;<br></br></>
                                    :
                                    null
                                }
                                &nbsp;&nbsp;<Link id="a" className="menu-item" onClick={e => { handleYearSelected(2001, 2025) }}>All</Link>&nbsp;&nbsp;
                                {getYears().map(year => { return renderYearLink(year) })}
                                <br></br>
                                Show S&P500: <input type="checkbox" checked={showSPY} onChange={() => { setShowSpy(!showSPY) }}></input>
                                &nbsp;&nbsp;Sync Start Values: <input type="checkbox" checked={syncStartValues} onChange={(e) => handleSyncChecked()}></input>
                                &nbsp;&nbsp;Show Drawdown: <input type="checkbox" defaultChecked={showDrawdown} onChange={() => { setShowDrawdown(!showDrawdown); setSyncStartValues(true) }}></input>
                                &nbsp;&nbsp;MA Length: <input type="text" size="4" defaultValue="0" onChange={(e) => { setMovingAverageLength(parseInt(e.target.value)); setShowSpy(false); }}></input>
                            </center>
                        }

                        <Chart
                            chartType="AreaChart"
                            data={chartData}
                            width={props.width ? props.width : "100%"}
                            height={props.height ? props.height : "400px"}
                            options={mainChartOptions}
                            chartPackages={["corechart", "controls"]}
                        />

                        {!props.hideAllocationChart ?
                            <Chart
                                chartType="AreaChart"
                                data={getAllocationChartData()}
                                width={props.width ? props.width : "100%"}
                                height={"100px"}
                                options={{
                                    chartArea: { 'width': '80%', 'height': '75%' },
                                    vAxis: {
                                        viewWindowMode: 'explicit',
                                        viewWindow: {
                                            max: 1,
                                            min: 0
                                        },
                                        format: 'percent'
                                    }
                                }}
                                chartPackages={["corechart", "controls"]}
                            />
                            : null
                        }

                        {!props.hideChartSummary ?
                            <center>
                                <RichTable data={chartStats} className="table-striped table-hover table-condensed" mappers={[
                                    { title: 'Profit', field: 'profit', formatter: tableHelpers.percentFormatter },
                                    { title: 'SPY Profit', field: 'spyProfit', formatter: tableHelpers.percentFormatter, hidden: !showSPY },
                                ]} />
                            </center >
                            :
                            null
                        }

                    </div>
                    :
                    null
            }
        </div >);
}

export default DailyBalanceChart;