import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import RichTable from '../components/richTable/RichTable'
import { createDateSorter } from '../components/richTable/sorters'
import tableHelpers from '../helpers/TableHelpers.js'
import { Chart } from "react-google-charts";
import DatePicker from "react-datepicker";
import ExpectancyChart from '../components/ExpectancyChart.js';
import EditTrade from './EditTrade.js';
import CompletedTradesBreakdownChart from "./CompletedTradesBreakdownChart.js"
import Dropdown from 'react-bootstrap/Dropdown';

function CompletedTrades(props) {
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(new Date(2025, 0, 1))
    const [endDate, setEndDate] = useState(getCurrentDate())
    const [filterSymbol, setFilterSymbol] = useState(null)
    const [filterAlgoType, setFilterAlgoType] = useState(null)
    const [filterAlgo, setFilterAlgo] = useState(null)
    const [monthlyStat, setMonthlyStat] = useState('winPercentage')
    const [isEditingTrade, setIsEditingTrade] = useState(false);
    const [editTrade, setEditTrade] = useState(null)
    const [updates, setUpdates] = useState(0);
    const [source, setSource] = useState('plan');

    function getProfit(trade) {
        return ((trade.sell_price - trade.purchase_price) / trade.purchase_price * 100);
    }

    function getDollarProfit(trade) {
        return (trade.sell_price - trade.purchase_price);
    }

    function getCurrentDate() {
        let d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    }

    function getStats(trades) {
        let stats = { count: 0, winners: 0, losers: 0, avgProfit: 0.0, avgWin: 0.0, avgLoss: 0.0, maxWin: 0.0, maxLoss: 0.0, netProfit: 0.0, avgNetProfit: 0.0, totalProfit: 0.0 }

        trades.forEach(trade => {
            let profit = trade.user_profit ? trade.user_profit : ((trade.sell_price - trade.purchase_price) / trade.purchase_price * 100);
            stats.totalProfit += profit;
            stats.netProfit += profit * (trade.allocation ? trade.allocation / 100 : 1);
            stats.avgProfit = ((stats.avgProfit * stats.count) + profit) / (stats.count + 1);

            if (profit > 0) {
                if (profit > stats.maxWin) stats.maxWin = profit;
                stats.avgWin = ((stats.avgWin * stats.winners) + profit) / (stats.winners + 1);
                ++stats.winners;
            } else {
                if (profit < stats.maxLoss) stats.maxLoss = profit;
                stats.avgLoss = ((stats.avgLoss * stats.losers) + profit) / (stats.losers + 1);
                ++stats.losers;
            }
            ++stats.count;
        });
        stats.winningPct = (stats.winners / (stats.winners + stats.losers) * 100);
        stats.avgNetProfit = stats.count ? stats.netProfit / stats.count : 0;
        return stats;
    }

    useEffect(() => {
        (async function () {
            let text = "";
            if (props.planId !== undefined) {
                let path = "";
                if (props.onlyShowUserTrades) {
                    path = `/api/completedUserTrades/?planId=` + props.planId +
                        "&startDate=" + startDate.toLocaleDateString() +
                        "&endDate=" + endDate.toLocaleDateString() +
                        "&userId=" + props.userId +
                        "&accountId=" + props.accountId;
                } else {
                    if (source === "backtest") {
                        path = `/api/backtestTradesDecorated/?planId=` + props.planId +
                            "&startDate=" + startDate.toLocaleDateString() +
                            "&endDate=" + endDate.toLocaleDateString()
                    } else {
                        path = `/api/completedTrades/?planId=` + props.planId +
                            "&startDate=" + startDate.toLocaleDateString() +
                            "&endDate=" + endDate.toLocaleDateString() +
                            "&userId=" + props.userId + "&accountId=" + props.accountId +
                            "&useCurrentAllocations=" + (source === "updated")
                    }
                }

                text = await (await fetch(path)).json();
                console.log("completed trades", text);
            }
            else {
                let path = `/api/allCompletedTrades?` +
                    "startDate=" + startDate.toLocaleDateString() +
                    "&endDate=" + endDate.toLocaleDateString();

                text = await (await fetch(path)).json();
            }
            setData(text);
        })();
    }, [props.planId, startDate, endDate, props.userId, props.accountId, props.onlyShowUserTrades, updates, source]);

    function getTrades() {
        let filtered = filterSymbol ? data.filter(trade => trade.trade_symbol === filterSymbol) : data;
        filtered = filterAlgoType ? filtered.filter(trade => trade.algo_type === filterAlgoType) : filtered;
        filtered = filterAlgo ? filtered.filter(trade => trade.display_name === filterAlgo) : filtered;
        return filtered.map(trade => {
            let userProfit = (trade.user_sell_price - trade.user_purchase_price) / trade.user_purchase_price * 100;
            return {
                trade_symbol: trade.trade_symbol,
                base_symbol: trade.symbol,
                purchase_date: trade.purchase_date,
                purchase_price: trade.user_purchase_price ? trade.user_purchase_price : trade.purchase_price,
                sell_date: trade.user_sell_date ? trade.user_sell_date : trade.sell_date,
                sell_price: trade.user_sell_price ? trade.user_sell_price : trade.sell_price,
                dollarProfit: trade.user_purchase_price && trade.user_sell_price ? trade.user_sell_price - trade.user_purchase_price : getDollarProfit(trade),
                profit: getProfit(trade),
                display_name: trade.display_name,
                algo_type: trade.algo_type,
                entry_speed: trade.entry_speed,
                algoId: trade.idalgos,
                allocation: trade.allocation,
                avg_win: trade.avg_win,
                avg_loss: trade.avg_loss,
                realizedProfit: getProfit(trade) * trade.allocation / 100,
                user_symbol: trade.user_symbol,
                user_amount: trade.user_amount,
                user_trade_id: trade.user_trade_id,
                user_purchase_price: trade.user_purchase_price,
                user_sell_price: trade.user_sell_price,
                user_profit: userProfit,
                user_trade_is_option: trade.user_trade_is_option,
            }
        })
    }

    function getStatsArray() {
        let stats = getStats(getTrades());
        return [stats];
    }

    function algoLinkFormatter(cell, row) {

        let algoid = row.algoId;
        return (
            <Link className="bg-transparent" to={"/algo/" + algoid} >{cell}</Link>
        )
    }

    function renderPLCharts() {

        let data = getTrades();
        if (data === null || data.length === 0)
            return null;

        let chartData = [];
        chartData.push(['Profit', 'Count', { role: "style" }]);

        let distribution = {};
        for (let i = -20; i <= 21; i++) {
            distribution[i] = 0;
        }

        data.forEach(trade => {
            const profit = getProfit(trade);
            if (profit < -20) {
                distribution[-20] += 1;
            } else if (profit < -10) {
                distribution[-10] += 1;
            } else if (profit < -5) {
                distribution[-5] += 1;
            } else if (profit < 0) {
                distribution[0] += 1;
            } else if (profit < 5) {
                distribution[5] += 1;
            } else if (profit < 10) {
                distribution[10] += 1;
            } else if (profit < 20) {
                distribution[20] += 1;
            } else {
                distribution[21] += 1;
            }
        })

        chartData.push(['<-20%', distribution[-20], "#B85D5D"]);
        chartData.push(['-10 to -20%', distribution[-10], "#B85D5D"]);
        chartData.push(['-5 to -10%', distribution[-5], "#B85D5D"]);
        chartData.push(['0 to -5%', distribution[0], "#B85D5D"]);
        chartData.push(['0 to 5%', distribution[5], "#B2D8B2"]);
        chartData.push(['5 to 10%', distribution[10], "#B2D8B2"]);
        chartData.push(['10 to 20%', distribution[20], "#B2D8B2"]);
        chartData.push(['>20%', distribution[21], "#B2D8B2"]);

        var options = { legend: { position: 'none' }, bar: { width: "25" } }
        var optionsPercent = { legend: { position: 'none' }, vAxis: { format: '#%' }, bar: { width: "25" } }

        let netChartData = [];
        const stats = getStats(data);
        netChartData.push(['Total', 'Percent', { role: "style" }]);
        netChartData.push(["Avg Loss", Math.abs(stats.avgLoss / 100), "#B85D5D"]);
        netChartData.push(["Avg Per Trade", stats.avgProfit / 100, "#A0DDFD"]);
        netChartData.push(["Avg Win", stats.avgWin / 100, "#B2D8B2"]);

        return (
            <>
                <table border="0" width="100%">
                    <tr>
                        <td width="50%">
                            <Chart
                                chartType="ColumnChart"
                                data={netChartData}
                                width={"100%"}
                                options={optionsPercent}
                                chartPackages={["corechart", "controls"]}
                            />
                        </td>
                        <td width="50%">
                            <Chart
                                chartType="ColumnChart"
                                data={chartData}
                                width={"100%"}
                                options={options}
                                chartPackages={["corechart", "controls"]}
                            />
                        </td>
                    </tr>
                </table>
            </>
        );
    }

    function renderRealizedGainCharts() {

        if (props.planId === undefined || data === null || data.length === 0)
            return (<></>);
        else {
            let chartData = [];
            chartData.push(['Date', 'Gain']);

            let visibleTrades = getTrades();
            const localEndDate = new Date(endDate);

            let date = new Date(startDate);
            let balance = 0;
            while (date <= localEndDate) {
                for (const trade of visibleTrades) {
                    const closeDate = new Date(trade.sell_date);
                    const allocation = trade.allocation;
                    if (date.getFullYear() === closeDate.getFullYear() &&
                        date.getMonth() === closeDate.getMonth() &&
                        date.getDate() === closeDate.getDate()) {

                        const profit = getProfit(trade) * allocation / 100 / 100;
                        balance += profit;
                    }
                }

                chartData.push([new Date(date), balance])

                date.setDate(date.getDate() + 1)
            }
            var options = { title: "Realized P/L (not compounded)", legend: { position: 'none' }, vAxis: { format: 'percent' } }

            function getTradesBySymbolChartData() {
                let tradeBySymbol = [];
                getTrades().forEach(trade => {
                    if (tradeBySymbol[trade.trade_symbol]) {
                        tradeBySymbol[trade.trade_symbol] += trade.profit * trade.allocation / 100;
                    } else {
                        tradeBySymbol[trade.trade_symbol] = trade.profit * trade.allocation / 100;
                    }
                })
                let symbols = Object.keys(tradeBySymbol);
                let ret = [["Symbol", "Net"]];
                symbols.forEach(sym => {
                    ret.push([sym, tradeBySymbol[sym]])
                })
                return ret;
            }

            return (
                <div>
                    <center>
                        <table border="0" width="100%">
                            <tr>
                                <td width="50%">
                                    <Chart
                                        chartType="AreaChart"
                                        data={chartData}
                                        width={"100%"}
                                        options={options}
                                        chartPackages={["corechart", "controls"]}
                                    />
                                </td>
                                <td>
                                    <Chart
                                        chartType="ColumnChart"
                                        data={getTradesBySymbolChartData()}
                                        width={"100%"}
                                        chartPackages={["corechart", "controls"]}
                                        options={{
                                            title: "Net profit by stock",
                                            legend: 'none',
                                            bar: { width: "25" }
                                        }}
                                    />
                                </td>
                            </tr>
                        </table>

                    </center>
                </div>
            )
        }
    }

    function handleYtdClicked() {
        console.log("handleYtdClicked");
        let d = new Date(new Date().getUTCFullYear(), 0, 1);
        setStartDate(d);
        setEndDate(getCurrentDate());
    }

    function handleAllClicked() {
        let d = new Date(2023, 10, 1);
        setStartDate(d);
        setEndDate(getCurrentDate());
    }

    function handleMtdClicked() {
        let d = new Date(new Date().getUTCFullYear(), new Date().getMonth(), 1);
        setStartDate(d);
        setEndDate(getCurrentDate());
    }

    function handleLastWeeksClicked(weeks) {
        let d = new Date();
        d.setDate(d.getDate() - weeks * 7);
        setStartDate(d);
        setEndDate(getCurrentDate());
    }

    function getMonthlyStatistic(year, month) {
        let trades = data.filter(s => {
            let sellDate = new Date(s.sell_date);
            return sellDate.getUTCFullYear() === year && sellDate.getUTCMonth() === month;
        });
        let stats = { count: 0, wins: 0, totalProfit: 0, netProfit: 0 }
        trades.forEach(trade => {
            let profit = getProfit(trade);
            ++stats.count;
            if (profit >= 0)
                ++stats.wins;
            stats.totalProfit += profit;
            stats.netProfit += profit * trade.allocation / 100;
        })
        switch (monthlyStat) {
            case 'winPercentage': return stats.wins / stats.count * 100;
            case 'count': return stats.count;
            case 'avgProfit': return stats.totalProfit / stats.count;
            case 'totalProfit': return stats.totalProfit;
            case 'netProfit': return stats.netProfit;
            case 'avgNetProfit': return stats.netProfit / stats.count;

            default: return stats.wins / stats.count * 100;
        }
    }

    function getMonthlyFormat() {
        switch (monthlyStat) {
            case 'count': return tableHelpers.intFormatter;
            default: {
                if (props.mobileView) return tableHelpers.condensedPercentFormatter;
                return tableHelpers.condensedPercentFormatter;
            }
        }
    }

    function getMonthlyData() {
        let d = new Date(startDate);
        let result = [];
        while (d.getFullYear() <= endDate.getFullYear()) {
            result.push(
                {
                    year: `'` + (d.getFullYear() - 2000).toFixed(0),
                    jan: getMonthlyStatistic(d.getFullYear(), 0),
                    feb: getMonthlyStatistic(d.getFullYear(), 1),
                    mar: getMonthlyStatistic(d.getFullYear(), 2),
                    apr: getMonthlyStatistic(d.getFullYear(), 3),
                    may: getMonthlyStatistic(d.getFullYear(), 4),
                    jun: getMonthlyStatistic(d.getFullYear(), 5),
                    jul: getMonthlyStatistic(d.getFullYear(), 6),
                    aug: getMonthlyStatistic(d.getFullYear(), 7),
                    sep: getMonthlyStatistic(d.getFullYear(), 8),
                    oct: getMonthlyStatistic(d.getFullYear(), 9),
                    nov: getMonthlyStatistic(d.getFullYear(), 10),
                    dec: getMonthlyStatistic(d.getFullYear(), 11),
                }
            );
            d.setFullYear(d.getFullYear() + 1);
        }
        return result;
    }

    function symbolFormatter(cell, row) {
        if (row.user_symbol)
            return <>{row.user_symbol} <Link className="bg-transparent" onClick={() => showEditModal(row)}>✏️</Link></>
        else
            return <Link id="link" className="bg-transparent" to={`/stock/${cell}`}>{cell}</Link>;
    }

    function profitFormatter(cell, row) {
        if (row.user_trade_is_option) {
            return tableHelpers.percentChangeFormatter(row.user_profit, null);
        } else {
            return tableHelpers.percentChangeFormatter(cell, null);
        }
    }

    function profitChartFormatter(cell, row) {
        return (
            <ExpectancyChart profit={row.profit} avgWin={row.avg_win} avgLoss={row.avg_loss} width={250}></ExpectancyChart>
        )
    }

    function symbolAlgoFormatter(cell, row) {
        let algoid = row.algoId;
        return (
            <>
                <Link id="link" className="bg-transparent" to={`/stock/${row.trade_symbol}`}>{row.trade_symbol}</Link> <Link className="bg-transparent" to={"/algo/" + algoid}>{row.display_name}</Link>
                {row.user_symbol ?
                    <>&nbsp;<Link className="bg-transparent" to={"/algo/" + algoid}>{row.display_name}</Link>  <Link className="bg-transparent" onClick={() => showEditModal(row)}>✏️</Link>
                    </>
                    : null}
            </>
        )
    }

    function showEditModal(trade) {
        console.log("Opening modal for ", trade);
        setIsEditingTrade(true);
        setEditTrade(trade)
    }

    function onStopEditingTrade() {
        setIsEditingTrade(false)
        setEditTrade(null);
        setUpdates(updates + 1);
    }

    function getUniqueSymbols() {
        return [...new Set(data.map(trade => trade.trade_symbol))].sort();
    }
    function getUniqueAlgos() {
        return [...new Set(data.map(trade => trade.display_name))].sort();
    }

    return (
        <>

            {!props.hideDateSelector ?
                <center>
                    Start Date: <DatePicker className="bg-transparent" selected={startDate} onChange={(date) => { console.log("Setting start date", date); setStartDate(date) }} />&nbsp;&nbsp;
                    <br></br>
                    End Date: <DatePicker className="bg-transparent" selected={endDate} onChange={(date) => { console.log("Setting end date", date); setEndDate(date); }} />
                    <br></br>
                    <div className="sameline">Source:
                        <Dropdown onSelect={(e) => setSource(e)}>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                {source}
                            </Dropdown.Toggle>
                            <Dropdown.Menu  >
                                <Dropdown.Item as='datasource' eventKey={"plan"} value={"plan"}>Plan Trades</Dropdown.Item>
                                <Dropdown.Item as='datasource' eventKey={"updated"} value={"updated"}>Updated Allocations</Dropdown.Item>
                                <Dropdown.Item as='datasource' eventKey={"backtest"} value={"backtest"}>Backtest (Updated Allocations & Algos)</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <br></br>
                    <Link className="menu-item" onClick={e => { handleAllClicked() }}>All</Link>
                    &nbsp;&nbsp;<Link className="menu-item" onClick={e => { handleYtdClicked() }}>YTD</Link>
                    &nbsp;&nbsp;<Link className="menu-item" onClick={e => { handleMtdClicked() }}>MTD</Link>
                    &nbsp;&nbsp;<Link className="menu-item" onClick={e => { handleLastWeeksClicked(4) }}>4 weeks</Link>
                    &nbsp;&nbsp;<Link className="menu-item" onClick={e => { handleLastWeeksClicked(8) }}>8 weeks</Link>
                    &nbsp;&nbsp;<Link className="menu-item" onClick={e => { handleLastWeeksClicked(12) }}>12 weeks</Link>
                    <br></br>

                    <hr></hr>
                    <div className="sameline">
                        Filter by Symbol:
                        <Dropdown onSelect={(e) => setFilterSymbol(e)}>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                {filterSymbol}
                            </Dropdown.Toggle>
                            <Dropdown.Menu  >
                                <Dropdown.Item as='group' eventKey={null} value={null}>None</Dropdown.Item>
                                {getUniqueSymbols().map(sym => {
                                    return (
                                        <Dropdown.Item as='sym' eventKey={sym} value={sym}>{sym}</Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>

                        Filter by Algo Type:
                        <Dropdown onSelect={(e) => setFilterAlgoType(e)}>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                {filterAlgoType}
                            </Dropdown.Toggle>
                            <Dropdown.Menu  >
                                <Dropdown.Item as='group' eventKey={null} value={null}>None</Dropdown.Item>
                                <Dropdown.Item as='group' eventKey={"Trend"} value={"Trend"}>Trend</Dropdown.Item>
                                <Dropdown.Item as='group' eventKey={"Mean Reversion"} value={"Mean Reversion"}>Mean Reversion</Dropdown.Item>
                                <Dropdown.Item as='group' eventKey={"Candlestick"} value={"Candlestick"}>Candlestick</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        {!props.mobileView ?
                            <>
                                Filter by Algo:
                                <Dropdown onSelect={(e) => setFilterAlgo(e)}>
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                        {filterAlgo}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu  >
                                        <Dropdown.Item as='group' eventKey={null} value={null}>None</Dropdown.Item>
                                        {getUniqueAlgos().map(algo => {
                                            return (
                                                <Dropdown.Item as='algo' eventKey={algo} value={algo}>{algo}</Dropdown.Item>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </>
                            : null}
                    </div>
                    <hr></hr>
                </center>
                :
                null}

            <br></br>
            <RichTable data={getStatsArray()} className="table-striped table-hover table-condensed fixed" mappers={[
                { title: 'Trades', field: 'count', formatter: tableHelpers.intFormatter },
                { title: 'Win %', field: 'winningPct', formatter: tableHelpers.percentFormatter },
                { title: 'Net', field: 'netProfit', formatter: tableHelpers.percentChangeFormatter },
                { title: 'Avg P/L', field: 'avgProfit', formatter: tableHelpers.percentChangeFormatter },
                { title: 'Avg Net P/L', field: 'avgNetProfit', formatter: tableHelpers.percentChangeFormatter },
                { title: 'Avg Win', field: 'avgWin', formatter: tableHelpers.percentChangeFormatter, hidden: props.mobileView },
                { title: 'Avg Loss', field: 'avgLoss', formatter: tableHelpers.percentChangeFormatter, hidden: props.mobileView },
                { title: 'Max Win', field: 'maxWin', formatter: tableHelpers.percentChangeFormatter, hidden: props.mobileView },
                { title: 'Max Loss', field: 'maxLoss', formatter: tableHelpers.percentChangeFormatter, hidden: props.mobileView },
            ]} />

            {!props.hideCharts ? renderRealizedGainCharts() : null}
            {!props.hideCharts ? renderPLCharts() : null}
            {!props.hideCharts ? <>
                <center>
                    <CompletedTradesBreakdownChart mobileView={props.mobileView} defaultGroupBy={"trade_symbol"} defaultValueType={"winning percentage"} trades={getTrades()} width="100%" planId={props.planId} />
                </center>
            </>
                : null
            }

            <hr></hr>
            <center>
                <Link className="bg-transparent" onClick={() => setMonthlyStat('winPercentage')}>Win Rate</Link>
                &nbsp;&nbsp;<Link className="bg-transparent" onClick={() => setMonthlyStat('count')}>Count</Link>
                &nbsp;&nbsp;<Link className="bg-transparent" onClick={() => setMonthlyStat('netProfit')}>Net Profit</Link>
                &nbsp;&nbsp;<Link className="bg-transparent" onClick={() => setMonthlyStat('avgProfit')}>Avg Trade</Link>
                &nbsp;&nbsp;<Link className="bg-transparent" onClick={() => setMonthlyStat('avgNetProfit')}>Avg Trade Net</Link>
            </center>
            <hr></hr>

            <RichTable data={getMonthlyData()} className="table-striped table-hover table-condensed fixed" mappers={[
                { title: 'Year', field: 'year', formatter: tableHelpers.intFormatter },
                { title: 'J', field: 'jan', formatter: getMonthlyFormat() },
                { title: 'F', field: 'feb', formatter: getMonthlyFormat() },
                { title: 'M', field: 'mar', formatter: getMonthlyFormat() },
                { title: 'A', field: 'apr', formatter: getMonthlyFormat() },
                { title: 'M', field: 'may', formatter: getMonthlyFormat() },
                { title: 'J', field: 'jun', formatter: getMonthlyFormat() },
                { title: 'J', field: 'jul', formatter: getMonthlyFormat() },
                { title: 'A', field: 'aug', formatter: getMonthlyFormat() },
                { title: 'S', field: 'sep', formatter: getMonthlyFormat() },
                { title: 'O', field: 'oct', formatter: getMonthlyFormat() },
                { title: 'N', field: 'nov', formatter: getMonthlyFormat() },
                { title: 'D', field: 'dec', formatter: getMonthlyFormat() },
            ]} />
            <br></br>

            {props.mobileView ?
                <RichTable data={getTrades()} className="table-striped table-hover table-condensed" pageSize={10} mappers={[
                    { title: 'Sell Date', field: 'sell_date', formatter: tableHelpers.dateFormmatter, sorters: createDateSorter('sell_date'), isDefaultSort: true },
                    { title: `Algo`, field: 'trade_symbol', sorters: true, formatter: symbolAlgoFormatter },
                    { title: `Profit`, field: 'profit', sorters: true, formatter: profitChartFormatter, width: 250 },
                ]} />
                :
                <RichTable data={getTrades()} className="table-striped table-hover table-condensed" pageSize={10} mappers={[
                    { title: 'Purch Date', field: 'purchase_date', formatter: tableHelpers.dateFormmatter, hidden: props.mobileView, sorters: createDateSorter('purchase_date') },
                    { title: 'Sell Date', field: 'sell_date', formatter: tableHelpers.dateFormmatter, sorters: createDateSorter('sell_date'), isDefaultSort: true },
                    { title: 'Symbol', field: 'trade_symbol', formatter: symbolFormatter, sorters: true },
                    { title: 'Algo', field: 'display_name', formatter: algoLinkFormatter, sorters: true },
                    { title: 'Type', field: 'algo_type', sorters: true },
                    { title: 'P/L', field: 'profit', formatter: profitFormatter, sorters: true },
                    { title: 'Vs. Expectancy', field: 'profit', formatter: profitChartFormatter, sorters: true, hidden: props.mobileView, width: 300 },
                    { title: 'Allocation', field: 'allocation', formatter: tableHelpers.percentFormatterNoDecimals, sorters: true, hidden: props.mobileView || props.planId === undefined },
                    { title: 'Net P/L', field: 'realizedProfit', formatter: tableHelpers.percentChangeFormatter, sorters: true, hidden: props.mobileView || props.planId === undefined },
                ]} />
            }

            <EditTrade isOpen={isEditingTrade && editTrade}
                onClose={onStopEditingTrade}
                userTradeId={editTrade ? editTrade.user_trade_id : null}
            />
        </>
    );
}

export default CompletedTrades;