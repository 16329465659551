import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";


function AlgoRealizedGainChart(props) {

    const [tradeHistory, setTradeHistory] = useState(props.trades ? props.trades : []);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null)

    useEffect(() => {
        (async function () {

            setStartDate(props.startDate);
            setEndDate(props.endDate);

            if (!props.trades) {
                const url = `/api/backtestTrades?algos=` + props.algoId +
                    "&startDate=" + props.startDate.toLocaleDateString() +
                    "&endDate=" + props.endDate.toLocaleDateString();
                let history = await (await fetch(url)).json();
                setTradeHistory(history);
            } else {
                setTradeHistory(props.trades);
            }
        })();
    }, [props.algoId, props.trades, props.startDate, props.endDate]);

    function renderRealizedGainChart(useLiveTrades = false) {

        let chartData = [];
        chartData.push(['Date', 'Gain', { role: "style" }]);

        const trades = tradeHistory;
        const end = new Date(endDate);
        let date = new Date(startDate);
        let balance = 0;
        let maxValue = balance;
        let minValue = balance;
        while (date <= end) {
            for (const trade of trades) {
                const closeDate = new Date(trade.sell_date);
                if (date.getFullYear() === closeDate.getFullYear() &&
                    date.getMonth() === closeDate.getMonth() &&
                    date.getDate() === closeDate.getDate()) {

                    const profit = trade.profit / 100;
                    balance += profit;
                }
                if (balance > maxValue) maxValue = balance;
                if (balance < minValue) minValue = balance;
            }

            let color = balance > 0 ? "#B2D8B2" : "#B85D5D";
            chartData.push([new Date(date), balance, color])
            date.setDate(date.getDate() + 1)
        }

        const options = {
            vAxis: {
                format: '#%',
                viewWindowMode: 'explicit',
                viewWindow: {
                    max: maxValue + (Math.abs(maxValue) * .1),
                    min: minValue - (Math.abs(minValue) * .1)
                },
                minValue: maxValue + (Math.abs(maxValue) * .1),
                maxValue: minValue - (Math.abs(minValue) * .1)
            },
            chartArea: { 'width': '75%', 'height': '70%' },
            legend: { position: 'none' },
        };

        if (chartData.length <= 1) {
            return (<></>);
        } else {
            return (
                <div>
                    <center>
                        <Chart
                            chartType="AreaChart"
                            data={chartData}
                            width={"100%"}
                            height={"200px"}
                            options={options}
                            chartPackages={["corechart", "controls"]}
                        />
                    </center>
                </div>
            )
        }
    }

    return (
        <>
            {renderRealizedGainChart()}
        </>
    );
}

export default AlgoRealizedGainChart;