import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import RichTable from '../components/richTable/RichTable.jsx'
import tableHelpers from '../helpers/TableHelpers.js'
import { Chart } from "react-google-charts";
import TwoStepConfirmationButton from '../components/TwoStepConfirmButton.js'
import ActionResult from '../components/ActionResult.js';
import Dropdown from 'react-bootstrap/Dropdown';


function PlanMetrics(props) {
    const [data, setData] = useState([]);
    const [chartField, setChartField] = useState('robustness_overall');
    const [chartRange, setChartRange] = useState([0, 10]);
    const [chartTitle, setChartTitle] = useState('Robustness');
    const [updates, setUpdates] = useState(0);
    const [result, setResult] = useState(null);
    const [days, setDays] = useState(1);

    useEffect(() => {
        (async function () {
            let metrics = await ((await fetch(`/api/planMetrics?planId=` + props.planId))).json()
            setData(metrics);
        })();
    }, [props.planId, updates]);


    function getCurrentMetrics() {
        let current = data && data.length ? data[data.length - 1] : null;
        let previous = data && data.length > days ? data[data.length - days - 1] : null;
        if (current) {
            return [
                { title: 'Robustness', value: current.robustness_overall, format: 'float', field: 'robustness_overall', link: '/planAnalysis/robustness', previousValue: previous ? previous.robustness_overall : null },
                { title: 'Weighted Robustness', value: current.robustness_weighted, format: 'float', field: 'robustness_weighted', link: '/planAnalysis/robustness', previousValue: previous ? previous.robustness_weighted : null },
                { title: 'Missing Trades', value: current.missing_trade_count, format: 'int', field: 'missing_trade_count', previousValue: previous ? previous.missing_trade_count : null, range: [0, 10] },
                { title: '10 Day Drift', value: current.live_vs_backtest_10_days, format: 'percent', field: 'live_vs_backtest_10_days', link: '/planAnalysis/livevsbacktest', previousValue: previous ? previous.live_vs_backtest_10_days : null, range: [-2, 2] },
                { title: '22 Day Drift', value: current.live_vs_backtest_22_days, format: 'percent', field: 'live_vs_backtest_22_days', link: '/planAnalysis/livevsbacktest', previousValue: previous ? previous.live_vs_backtest_22_days : null, range: [-2, 2] },
                { title: 'Monte Carlo Average', value: current.monte_carlo_annual_return, format: 'percent', field: 'monte_carlo_annual_return', link: '/planAnalysis/montecarlo', previousValue: previous ? previous.monte_carlo_annual_return : null },
                { title: 'Monte Carlo Stddev', value: current.monte_carlo_annual_return_stddev, format: 'percent', field: 'monte_carlo_annual_return_stddev', link: '/planAnalysis/montecarlo', previousValue: previous ? previous.monte_carlo_annual_return_stddev : null, range: [-5, 5] },
                { title: 'Backtest Annual Return', value: current.backtest_annual_return, format: 'percent', field: 'backtest_annual_return', link: '/lastBacktest', previousValue: previous ? previous.backtest_annual_return : null },
                { title: 'Backtest 1Y Return', value: current.backtest_one_year_return, format: 'percent', field: 'backtest_one_year_return' },
                { title: 'Backtest 1M Return', value: current.backtest_one_month_return, format: 'percent', field: 'backtest_one_month_return' },
                { title: 'Backtest Max Drawdown', value: current.backtest_max_drawdown, format: 'percent', field: 'backtest_max_drawdown', link: '/lastBacktest', previousValue: previous ? previous.backtest_max_drawdown : null },
                { title: 'Live 1Y Return', value: current.live_one_year_return, format: 'percent', field: 'live_one_year_return' },
                { title: 'Live 1M Return', value: current.live_one_month_return, format: 'percent', field: 'live_one_month_return', link: '/detailedPerformance' },
                { title: 'Algos', value: current.allocations_algo_count, format: 'int', field: 'allocations_algo_count', link: '/updatePlan', previousValue: previous ? previous.allocations_algo_count : null },
                { title: 'Average Allocation', value: current.allocations_average_allocation, format: 'percent', field: 'allocations_average_allocation', link: '/updatePlan', previousValue: previous ? previous.percent : null, range: [0, 10] },
                { title: 'Allocation Changes - Last 10 Days', value: current.allocation_updates_10_days, format: 'int', field: 'allocation_updates_10_days', link: '/updatePlan', previousValue: previous ? previous.allocation_updates_10_days : null },
            ]
        } else {
            return [];
        }
    }

    function rowClass(row) {
        if (row.field === chartField) return "bold"
        else return "";
    }

    function viewChart(field, range, title) {
        setChartTitle(title);
        setChartField(field);
        setChartRange(range)
    }

    function detailsFormatter(field, row) {
        return (
            <>
                <Link onClick={() => viewChart(row.field, row.range, row.title)}>📊</Link>
                {row.link ? <>&nbsp;&nbsp;<Link reloadDocument to={row.link}>Details</Link></> : null}
            </>
        )
    }

    function getChartData() {
        if (data) {
            let result = data.map(d => [new Date(d.datetime), d[chartField]]);
            result.unshift(["Date", chartField])
            return result;
        } else {
            return [];
        }
    }

    async function onUpdateMetrics() {
        let result = await ((await fetch(`/api/planMetricsUpdate?planId=` + props.planId, { method: 'post' }))).json()
        setResult(result);
        setUpdates(updates + 1)
    }


    function dynamicFormatterWithDirection(cell, row) {
        let direction = "";
        if (row.previousValue) {
            if (cell > row.previousValue)
                direction = "🔺"
            else if (cell < row.previousValue)
                direction = "🔻"
        }
        return tableHelpers.dynamicFormatter(cell, row) + " " + direction;
    }

    return (
        <>
            <table width="100%">
                <tr>
                    <td width={"40%"}>
                        <Dropdown width="150" onSelect={(e) => setDays(e)}>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                {days} day trend
                            </Dropdown.Toggle>

                            <Dropdown.Menu  >
                                <Dropdown.Item eventKey={1} value={1}>1</Dropdown.Item>
                                <Dropdown.Item eventKey={2} value={2}>2</Dropdown.Item>
                                <Dropdown.Item eventKey={5} value={5}>5</Dropdown.Item>
                                <Dropdown.Item eventKey={10} value={10}>10</Dropdown.Item>
                                <Dropdown.Item eventKey={22} value={22}>22</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <RichTable data={getCurrentMetrics()} mappers={[
                            { title: 'Name', field: 'title' },
                            { title: 'Value', field: 'value', formatter: dynamicFormatterWithDirection },
                            { title: 'Details', field: 'link', formatter: detailsFormatter },
                        ]} className="table-striped table-hover table-condensed" rowClassName={rowClass} />
                    </td>
                    <td width={"60%"} valign='top'>
                        {data && data.length ?
                            <>
                                <center>
                                    <h4>{chartTitle}</h4>
                                </center>
                                <Chart
                                    chartType="LineChart"
                                    data={getChartData()}
                                    width={"100%"}
                                    height={"300px"}
                                    chartPackages={["corechart", "controls"]}
                                    options={{
                                        legend: 'none',
                                        series: {
                                            0: { lineWidth: 4 },
                                        },
                                        vAxis: {
                                            viewWindowMode: chartRange ? 'explicit' : undefined,
                                            viewWindow: {
                                                max: chartRange ? chartRange[1] : undefined,
                                                min: chartRange ? chartRange[0] : undefined,
                                            },
                                        },
                                    }}
                                />
                            </>
                            :
                            null}
                    </td>
                </tr>
            </table>

            {props.role >= 3 ?
                <TwoStepConfirmationButton id={0} text="Update Metrics" onConfirmed={onUpdateMetrics} >
                    <>
                    </>
                </TwoStepConfirmationButton>
                :
                null}

            <ActionResult result={result} />
        </>
    );
}

export default PlanMetrics;