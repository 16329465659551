import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import { Button } from 'react-bootstrap'
import RichTable from '../components/richTable/RichTable.jsx'
import tableHelpers from '../helpers/TableHelpers.js'
import { Link } from 'react-router-dom'

function Forecast(props) {
    const [backtestResults, setBacktestResults] = useState({});
    const [balances, setBalances] = useState([]);
    const [monthlyWithdraw, setMonthlyWithdraw] = useState(0);
    const [monthlyDeposit, setMonthlyDeposit] = useState(0);
    const [simulationYears, setSimulationYears] = useState(10);
    const [startingAmount, setStartingAmount] = useState(Number(props.accountValue));
    const [showAverage, setShowAverage] = useState(false);
    const [monthlyAverageReturn, setMonthlyAverageReturn] = useState(0);
    const [backtestMonthlyAverageReturn, setBacktestMonthlyAverageReturn] = useState(0);

    useEffect(() => {
        (async function () {
            const url = `/api/lastBacktestResults?planId=` + props.planId;
            let text = await (await fetch(url)).json();
            setBacktestResults(text);
            let avgProfit = getPeriodResult(text, "monthly", "avgResult");
            setMonthlyAverageReturn(avgProfit.toFixed(2));
            setBacktestMonthlyAverageReturn(avgProfit);
        })();
    }, [props.planId]);

    useEffect(() => {
        (async function () {
            setStartingAmount(Number(props.accountValue))
        })();
    }, [props.accountValue]);

    function getPeriodResult(results, timeframe, key) {
        return (results && results.periodSummaries && results.periodSummaries[timeframe] && results.periodSummaries[timeframe][key]) ?
            results.periodSummaries[timeframe][key] : 0;
    }

    function getMonthlyValue(year, month) {
        if (backtestResults && backtestResults.periodSummaries && backtestResults.periodSummaries.profitByMonth)
            return backtestResults.periodSummaries.profitByMonth[year][month] ? backtestResults.periodSummaries.profitByMonth[year][month].toFixed(2) : "0"
        else
            return "0"
    }

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    function getRandomMonthlyReturn() {
        if (backtestResults && backtestResults.periodSummaries && backtestResults.periodSummaries.profitByMonth) {
            const yearArray = Object.keys(backtestResults.periodSummaries.profitByMonth);
            let year = yearArray[getRandomInt(yearArray.length)];
            const monthArray = Object.keys(backtestResults.periodSummaries.profitByMonth[year]);
            let month = monthArray[getRandomInt(monthArray.length)];
            return getMonthlyValue(year, month);
        } else {
            return 0;
        }
    }

    function runSimulation() {
        let monthlyBalance = [];

        let year = new Date().getFullYear();
        let balance = startingAmount;
        let avgBalance = startingAmount;
        let monthlyReturnMultiplier = Number(monthlyAverageReturn) / backtestMonthlyAverageReturn;
        let totalWithdrawals = 0;
        let totalProfits = 0;
        let totalDeposits = 0;

        for (let i = 0; i < simulationYears; i++) {
            let month = 0;
            if (i === 0) {
                month = new Date().getMonth();
            }
            while (month < 12) {

                let rando = getRandomMonthlyReturn()
                console.log(rando)
                let profit = rando * monthlyReturnMultiplier / 100;
                let avgProfit = Number(monthlyAverageReturn) * monthlyReturnMultiplier / 100;

                let netChange = monthlyDeposit - monthlyWithdraw;
                netChange += (balance * profit);

                let avgChange = monthlyDeposit - monthlyWithdraw;
                avgChange += (avgBalance * avgProfit);

                totalWithdrawals += monthlyWithdraw;
                totalProfits += balance * profit;
                totalDeposits += monthlyDeposit;

                balance += netChange;
                avgBalance += avgChange;

                monthlyBalance.push(
                    {
                        date: new Date(year, month, 0),
                        balance: balance,
                        profit: profit,
                        change: netChange,
                        avgBalance: avgBalance,
                        avgProfit: avgProfit,
                        avgChange: avgChange,
                        totalWithdrawals: totalWithdrawals,
                        totalProfits: totalProfits,
                        totalDeposits: totalDeposits
                    }
                )
                ++month;
            }
            year++;
        }

        return monthlyBalance;
    }

    function handleRunSimulation() {
        const balances = runSimulation();
        setBalances(balances);
    }

    function getSummaryArray() {
        if (balances.length === 0)
            return [];

        let stats = {
            endBalance: balances[balances.length - 1].balance,
            avgBalance: balances[balances.length - 1].avgBalance,
            profit: balances[balances.length - 1].balance - balances[balances.length - 2].balance,
            profitPct: (balances[balances.length - 1].balance - balances[0].balance) / balances[0].balance * 100,
            avgProfit: (balances[balances.length - 1].avgBalance - balances[0].avgBalance) / balances[0].avgBalance * 100,
            totalWithdrawals: balances[balances.length - 1].totalWithdrawals,
            totalProfits: balances[balances.length - 1].totalProfits,
            totalDeposits: balances[balances.length - 1].totalDeposits
        }
        return [stats];
    }

    function getMonthlyProfitChartData() {

        let ret = [];
        ret = balances.map(b => {
            let date = (b.date.getUTCMonth() + 1) + "/" + b.date.getUTCDate() + "/" + (b.date.getUTCFullYear() - 2000);
            return [date, b.change];
        })
        ret.unshift(["Date", "Simulation"]);

        return ret;
    }

    function onRateChanged(delta) {
        setMonthlyAverageReturn((Number(monthlyAverageReturn) + Number(delta)).toFixed(1))
        //handleRunSimulation();
    }

    function onWithdrawalChanged(delta) {
        setMonthlyWithdraw(Math.max(0, (Number(monthlyWithdraw) + Number(delta))))
        //handleRunSimulation();
    }

    function onDepositChanged(delta) {
        setMonthlyDeposit(Math.max(0, (Number(monthlyDeposit) + Number(delta))))
        //handleRunSimulation();
    }

    function getChartData() {

        let ret = [];
        ret = balances.map(b => {
            let date = (b.date.getUTCMonth() + 1) + "/" + b.date.getUTCDate() + "/" + (b.date.getUTCFullYear() - 2000);
            if (showAverage)
                return [date, b.balance, b.avgBalance];
            else
                return [date, b.balance];
        })
        if (showAverage)
            ret.unshift(["Date", "Simulation", "Average"]);
        else
            ret.unshift(["Date", "Simulation"]);

        return ret;
    }

    return (
        <>
            <center>
                <br></br>
                Monthly Return: <Link className="plus-minus-button" onClick={() => onRateChanged(-.1)}>⬇️</Link><input size={5} type="text" value={monthlyAverageReturn} onChange={(e) => setMonthlyAverageReturn(e.target.value)} />%
                <Link className="plus-minus-button" onClick={() => onRateChanged(.1)}>⬆️</Link>
                <br></br>
                &nbsp;&nbsp;Monthly Win Rate: {getPeriodResult(backtestResults, "monthly", "winningPercentage").toFixed(0)}%
                <br></br>
                Starting Amount: <input className="analytics_hidden" type="text" size="10" value={startingAmount} onChange={(e) => setStartingAmount(Number(e.target.value))}></input>
                &nbsp;&nbsp;Years: <input type="text" size="3" value={simulationYears} onChange={(e) => setSimulationYears(Number(e.target.value))}></input>
                <br></br>
                Monthly Withdraw:
                <Link className="plus-minus-button" onClick={() => onWithdrawalChanged(-1000)}>⬇️</Link>
                <input type="text" size="7" value={monthlyWithdraw} onChange={(e) => setMonthlyWithdraw(Number(e.target.value))}></input>
                <Link className="plus-minus-button" onClick={() => onWithdrawalChanged(1000)}>⬆️</Link>
                <br></br>
                &nbsp;&nbsp;Monthly Deposit:
                <Link className="plus-minus-button" onClick={() => onDepositChanged(-1000)}>⬇️</Link>
                <input type="text" size="7" value={monthlyDeposit} onChange={(e) => setMonthlyDeposit(Number(e.target.value))}></input>
                <Link className="plus-minus-button" onClick={() => onDepositChanged(1000)}>⬆️</Link>
                <br></br>
                &nbsp;&nbsp;Show Average:<input type="checkbox" defaultChecked={showAverage} onChange={(e) => setShowAverage(!showAverage)}></input>&nbsp;&nbsp;
                <br></br>
                {backtestResults && backtestResults.periodSummaries ?
                    <Button className="btn btn-primary" onClick={handleRunSimulation}>Simulate</Button>
                    :
                    <>Loading...</>
                }

                {balances && balances.length ?
                    <div>
                        <br></br>
                        This simulation uses actual returns from random months over the past 10 years in order to demonstrate winning and losing months.<br></br>
                        The Average is based off of the average monthly return.
                        <br></br>
                        <RichTable data={getSummaryArray()} className="table-striped table-hover table-condensed" mappers={[
                            { title: 'Ending Balance', field: 'endBalance', formatter: tableHelpers.moneyFormatterNoCents },
                            { title: 'Using Monthly Average', field: 'avgBalance', formatter: tableHelpers.moneyFormatterNoCents },
                            { title: 'Net Gain', field: 'profitPct', formatter: tableHelpers.percentFormatterNoDecimals },
                            { title: 'Total Withdrawals', field: 'totalWithdrawals', formatter: tableHelpers.moneyFormatterNoCents },
                            { title: 'Total Deposits', field: 'totalDeposits', formatter: tableHelpers.moneyFormatterNoCents },
                            { title: 'Total Profits', field: 'totalProfits', formatter: tableHelpers.moneyFormatterNoCents },
                        ]} />

                        <Chart
                            chartType="AreaChart"
                            data={getChartData()}
                            width={"100%"}
                            height={"400px"}
                            chartPackages={["corechart", "controls"]}
                            options={{ legend: 'none' }}
                        />
                        <Chart
                            chartType="ColumnChart"
                            data={getMonthlyProfitChartData()}
                            width={"100%"}
                            height={"400px"}
                            chartPackages={["corechart", "controls"]}
                            options={{ legend: 'none' }}
                        />
                    </div>
                    :
                    null}
            </center>
        </>
    );
}

export default Forecast;