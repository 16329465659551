import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import tableHelpers from '../helpers/TableHelpers.js'
import RichTable from '../components/richTable/RichTable.jsx'
import AlgoQuickStats from '../components/AlgoQuickStats.js'
import { createDateSorter } from '../components/richTable/sorters.js'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Tooltip from '../components/Tooltip.js'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AutomateTrade from './AutomateTrade.js'
import OptionChain from './OptionChain.js';
const OptionFinder = require('./OptionFinder.js');

function BonusTrades(props) {
    const [data, setData] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [tradeShares, setTradeShares] = React.useState(1);
    const [tradeSymbol, setTradeSymbol] = React.useState('');
    const [optionTradeSymbol, setOptionTradeSymbol] = React.useState(null);
    const [optionTradeQuantity, setOptionTradeQuantity] = React.useState(1);
    const [optionTradePrice, setOptionTradePrice] = React.useState(0);
    const [tradeId, setTradeId] = React.useState(null);
    const [userTrades, setUserTrades] = useState([])
    const [updates, setUpdates] = useState(0);
    const [alertDetails, setAlertDetails] = useState(null)
    const [isPlacingOrder, setIsPlacingOrder] = useState(false);
    const [trade, setTrade] = useState(null);

    useEffect(() => {
        (async function () {
            let text = await (await fetch(`/api/allocationChanges/?planId=108&userId=` + props.userId)).json();
            setData(text);

            let trades = await (await fetch(`/api/userTrades/?accountId=0&userId=` + props.userId)).json();
            setUserTrades(trades);
        })();
    }, [props.planId, props.userId, updates]);

    async function saveTrade() {

        let url = `/api/userTrade?isOption=0&accountId=0&userId=` + props.userId;
        url += "&option=&planTradeId=" + tradeId
        url += "&shares=" + tradeShares;

        let result = await (await fetch(url, { method: 'POST' })).json();
        console.log("result", result);

        setUpdates(updates + 1);
        handleCloseModal();
        handleCloseActionMenu();
    }

    async function saveOptionTrade() {

        let url = `/api/userTrade?isOption=1&accountId=0&userId=` + props.userId;
        url += "&planTradeId=" + tradeId
        url += "&option=" + optionTradeSymbol
        url += "&shares=" + optionTradeQuantity
        url += "&purchasePrice=" + optionTradePrice
        url += "&sellPrice="

        let result = await (await fetch(url, { method: 'POST' })).json();
        console.log("result", result);

        setUpdates(updates + 1);
        handleCloseModal();
        handleCloseActionMenu();
    }

    async function handleForgetTrade() {
        let url = `/api/userTrade?isOption=0&accountId=0&userId=` + props.userId;
        url += "&option=&planTradeId=" + tradeId
        url += "&shares=0";

        await (await fetch(url, { method: 'POST' })).json();

        setUpdates(updates + 1);
        handleCloseModal();
        handleCloseActionMenu();
    }

    function actionFormatter(cell, row) {

        let action = "";

        if (row.didTakeTrade)
            action += "🚨 "

        if (cell === 'buy') {
            if (row.didTakeTrade)
                action += "Bought";
            else {
                action += "Buy";
            }
        } else {
            if (row.didTakeTrade)
                action += "Sold";
            else {
                action += "Sell";
            }
        }

        if (row.didTakeTrade) {
            if (row.option_symbol && row.option_symbol.length)
                action += " " + tableHelpers.moneyFormatterNoCents(row.shares * row.purchase_price * 100);
            else
                action += " " + tableHelpers.intFormatter(row.shares);
        }

        if (row.option_symbol && row.option_symbol.length)
            action += " " + row.option_symbol
        else
            action += " " + row.trade_symbol

        return action;
    }

    function profitFormatter(cell, row) {

        let result = "";

        if (row.action === 'sell')
            result = tableHelpers.percentChangeFormatter(cell, row);
        else if (row.action === 'buy' && row.profitPercentage) {
            result = tableHelpers.percentChangeFormatter(row.profitPercentage, row);
        } else {
            result = tableHelpers.percentChangeFormatter(row.percentChangeSince, row);
        }

        result += (row.action === 'buy' && row.profitPercentage === undefined ? " (Active)" : " (Completed)");

        return result;
    }

    function getChanges() {
        let results = data.map(change => {
            let userTrade = userTrades.find(t => t.planTradeId === change.tradeId)
            let diff = (change.quote - change.close) / change.close * 100;
            return {
                date: change.date,
                trade_symbol: change.trade_symbol,
                display_name: change.display_name,
                name: change.name,
                base_symbol: change.base_symbol,
                short: change.short,
                tradeId: change.tradeId,
                algoId: change.algoId,
                action: change.action,
                close: change.close,
                baseClose: change.baseClose,
                algo_type: change.algo_type,
                profitPercentage: change.profitPercentage,
                percentChangeSince: diff,
                winning_percentage: change.winning_percentage,
                avg_profit: change.avg_profit,
                avg_win: change.avg_win,
                avg_loss: change.avg_loss,
                avg_duration: change.avg_duration,
                allocation: change.percentage,
                didTakeTrade: userTrade !== undefined && userTrade.quantity > 0,
                shares: userTrade !== undefined ? userTrade.quantity : 0,
                quote: change.quote,
                robustness_overall: change.robustness_overall,
                option_symbol: change.user_option_symbol,
                is_option: change.user_is_option,
                sellAmount: change.user_sell_quantity,
                purchase_price: change.user_purchase_price
            }
        })

        // only display:
        // active trades
        // closed trades
        if (props.showAll)
            return results;
        else
            return results.filter(t => (t.action === 'buy' && t.profitPercentage === undefined) ||
                t.didTakeTrade);
    }

    function algoLinkFormatter(cell, row) {
        return (
            <>
                <Link id="link" className="bg-transparent" to={`/stock/${row.trade_symbol}`}>{row.trade_symbol}</Link>
                &nbsp;<Link className="bg-transparent" to={"/algo/" + row.algoId}>{cell}</Link>
                &nbsp;&nbsp;<Tooltip textClass={"tooltip-link"} text={"🔍"} width={225} borderWidth={"0px"} position="right center" >
                    <center>
                        <AlgoQuickStats winningPercentage={row.winning_percentage}
                            avgProfit={row.avg_profit}
                            avgWin={row.avg_win}
                            avgLoss={row.avg_loss}
                            avgDuration={row.avg_duration}
                            robustness={row.robustness_overall}
                            algoType={row.algo_type.toUpperCase()}
                        />
                    </center>
                </Tooltip >
            </>
        );
    }

    const actionsMenuOpen = Boolean(anchorEl);
    const handleOpenActionMenu = (event, row, tradeId) => {
        console.log("Opening menu for trade", tradeId);
        setTradeId(tradeId);
        setAnchorEl(event.currentTarget);
        loadAlertDetails(tradeId);
    };
    const handleCloseActionMenu = () => {
        setAnchorEl(null);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        handleCloseActionMenu();
    }
    const handleOpenModal = () => {

        setTradeShares(0);
        setOptionTradeQuantity(0);
        setModalOpen(true);

        let selectedTrade = data.find(change => change.tradeId === tradeId);
        let exampleShares = selectedTrade && props.accountValue && selectedTrade.quote ? props.accountValue * .01 / selectedTrade.quote : 1
        let userTrade = userTrades.find(t => t.planTradeId === tradeId)

        setTradeSymbol(selectedTrade.trade_symbol);
        setOptionTradeSymbol(selectedTrade.user_option_symbol);
        if (userTrade) {
            if (userTrade.is_option) {
                setOptionTradeQuantity(userTrade.quantity);
                setOptionTradePrice(userTrade.purchase_price);
            } else
                setTradeShares(userTrade.quantity);
        } else {
            setOptionTradeQuantity(1);
            setTradeShares(Math.round(exampleShares));
        }
    }

    async function loadAlertDetails(planTradeId) {

        let change = data.find(c => c.tradeId === planTradeId);
        if (change) {
            let optionFinder = new OptionFinder.OptionFinder();
            let alert = await optionFinder.loadAlertDetails(change.name, change.base_symbol);
            console.log("loaded alert", alert);
            if (alert && alert.tradeCount) {
                alert.algoName = change.name;
                alert.symbol = change.base_symbol;
                setAlertDetails(alert);
            }
        }
    }

    function openPlaceTradeModal(symbol, quantity) {
        setTrade({
            symbol: symbol,
            shares: Number(quantity)
        })
        setIsPlacingOrder(true);
    }

    function onOptionSelected(planTradeId, optionSymbol, price) {
        console.log("onOptionSelected", planTradeId, optionSymbol, price)
        setOptionTradeSymbol(optionSymbol);
        setOptionTradePrice(Number(price).toFixed(2));
    }

    function actionsFormatter(cell, row) {
        if (row.profitPercentage === undefined || row.didTakeTrade)
            return (
                <Button
                    id="basic-button"
                    aria-controls={actionsMenuOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={actionsMenuOpen ? 'true' : undefined}
                    onClick={(e) => handleOpenActionMenu(e, row, row.tradeId)}
                    style={{ padding: 0, margin: 0 }}
                >
                    🔻Actions
                </Button>
            );
        else
            return null;
    }

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    let selectedTrade = data.find(change => change.tradeId === tradeId);

    return (
        <>
            <RichTable pageSize={10} data={getChanges()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Date', field: 'date', sorters: createDateSorter('date'), formatter: tableHelpers.dateFormmatter },
                { title: 'Algo', field: 'display_name', formatter: algoLinkFormatter },
                { title: 'Action', field: 'action', formatter: actionFormatter, sorters: true },
                { title: 'P/L', field: 'profitPercentage', formatter: profitFormatter, hidden: props.mobileView },
                { title: '', field: 'algoId', formatter: actionsFormatter },
            ]} />

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={actionsMenuOpen}
                onClose={handleCloseActionMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleOpenModal}>Take / Edit Trade</MenuItem>
                <MenuItem onClick={handleForgetTrade}>Undo Taken Trade</MenuItem>
            </Menu>

            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modalOpen}
                onClose={handleCloseModal}
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Take Trade
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <br></br>
                        <input type="text" value={tradeShares} onChange={(e) => setTradeShares(e.target.value)} /> shares
                        <br></br>
                        Amount: {selectedTrade ? tableHelpers.moneyFormatter(selectedTrade.quote * tradeShares, null) : 0}
                        {props.brokerage ? <Link onClick={() => openPlaceTradeModal(tradeSymbol, tradeShares)}> ⤴️</Link> : null}
                        {props.brokerage && tradeSymbol ? <><Link onClick={() => openPlaceTradeModal(tradeSymbol, tradeShares * -1)}> ⤵️</Link></> : null}
                        <br></br>
                        <br></br>
                        <button class="btn btn-primary" onClick={saveTrade} type="button">Take Stock Trade</button>
                        <br></br>
                        <br></br>
                        -or-
                        <br></br>
                        <br></br>
                        Contracts: <input type="text" size={6} value={optionTradeQuantity} onChange={(e) => setOptionTradeQuantity(parseInt(e.target.value))} />
                        &nbsp;Price: <input type="text" size={6} value={optionTradePrice} onChange={(e) => setOptionTradePrice(parseFloat(e.target.value))} />
                        &nbsp;Option Symbol: <input type="text" size={25} onChange={(e) => setOptionTradeSymbol(e.target.value)} value={optionTradeSymbol} />
                        {props.brokerage && alertDetails ?
                            <Tooltip closeOnDocumentClick={true} width="600px" text=" 🔍" position={"left center"} >
                                <>
                                    <OptionChain userId={props.userId} brokerage={props.brokerage} alertName={alertDetails.algoName} alertSymbol={alertDetails.symbol} isShort={false} maxPrice={10} onOptionSelected={onOptionSelected} planTradeId={selectedTrade.planTradeId} />
                                </>
                            </Tooltip>
                            :
                            null}
                        {props.brokerage ? <Link onClick={() => openPlaceTradeModal(optionTradeSymbol, optionTradeQuantity)}> ⤴️</Link> : null}
                        {props.brokerage && optionTradeSymbol ? <><Link onClick={() => openPlaceTradeModal(optionTradeSymbol, optionTradeQuantity * -1)}> ⤵️</Link></> : null}

                        <br></br>
                        <br></br>
                        <button class="btn btn-primary" onClick={saveOptionTrade} type="button">Take Option Trade</button>
                        <br></br>
                        <br></br>
                        Note: these trades won't show up in your plan. You can only find them and their sell signals here in Bonus Trades.
                        <br></br>
                        <div style={{ textAlign: 'right' }}>
                            <button class="btn btn-danger" onClick={handleCloseModal} type="button">Cancel</button>
                        </div>
                    </Typography>
                </Box>
            </Modal>

            <AutomateTrade brokerage={props.brokerage} trade={trade} userId={props.userId} accountId={props.accountId} isOpen={isPlacingOrder} onClose={() => setIsPlacingOrder(false)} mobileView={props.mobileView} />
        </>
    );
}

export default BonusTrades;