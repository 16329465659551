import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import RichTable from '../components/richTable/RichTable.jsx'

function SingleAlgoCorrelations(props) {
    const [data, setData] = useState([]);
    const [filterTradeSymbol, setFilterTradeSymbol] = useState(true);
    const [filterAlgoType, setFilterAlgoType] = useState(true);

    useEffect(() => {
        (async function () {
            const url = `/api/algoCorrelation?algoId=` + props.algoId
            let d = await (await fetch(url)).json();
            setData(d);
        })();
    }, [props.algoId]);

    function algoLinkFormatter(cell, row) {
        return <Link reloadDocument to={"/algo/" + row.secondary_algoId}>{cell}</Link>;
    }

    function getCorrelations() {
        return data
            .filter(algo => filterTradeSymbol ? algo.secondary_trade_symbol === props.symbol : true)
            .filter(algo => filterAlgoType ? algo.secondary_algo_type === props.algoType : true)
    }


    return (
        <>

            <center>
                <input type="checkbox" checked={filterTradeSymbol} onChange={() => setFilterTradeSymbol(!filterTradeSymbol)} /> Filter by trade symbol
                <br></br>
                <input type="checkbox" checked={filterAlgoType} onChange={() => setFilterAlgoType(!filterAlgoType)} /> Filter by algo type
            </center>

            <RichTable data={getCorrelations()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Algo', field: 'secondary_display_name', formatter: algoLinkFormatter, filter: 'text' },
                { title: 'Symbol', field: 'secondary_symbol', sorters: true, filter: 'choice' },
                { title: 'Trade Symbol', field: 'secondary_trade_symbol', sorters: true, filter: 'choice' },
                { title: 'Type', field: 'secondary_algo_type', sorters: true, filter: 'choice' },
                { title: 'Correlation', field: 'percent', sorters: true, isDefaultSort: true },
            ]} />

        </>
    );
}

export default SingleAlgoCorrelations;