import React, { useState, useEffect } from 'react';

import { slide as Menu } from 'react-burger-menu'
import { Link } from 'react-router-dom'
import ToggleButton from 'react-bootstrap/ToggleButton';
import StickySettings from '../helpers/StickySettings.js';

function Navigation(props) {


    const [isOpen, setOpen] = useState(false)
    const [shouldDock, setShouldDock] = useState(StickySettings.getBool("shouldDockMenu", false));
    const [brokerageConnections, setBrokerageConnections] = useState([]);

    useEffect(() => {
        (async function () {

            async function loadBrokerageConnections() {
                console.log("Checking for active brokerage connections");
                let response = await fetch(`/api/brokeragesActive?userId=` + props.userId);
                if (response.ok) {
                    let connections = await (response.json());
                    setBrokerageConnections(connections);
                }
            }

            setInterval(async () => {
                loadBrokerageConnections();
            }, 180000);

            loadBrokerageConnections();
        })();
    }, [props.userId]);

    const handleIsOpen = () => {
        setOpen(!isOpen)
    }

    const closeSideBar = () => {
        if (!shouldDock) setOpen(false)
    }

    const setCSSVariable = (element, variable, value) => {
        element.style.setProperty(variable, value);
    }

    function dockMenu(onOff) {
        setShouldDock(onOff);
        StickySettings.setBool("shouldDockMenu", onOff);
        if (!onOff) {
            setCSSVariable(document.documentElement, "--current-right-margin", '20px');
            setOpen(false);
        } else {
            setCSSVariable(document.documentElement, "--current-right-margin", '250px');
        }
    }

    const isLoggedIn = props.userId !== 2
    if (shouldDock) {
        setCSSVariable(document.documentElement, "--current-right-margin", '250px');
    }

    return (
        <Menu isOpen={isOpen || shouldDock}
            onOpen={handleIsOpen}
            onClose={handleIsOpen}
            right width={shouldDock ? '200px' : '300px'}
            outerContainerId={props.outerContainerId}
            noOverlay={shouldDock}
            pageWrapId={props.pageWrapId}>
            <div>

                <ToggleButton
                    className="mb-2"
                    id="toggle-check"
                    type="checkbox"
                    variant="outline-secondary"
                    checked={shouldDock}
                    value="1"
                    onChange={(e) => dockMenu(e.currentTarget.checked)}
                >
                    Dock Menu
                </ToggleButton>
                <br></br>

                {brokerageConnections.map(connection => {
                    return <>🟢 {connection.brokerage}<br></br></>
                })}

                <div className="nav-section">
                    {
                        isLoggedIn ? <a onClick={props.onLogout} href='/logout' id="logout" className="menu-item nav-menu-item">Logout</a>
                            : <a onClick={props.onLogout} id="runtest" className="menu-item nav-menu-item" href='/login'>Login</a>
                    }
                    <Link onClick={closeSideBar} id="plans" className="menu-item nav-menu-item" to={"/plans"}>Change Plan</Link>
                </div>

                <div className="nav-section">
                    <div className="nav-header sameline">Plan: {props.planName}</div>
                    <div className="nav-links">
                        <Link onClick={closeSideBar} id="newtrades" className="menu-item nav-menu-item" to={"/overview"}>Overview</Link>
                        <Link onClick={closeSideBar} id="trades" className="menu-item nav-menu-item" to={"/trades"}>Trades</Link>
                        <Link onClick={closeSideBar} id="positions" className="menu-item nav-menu-item" to={"/positions"}>Positions</Link>
                    </div>
                </div>

                <div className="nav-section">
                    <div className="nav-header">Performance</div>
                    <div class-name="nav-links">
                        <Link onClick={closeSideBar} id="detailedPerformance" className="menu-item nav-menu-item" to={"/detailedPerformance"}>Detailed Performance</Link>
                        <Link onClick={closeSideBar} id="lastBacktest" className="menu-item nav-menu-item" to={"/lastBacktest"}>Most Recent Backtest</Link>
                        <Link onClick={closeSideBar} id="forecast" className="menu-item nav-menu-item" to={"/forecast"}>Forecaster</Link>
                        <Link onClick={closeSideBar} id="runtest" className="menu-item nav-menu-item" to={"/runbacktest"}>Run Backtest</Link>
                    </div>
                </div>

                {props.role >= 1 ?
                    <div className="nav-section">
                        <div className="nav-header">Plan</div>
                        <div className="nav-links">
                            <Link onClick={closeSideBar} id="update" className="menu-item nav-menu-item" to={"/updatePlan"}>Update Plan</Link>
                            <Link onClick={closeSideBar} id="analysis" className="menu-item nav-menu-item" to={"/planAnalysis"}>Plan Analysis</Link>
                            <Link onClick={closeSideBar} id="perf" className="menu-item nav-menu-item" to={"/algoPerformance"}>Algo Performance</Link>
                        </div>
                    </div>
                    : null
                }

                {props.role >= 2
                    ? <div className="nav-section">
                        <div className="nav-header">Admin</div>
                        <div className="nav-links">
                            <Link onClick={closeSideBar} id="strategies" className="menu-item nav-menu-item" to={"/alerts"}>Alerts</Link>
                            {props.role >= 2 ? <Link onClick={closeSideBar} id="manageSystem" className="menu-item nav-menu-item" to={"/manageSystem"}>Manage System</Link> : null}
                        </div>
                    </div>
                    : null
                }


                <div className="nav-section">
                    <div className="nav-links">
                        <Link onClick={closeSideBar} id="help" className="menu-item nav-menu-item" to={"/help"}>Help</Link>
                        <Link onClick={() => { closeSideBar() }} id="runtest" className="menu-item nav-menu-item" target={"_blank"} to={"https://discord.com/channels/1227965055488757761/1227971717712838747"}>Discord Server</Link>
                    </div>
                </div>

            </div>
        </Menu>
    )
}

export default Navigation;