import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import AlertEvaluationResults from './AlertEvaluationResults.js';
import AlertConfiguration from './AlertConfiguration.js';
import StockChart from '../stock/StockChart.js';
import AlertDataSample from './AlertDataSample.js'
import QuoteDateAnalysis from './QuoteDateAnalysis.js';
import AlertConfigChanges from "./AlertConfigChanges.js"
import AlertTrades from "./AlertTrades.js"
import AlertProfitChart from "./AlertProfitChart.js"
import AlertPerformanceHistory from "./AlertPerformanceHistory.js"
import AlertTradeResultsChart from './AlertTradeResultsChart.js';
import { useParams } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ActionResult from '../components/ActionResult.js'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function ConfigureAlert(props) {

    let { alertIdParam } = useParams();

    const [data, setData] = useState({})
    const [config, setConfig] = useState({})
    const [maxConfig, setMaxConfig] = useState({})
    const [defaultConfig, setDefaultConfig] = useState({})
    const [performance, setPerformance] = useState({})
    const [performanceArray, setPerformanceArray] = useState([])
    const [result, setResult] = useState(null)
    const [updates, setUpdates] = useState(0);
    const [algoId, setAlgoId] = useState(null);
    const [algos, setAlgos] = useState([])
    const [startDate, setStartDate] = useState(new Date(2014, 0, 1));
    const [endDate, setEndDate] = useState(new Date());
    const [addRandomness, setAddRandomness] = useState(false);
    const [monteCarloCount, setMonteCarloCount] = useState(10);
    const [alertId] = useState(alertIdParam ? alertIdParam : props.alertId)
    const [showDrawdown, setShowDrawdown] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [createAlgoDisplayName, setCreateAlgoDisplayName] = useState("");
    const [createAlgoTradeSymbol, setCreateAlgoTradeSymbol] = useState("");
    const [createAlgoType, setCreateAlgoType] = useState("Mean Reversion");
    const [createAlgoIsShort, setCreateAlgoIsShort] = useState(false);


    useEffect(() => {
        (async function () {

            console.log("Loading alert", alertId);
            if (alertId) {
                let url = `/api/strategyAlert?alertId=` + alertId;
                let text = await (await fetch(url)).json();

                setData(text);
                if (text.defaultConfig) {
                    Object.keys(text.defaultConfig).forEach(key => {
                        if (text.config[key] === undefined || text.config[key] === null) {
                            text.config[key] = text.defaultConfig[key];
                        }
                    })
                }
                if (text.config) {
                    setConfig(text.config);
                }
                if (text.defaultConfig)
                    setDefaultConfig(text.defaultConfig);
                if (text.idalgos)
                    setAlgoId(text.idalgos);

                let end = new Date();
                let evalUrl = `/api/strategyAlertEvaluator?alertId=` + alertId;
                evalUrl += "&startDate=2014-1-1&endDate=" + end.getFullYear().toString() + "-" + (end.getMonth() + 1).toString() + "-" + (end.getDate()).toString();
                let performanceText = await (await fetch(evalUrl)).json();
                setPerformance(performanceText);

                let perfArray = [];
                perfArray.push({ id: 1, ...performanceText });
                setPerformanceArray(perfArray);

                const algosText = await (await fetch(`/api/algos`)).json();
                console.log("algos", algosText);
                setAlgos(algosText);
            }
        })();
    }, [alertId, updates]);

    function shouldAllowEdits() {
        if (props.role === 3) return true;
        else return false;
    }

    async function handlePauseClicked() {
        console.log("handleSubmit");
        const url = "/api/strategyAlertStatus?alertId=" + alertId + "&paused=" + !data.paused
        console.log(url);
        const r = await (await fetch(url, {
            method: 'POST'
        })).json();
        setResult(r);
        setUpdates(updates + 1);
    }

    async function handleSubmit() {
        console.log("handleSubmit");
        const url = "/api/strategyAlertUpdate?alertId=" + alertId
        console.log(url);
        const r = await (await fetch(url, {
            method: 'POST',
            body: JSON.stringify(config)
        })).json();
        console.log("Result from api call", r);
        setResult(r);
    }

    async function handleRememberConfig() {
        console.log("handleRememberConfig");
        const url = "/api/pendingAlertConfigChange?alertId=" + alertId
        const r = await (await fetch(url, {
            method: 'POST',
            body: JSON.stringify(config)
        })).json();
        setResult(r);
        handleCloseConfigMenu();
    }

    async function handleForgetConfig() {
        console.log("handleForgetConfig");
        const url = "/api/pendingAlertConfigChange?alertId=" + alertId
        const r = await (await fetch(url, {
            method: 'POST',
        })).json();
        setResult(r);
        handleCloseConfigMenu();
    }

    async function loadRememberedConfig() {
        const url = "/api/pendingAlertConfig?alertId=" + alertId
        const r = await (await fetch(url)).json();
        if (r && r.length) {
            setConfig(r[0].config);
            setResult({ success: "Remembered config" })
        }
        handleCloseConfigMenu();
        handleEvaluate(r[0].config);
    }

    async function delay(milliseconds) {
        return new Promise(resolve => {
            setTimeout(resolve, milliseconds);
        });
    }

    async function handleSyncBacktestTrades() {
        for (let i = 0; i < algos.length; i++) {
            const algo = algos[i];
            console.log(algo);
            if (algo.name === data.algo_name && algo.symbol === data.symbol) {
                const id = algo.idalgos;
                console.log("Syncing trades with algoId", id);
                const url = `/api/algoSyncWithStrategyAlerts?algoId=` + id;
                const text = await (await fetch(url, {
                    method: 'POST'
                })).json();
                setResult(text);
                await delay(2000);
            }
        }
    }

    async function handleReset() {
        setUpdates(updates + 1);
    }

    async function handleEvaluate(conf = null, start = null, end = null) {
        console.log("handleEvaluate");
        let url = "/api/strategyAlertEvaluator?alertId=" + alertId
        let startToUse = start ? start : startDate;
        let endToUse = end ? end : endDate;
        if (startDate) url += "&startDate=" + startToUse.getFullYear().toString() + "-" + (startToUse.getMonth() + 1).toString() + "-" + (startToUse.getDate()).toString();
        if (endDate) url += "&endDate=" + endToUse.getFullYear().toString() + "-" + (endToUse.getMonth() + 1).toString() + "-" + (endToUse.getDate()).toString();
        url += "&addRandomness=" + (addRandomness ? 'true' : 'false');
        console.log(url);
        const r = await (await fetch(url, {
            method: 'POST',
            body: conf ? JSON.stringify(conf) : JSON.stringify(config)
        })).json();
        setPerformance(r);

        let perfArray = [];
        perfArray.push({ id: performanceArray.length + 1, ...r });
        performanceArray.forEach(p => {
            perfArray.push(p);
        })
        setPerformanceArray(perfArray);
    }

    async function handleMonteCarlo(conf = null, start = null, end = null) {
        console.log("handleMonteCarlo");
        let url = "/api/strategyAlertMonteCarlo?alertId=" + alertId + "&count=" + monteCarloCount
        let startToUse = start ? start : startDate;
        let endToUse = end ? end : endDate;
        if (startDate) url += "&startDate=" + startToUse.getFullYear().toString() + "-" + (startToUse.getMonth() + 1).toString() + "-" + (startToUse.getDate()).toString();
        if (endDate) url += "&endDate=" + endToUse.getFullYear().toString() + "-" + (endToUse.getMonth() + 1).toString() + "-" + (endToUse.getDate()).toString();
        url += "&addRandomness=" + (addRandomness ? 'true' : 'false');
        console.log(url);
        const r = await (await fetch(url, {
            method: 'POST',
            body: conf ? JSON.stringify(conf) : JSON.stringify(config)
        })).json();
        setPerformance(r);

        let perfArray = [];
        perfArray.push({ id: performanceArray.length + 1, ...r });
        performanceArray.forEach(p => {
            perfArray.push(p);
        })
        setPerformanceArray(perfArray);
    }

    async function evaluateConfig(c, arrayIndex) {
        let url = "/api/strategyAlertEvaluator?alertId=" + alertId
        if (startDate) url += "&startDate=" + startDate.toLocaleDateString();
        if (endDate) url += "&endDate=" + endDate.toLocaleDateString();
        url += "&addRandomness=" + (addRandomness ? 'true' : 'false');
        const r = await (await fetch(url, {
            method: 'POST',
            body: JSON.stringify(c)
        })).json();
        setPerformance(r);

        console.log("performanceArray in evaluateConfig", performanceArray);

        let tempArray = performanceArray;
        tempArray.push({ id: arrayIndex, ...r });
        setPerformanceArray(tempArray);
    }

    function handleClearResults() {
        setPerformanceArray([]);
    }

    function isInt(n) {
        return Number(n) === n && n % 1 === 0;
    }

    function onDataSampleUpdated(startDate, endDate) {
        setStartDate(startDate);
        setEndDate(endDate);
        handleEvaluate(null, startDate, endDate);
    }

    function onAddRandomnessUpdated(shouldAddRandomness) {
        setAddRandomness(shouldAddRandomness);
    }

    async function testRange(config, key, start, end, startCounter) {

        console.log("testRange", start, end, startCounter);
        let counter = 0;
        for (let j = start; j <= end; isInt(start) ? j++ : j = j + .1) {
            let tempConfig = JSON.parse(JSON.stringify(config));
            tempConfig[key] = j;
            let finalCounter = startCounter + counter;

            setTimeout(async (c) => {

                console.log("calling evaluate config", c);
                evaluateConfig(tempConfig, Math.round(c));

            }, 100 * counter, finalCounter);

            ++counter;
        }

        return counter;
    }

    async function handleEvaluateRange() {
        console.log("handleEvaluateRange");
        console.log("config", config);
        console.log("maxConfig", maxConfig);

        let keys = Object.keys(maxConfig);

        let counter = 0;

        if (keys.length === 1) {
            let key = keys[0];

            let start = Number(config[key]);
            let end = Number(maxConfig[key]);

            await testRange(config, key, start, end, counter);
        } else {
            let primaryKey = keys[0];
            let secondaryKey = keys[1];

            let start = Number(config[primaryKey]);
            let end = Number(maxConfig[primaryKey]);

            let secondaryStart = Number(config[secondaryKey]);
            let secondaryEnd = Number(maxConfig[secondaryKey]);

            for (let i = start; i < end; isInt(start) ? i++ : i = i + .1) {
                let tempConfig = JSON.parse(JSON.stringify(config));
                tempConfig[primaryKey] = i;

                await testRange(tempConfig, secondaryKey, secondaryStart, secondaryEnd, counter);
                counter += isInt(secondaryStart) ? (secondaryEnd - secondaryStart) : (secondaryEnd - secondaryStart) / .1;
            }
        }
    }

    function handleConfigUpdate(c) {
        console.log(c);
        setConfig(c);
        handleEvaluate(c);
    }

    function handleMaxConfigUpdated(c) {
        console.log(c);
        setMaxConfig(c);
    }

    function displayAlgoLinks() {
        let links = algos.filter(a => a.name === data.algo_name && a.symbol === data.symbol);
        if (links.length) {
            return links.map(a => {
                return <><Link to={"/algo/" + a.idalgos}>{a.display_name} - {a.trade_symbol}</Link><br></br></>
            })
        }
    }

    function getTrades() {
        if (performance && performance.trades && performance.trades.length) {
            return performance.trades.map(t => {
                return {
                    purchase_date: t.purchaseDate,
                    purchase_price: t.purchasePrice,
                    sell_date: t.sellDate,
                    sell_price: t.sellPrice,
                    profitPercentage: t.profitPercentage
                }
            })
        } else {
            return [];
        }
    }

    function onPerformanceResultsSelected(perf) {
        console.log("Update the charts to these results", perf)
    }

    const pendingConfigMenuOpen = Boolean(anchorEl);
    const handleOpenConfigMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseConfigMenu = () => {
        setAnchorEl(null);
    };

    async function handleCreateAlgoSubmit() {
        console.log("handleCreateAlgoSubmit");
        const url = "/api/algoCreate?name=" + data.algo_name + "&displayName=" + createAlgoDisplayName + "&baseSymbol=" + data.symbol + "&tradeSymbol=" + createAlgoTradeSymbol + "&short=" + (createAlgoIsShort ? "1" : "0") + "&pyramids=1&type=" + createAlgoType;
        console.log(url);
        const r = await (await fetch(url)).json();
        setResult(r);
        setModalOpen(false);
        setUpdates(updates + 1)
    }

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Link to={"/alerts"}>Back to Alerts</Link>

            <Accordion>
                <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <h4>Id: {data.idtrading_strategy_alerts} ({data.symbol} / {data.algo_name})</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <table width="100%" border="2">
                        <tr>
                            <td width="15%">Algo Name: {data.idtrading_strategy_alerts} - {data.algo_name}</td>
                            <td width="15%">Symbol: {data.symbol}</td>
                            <td width="15%">Strategy: {data.name}</td>
                            <td width="15%">Timeframe: {data.timeframe === 0 ? "Daily" : "Weekly"}</td>
                            <td width="15%">Last Triggered<br></br>{data.last_triggered}
                                {shouldAllowEdits() ? <>&nbsp;&nbsp;&nbsp;<button onClick={handlePauseClicked}>{data.paused ? "Unpause" : "Pause"}</button></> : null}
                            </td>
                            <td>
                                <>
                                    {displayAlgoLinks()}
                                </>
                            </td>
                        </tr>
                    </table>
                </AccordionDetails>
            </Accordion>

            <table width="100%" cellPadding={10}>
                <tr>
                    <td align='left' valign='top'>
                        <AlertConfiguration config={config} defaultConfig={defaultConfig} onConfigUpdated={handleConfigUpdate} onMaxConfigUpdated={handleMaxConfigUpdated} showMaxConfig={!props.mobileView} />
                    </td>
                    <td align='left' valign='top' width="250px">
                        <AlertEvaluationResults mobileView={props.mobileView} performance={performance} performanceArray={performanceArray} shouldShowTrades={!props.mobileView && Object.keys(maxConfig).length === 0} />
                    </td>
                    <td align='left' valign='top'>

                        <center><Link onClick={() => setShowDrawdown(!showDrawdown)}>{showDrawdown ? "Show Profit" : "Show Drawdown"}</Link></center>
                        <AlertTradeResultsChart showDrawdown={showDrawdown} performance={performance} />
                        <AlertProfitChart showDrawdown={showDrawdown} performance={performance} />
                        <br></br>
                        <center>
                            <QuoteDateAnalysis symbol={data.symbol} trades={performance.trades} />
                        </center>
                    </td>
                </tr>
            </table>

            <ActionResult result={result} />

            <AlertDataSample onDataSampleUpdated={onDataSampleUpdated} onAddRandomnessUpdated={onAddRandomnessUpdated} />

            <>
                <br></br>
                <button onClick={() => handleReset()}>Reset</button>
                &nbsp;&nbsp;&nbsp;<button onClick={() => handleEvaluate(config)}>Evaluate</button>
                &nbsp;&nbsp;&nbsp;<button onClick={handleEvaluateRange}>Evaluate Range</button>
                &nbsp;&nbsp;&nbsp;<button onClick={() => handleMonteCarlo(config)}>Monte Carlo</button>
                &nbsp;&nbsp;x&nbsp;<input type="text" size="4" defaultValue={10} onChange={(e) => setMonteCarloCount(e.target.value)} />
                &nbsp;&nbsp;&nbsp;<button onClick={handleClearResults}>Clear Results</button>
                <br></br>
                <br></br>
                <div hidden={!shouldAllowEdits()} style={{ borderStyle: 'solid', padding: '5px' }}>
                    {shouldAllowEdits() ? <Link onClick={() => setModalOpen(true)}>Create Algo</Link> : null}
                    {shouldAllowEdits() ? <>&nbsp;&nbsp;&nbsp;&nbsp;<button onClick={handleSubmit}>Save</button></> : null}
                    {shouldAllowEdits() && algoId ? <>&nbsp;&nbsp;&nbsp;&nbsp;<button onClick={handleSyncBacktestTrades}>Sync Backtests</button></> : null}

                    <Button
                        id="basic-button"
                        aria-controls={pendingConfigMenuOpen ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={pendingConfigMenuOpen ? 'true' : undefined}
                        onClick={handleOpenConfigMenu}
                    >
                        Pending Config Changes
                    </Button>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={pendingConfigMenuOpen}
                        onClose={handleCloseConfigMenu}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={loadRememberedConfig}>Load Remembered Config</MenuItem>
                        <MenuItem onClick={handleRememberConfig}>Remember Config</MenuItem>
                        <MenuItem onClick={handleForgetConfig}>Forget Config</MenuItem>
                    </Menu>
                </div>

            </>

            <StockChart width={"800px"} symbol={data.symbol} trades={getTrades()} />

            <br></br>
            <Accordion>
                <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <h4>History</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <AlertPerformanceHistory mobileView={props.mobileView} performanceArray={performanceArray} onResultsSelected={onPerformanceResultsSelected} />
                </AccordionDetails>
            </Accordion>

            <br></br>
            <Accordion>
                <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <h4>Trades</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <AlertTrades mobileView={props.mobileView} performance={performance} />
                </AccordionDetails>
            </Accordion>

            <br></br>
            <Accordion>
                <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <h4>Updates</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <AlertConfigChanges alertId={alertId} />
                </AccordionDetails>
            </Accordion>

            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Create Algo
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Identifier: {data.algo_name}
                        <br></br>
                        <br></br>
                        Symbol: {data.symbol}
                        <br></br>
                        <br></br>
                        Display Name: <input type="text" onChange={(e) => setCreateAlgoDisplayName(e.target.value)} />
                        <br></br>
                        <br></br>
                        Trade Symbol: <input type="text" defaultValue="" onChange={(e) => setCreateAlgoTradeSymbol(e.target.value)} />
                        <br></br>
                        <br></br>
                        Type: <input type="text" defaultValue="Mean Reversion" onChange={(e) => setCreateAlgoType(e.target.value)} />
                        <br></br>
                        <br></br>
                        Is Short: <input type="checkbox" onChange={(e) => setCreateAlgoIsShort(!createAlgoIsShort)} />
                        <br></br>
                        <br></br>
                        <button class="btn btn-primary" onClick={handleCreateAlgoSubmit} type="button">Submit</button>
                        <br></br>
                    </Typography>
                </Box>
            </Modal>

        </div >);
}

export default ConfigureAlert;