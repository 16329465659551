import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import tableHelpers from '../helpers/TableHelpers.js'
import RichTable from '../components/richTable/RichTable.jsx'

function PlansMetrics(props) {

    const [metrics, setMetrics] = useState([]);

    useEffect(() => {
        (async function () {
            let metrics = await ((await fetch(`/api/plansMetrics?userId=` + props.userId))).json()
            console.log(metrics);
            setMetrics(metrics);
        })();
    }, [props.userId]);

    function onPlanClicked(planId, planName) {
        if (props.onPlanSelected)
            props.onPlanSelected(planId, planName);
    }

    function getDirection(plan, propertyName) {
        if (plan.previous) {
            if (plan.mostRecent[propertyName] > plan.previous[propertyName])
                return "🔺"
            else if (plan.mostRecent[propertyName] < plan.previous[propertyName])
                return "🔻"
            else
                return "";
        } else {
            return "";
        }
    }

    function getMetrics() {
        return metrics
            .sort((a, b) => a.mostRecent.planId - b.mostRecent.planId)
            .map(plan => {
                return {
                    name: plan.mostRecent.name,
                    planId: plan.planId,
                    robustness_overall_direction: getDirection(plan, 'robustness_overall'),
                    robustness_weighted_direction: getDirection(plan, 'robustness_weighted'),
                    missing_trade_count_direction: getDirection(plan, 'missing_trade_count'),
                    allocations_algo_count_direction: getDirection(plan, 'allocations_algo_count'),
                    live_vs_backtest_10_days_direction: getDirection(plan, 'live_vs_backtest_10_days'),
                    live_vs_backtest_22_days_direction: getDirection(plan, 'live_vs_backtest_22_days'),
                    monte_carlo_annual_return_direction: getDirection(plan, 'monte_carlo_annual_return'),
                    allocations_average_allocation_direction: getDirection(plan, 'allocations_average_allocation'),
                    backtest_one_month_return_direction: getDirection(plan, 'backtest_one_month_return'),
                    live_one_month_return_direction: getDirection(plan, 'live_one_month_return'),
                    ...plan.mostRecent
                }
            })
    }

    function dynamicFormatter(cell, row, fieldName, extraData) {
        let result = tableHelpers.dynamicFormatterWithFormat(cell, row, extraData);
        result += " " + row[fieldName + "_direction"];
        return result;
    }

    function planLinkFormatter(cell, row) {
        return (
            <Link className="bg-transparent" to={`/overview`} onClick={() => onPlanClicked(row.planId, row.name)}>{cell}</Link>
        )
    }


    return (
        <div>
            <RichTable data={getMetrics()} mappers={[
                { title: `Name`, field: 'name', sorters: true, formatter: planLinkFormatter },
                { title: `Robustness`, field: 'robustness_overall', sorters: true, formatter: dynamicFormatter, extraData: 'float' },
                { title: `Weighted Robustness`, field: 'robustness_weighted', sorters: true, formatter: dynamicFormatter, extraData: 'float' },
                { title: `Missing Trades`, field: 'missing_trade_count', sorters: true, formatter: dynamicFormatter, extraData: 'int' },
                { title: `Algos`, field: 'allocations_algo_count', sorters: true, formatter: dynamicFormatter, extraData: 'int' },
                { title: `Avg Allocation`, field: 'allocations_average_allocation', sorters: true, formatter: dynamicFormatter, extraData: 'percent' },
                { title: `10 Day Drift`, field: 'live_vs_backtest_10_days', sorters: true, formatter: dynamicFormatter, extraData: 'percent' },
                { title: `22 Day Drift`, field: 'live_vs_backtest_22_days', sorters: true, formatter: dynamicFormatter, extraData: 'percent' },
                { title: `Monte Carlo Annual Return`, field: 'monte_carlo_annual_return', sorters: true, formatter: dynamicFormatter, extraData: 'percent' },
                { title: `1M Return`, field: 'live_one_month_return', sorters: true, formatter: dynamicFormatter, extraData: 'percent' },
                { title: `Backtest 1M Return`, field: 'backtest_one_month_return', sorters: true, formatter: dynamicFormatter, extraData: 'percent' },

            ]} className="table-striped table-hover table-condensed" />
        </div>);
}

export default PlansMetrics;