import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import RichTable from '../components/richTable/RichTable'
import TableHelpers from '../helpers/TableHelpers';
import { CircularProgress } from '@mui/material';
import AutomateTrade from './AutomateTrade.js'
const OptionFinder = require('./OptionFinder.js');

function OptionChain(props) {

    const [optionCandidates, setOptionCandidates] = useState([]);
    const [alertDetails, setAlertDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [maxPrice, setMaxPrice] = useState(props.maxPrice);
    const [minDays, setMinDays] = useState(7);
    const [maxDays, setMaxDays] = useState(7);
    const [currentPrice, setCurrentPrice] = useState(null);
    const [targetPrice, setTargetPrice] = useState(null);
    const [targetExitDate, setTargetExitDate] = useState(null);
    const [isPlacingOrder, setIsPlacingOrder] = useState(false);
    const [trade, setTrade] = useState(null);

    useEffect(() => {
        (async function () {
            if (props.brokerage && props.userId) {
                setIsLoading(true);

                let optionFinder = new OptionFinder.OptionFinder();
                let alertDetails = await optionFinder.loadAlertDetails(props.alertName, props.alertSymbol);
                if (alertDetails) {
                    setAlertDetails(alertDetails);
                    let minDays = Math.round(alertDetails.avgDurationInDaysForWinners);
                    let maxDays = Math.round(minDays + alertDetails.winnerDurationInDaysStdDev * 2)
                    setMinDays(minDays)
                    setMaxDays(maxDays)
                    console.log("OptionChain Loading option candidates", props.brokerage, maxPrice)
                    let optionCandidates = await optionFinder.loadOptionCandidates(props.userId, props.brokerage, props.alertSymbol, minDays, maxDays, maxPrice, props.isShort, alertDetails.avgWin);
                    console.log("OptionChain optionCandidates", optionCandidates);
                    if (optionCandidates) {
                        setTargetPrice(optionCandidates.targetPrice);
                        setTargetExitDate(optionCandidates.targetDate);
                        setCurrentPrice(optionCandidates.quote);
                        if (optionCandidates.options && optionCandidates.options.length)
                            setOptionCandidates(optionCandidates.options);
                    }
                } else {
                    console.error("OptionChain Could not load alert", props.alertName, props.alertSymbol);
                }
                setIsLoading(false);
            }
        })();
    }, [props.userId, props.brokerage, props.alertSymbol, props.alertName, props.isShort, maxPrice]);

    async function reloadData() {
        setIsLoading(true);
        setOptionCandidates([])
        let optionFinder = new OptionFinder.OptionFinder();
        let alertDetails = await optionFinder.loadAlertDetails(props.alertName, props.alertSymbol);
        if (alertDetails) {
            setAlertDetails(alertDetails);
            console.log("OptionChain Loading option candidates", props.brokerage, maxPrice)
            let optionCandidates = await optionFinder.loadOptionCandidates(props.userId, props.brokerage, props.alertSymbol, minDays, maxDays, maxPrice, props.isShort, alertDetails.avgWin);
            if (optionCandidates) {
                console.log(optionCandidates);
                setTargetPrice(optionCandidates.targetPrice);
                setTargetExitDate(optionCandidates.targetDate);
                setCurrentPrice(optionCandidates.quote);
                if (optionCandidates.options && optionCandidates.options.length)
                    setOptionCandidates(optionCandidates.options);
            }
        }
        setIsLoading(false);
    }

    function openPlaceTradeModal(symbol, quantity) {
        setTrade({
            symbol: symbol,
            shares: Number(quantity)
        })
        setIsPlacingOrder(true);
    }

    function symbolFormatter(cell, row) {
        return (
            <>
                {cell} {props.onOptionSelected ? <Link onClick={() => props.onOptionSelected(props.planTradeId, cell, (row.bid + row.ask) / 2)}> 🎯</Link> : null}
                {props.brokerage && props.accountId ? <Link onClick={() => openPlaceTradeModal(cell, 1)}> ⤴️</Link> : null}

            </>
        )
    }

    function bidAskFormatter(cell, row) {
        return (
            <>{TableHelpers.decimalFormatter(row.bid)} x {TableHelpers.decimalFormatter(row.ask)}</>
        )
    }

    return (
        <>
            {isLoading ?
                <CircularProgress />
                :
                <>
                    {props.brokerage && props.userId ?
                        <RichTable pageSize={10} headless={false} data={optionCandidates} className="table-condensed" mappers={[
                            { title: 'Symbol', field: 'description', formatter: symbolFormatter, sorters: true },
                            { title: 'Target Profit', field: 'hypotheticalProfitPct', formatter: TableHelpers.percentChangeFormatterOneDecimal, sorters: true },
                            { title: 'Bid Ask', field: 'bid', formatter: bidAskFormatter, sorters: true },
                            { title: 'Delta', field: 'delta', sorters: true },
                            { title: 'Theta %', field: 'thetaPercent', formatter: TableHelpers.percentFormatterNoDecimals, sorters: true },
                            { title: 'Distance', field: 'distanceFromLast', formatter: TableHelpers.percentChangeFormatter, sorters: true },
                        ]} />
                        :
                        "Not logged into brokerage account"
                    }

                    {alertDetails ?
                        <>
                            <b>Min Days:</b> <input size={5} type="text" onChange={(e) => setMinDays(e.target.value)} value={minDays}></input>
                            &nbsp;&nbsp;<b>Max Days:</b> <input size={5} type="text" onChange={(e) => setMaxDays(e.target.value)} value={maxDays}></input>
                            &nbsp;&nbsp;<b>Max Price:</b> <input size={5} type="text" onChange={(e) => setMaxPrice(e.target.value)} value={maxPrice}></input> <Link onClick={reloadData}> 🔄</Link>
                            <br></br>
                            <b>Avg Win:</b> {TableHelpers.percentChangeFormatterOneDecimal(alertDetails.avgWin)}
                            &nbsp;&nbsp;<b>Avg Duration:</b> {TableHelpers.intFormatter(alertDetails.avgDurationInDaysForWinners)}
                            &nbsp;&nbsp;<b>StdDev:</b> {TableHelpers.decimalFormatter(alertDetails.winnerDurationInDaysStdDev)}
                            <br></br>
                            <b>Current Price:</b>  {TableHelpers.intFormatter(currentPrice)}
                            &nbsp;&nbsp;<b>Target Price:</b>  {TableHelpers.intFormatter(targetPrice)}
                            &nbsp;&nbsp;<b>Target Exit Date:</b> {targetExitDate}
                        </>
                        :
                        null}
                </>
            }
            <AutomateTrade brokerage={props.brokerage} trade={trade} userId={props.userId} accountId={props.accountId} isOpen={isPlacingOrder} onClose={() => setIsPlacingOrder(false)} mobileView={props.mobileView} />
        </>
    );
}

export default OptionChain;